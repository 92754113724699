<template>
  <div>
    <div class="d-flex gap-20 mb-5">
      <v-card
        @click="videoDialog = true"
        width="250"
        height="170"
        class="pa-5 d-flex flex-column"
        outlined
        hover
      >
        <v-icon size="45" color="accent">mdi-play-circle</v-icon>
        <div class="text-center font-weight-bold mb-2">
          Intro for Promo Recipe eBook & Client List
        </div>
        <div class="text-center caption" :style="{ lineHeight: '1.1' }">
          If this is the first time you are creating a recipe eBook, please watch this
          demo
        </div>
      </v-card>
    </div>
    <v-row>
      <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>Promo Recipe eBook 1</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <div class="d-flex gap-1 align-start px-0 px-md-5 px-lg-8 flex-wrap flex-lg-nowrap">
                <div class="col-12 col-lg-11 px-0">
                  <v-card class="my-0 my-md-5 my-lg-5 box-shadow primary pa-1 pa-md-5 pa-lg-5 ribbon-container" outlined>
                    <span class="ribbon-name">Ebook Title Page: </span>
                    <span class="ribbon-button"
                      v-if="lgUp"
                    >
                      <v-btn
                        class="mt-2"
                        @click="copyRecipeLink(1, true)"
                        color="primary"
                        x-small
                        depressed
                      >
                        <v-icon class="mr-1" x-small>mdi-content-copy</v-icon>
                        Client Questionnaire Link for Promo Recipe eBook 1
                      </v-btn>
                    </span>
                    <span class="ribbon-button-sm" v-else>
                      <v-btn
                        class="mt-2 mr-5"
                        @click="copyRecipeLink(1, true)"
                        color="primary"
                        x-small
                        depressed
                      >
                        <v-icon class="mr-1" x-small>mdi-content-copy</v-icon>
                        Client Link
                      </v-btn>
                    </span>
                    <div class="d-flex gap-1 align-start justify-start justify-md-center justify-lg-center flex-wrap">
                      <!-- TO BE OPTIMIZED (PUT IN IT"S OWN CONTAINER) -->
                      <div class="popover__wrapper">
                        <v-icon
                          small
                          class="white--text"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                        <div class="popover__content">
                          <p class="popover__message">This will show your clients or potentional clients who is behind the promo recipe eBook. The eBooks are also branded with your logo and colours.</p>  
                          <div class="popover__actions">
                            <v-btn
                              depressed
                              x-small
                              class="white--text info"
                              @click="openLink('https://vimeo.com/1038769900?')"
                            >
                            View sample video
                            </v-btn>
                          </div>
                        </div>
                      </div>
                      <v-chip
                        class="ma-1 ma-lg-2"
                        label
                        style="background-color: #463f57; color: #fff"
                      >
                        Business Name
                      </v-chip>
                      <v-text-field
                        class="mr-2"
                        v-model="promoData.businessName"
                        outlined
                        dense
                        background-color="white"
                      >
                      </v-text-field>
                     <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                      <div class="popover__wrapper">
                        <v-icon
                          small
                          class="white--text"
                          v-if="mdUp"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                        <div class="popover__content" style="left: -1000%">
                          <p class="popover__message">Click the preview button to see what the page will look like on various screen sizes. Be mindful that most people will be looking at the recipe eBooks on a mobile device; therefore adding smaller images may provide a better user experience. A good pixel width for mobile is 250px</p>  
                          <div class="popover__actions">
                            <v-btn
                              depressed
                              x-small
                              class="white--text info"
                              @click="openLink('https://vimeo.com/1038774160')"
                            >
                            View sample video
                            </v-btn>
                          </div>
                        </div>
                      </div>
                      <v-btn
                        depressed
                        icon
                        @click="() => {
                            previewTitleDialog = true;
                            itemToShow = 'pageTitle';
                          }
                          "
                        :disabled="!promoData?.businessName || !promoData.bio"
                      >
                        <v-icon v-if="mdUp" large style="color: #463f57"> mdi-eye </v-icon>
                      </v-btn>
                    </div>
                    <div class="d-flex gap-1 align-start justify-start flex-wrap">
                      <!-- TO BE OPTIMIZED (PUT IN IT"S OWN CONTAINER) -->
                      <div class="popover__wrapper">
                        <v-icon
                          small
                          class="white--text"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                        <div class="popover__content">
                          <p class="popover__message">Promo recipe eBooks are generated on the spot for anyone who completes a 'Client Questionnaire'. For that reason, you need to pre-build the page(s) within the book. Page 1 is the very first page people will see once the eBook is generated. Think of this as a web or landing page to introduce yourself to your clients or potential clients. You can format this page with different font colours, font sizes and alignment. You can add images and Youtube videos, and can also hyerlink images or text to point to a webpage of your choice.</p>  
                          <div class="popover__actions">
                            <v-btn
                              depressed
                              x-small
                              class="white--text info"
                              @click="openLink('https://vimeo.com/1038769705?')"
                            >
                            View sample video
                            </v-btn>
                          </div>
                        </div>
                      </div>
                      <v-chip
                        class="ma-2"
                        label
                        style="background-color: #463f57; color: #fff"
                      >
                        Page 1 of Promo eBook
                      </v-chip>
                    </div>
                    <div style="background-color: white">
                      <vue-editor
                        v-model="promoData.bio"
                        background-color="white"
                        placeholder="(500 Character Limit)"
                        class="mb-3"
                      />
                    </div>
                  </v-card>
                </div>
                <!-- <div class="col-12 col-md-4 col-lg-2">
        <v-card
          class="my-5 box-shadow primary pa-5 ribbon-container text-center"
          outlined
        >
          <span class="ribbon-name">QR Code: </span>
          <div>
            <qrcode-vue :value="generatedLink" :size="size" level="M" id="qr-code" />
          </div>
          <v-btn @click="printQR" x-small depressed color="info"> Download </v-btn>
        </v-card>
      </div> -->
              </div>
              <div class="d-flex gap-1 px-0 px-md-5 px-lg-8 align-start flex-sm-wrap flex-lg-nowrap">
                <div class="col-12 col-md-10 col-lg-11 col-xl-10">
                  <div
                    class="d-flex flex-wrap flex-xl-nowrap align-start justify-center gap-3"
                  >
                    <div class="col-12 col-lg-10 col-xl-6">
                      <v-card
                        class="my-5 box-shadow primary pa-2 pa-md-5 pa-lg-5 ribbon-container"
                        outlined
                      >
                        <span class="ribbon-name">
                          <div class="d-flex gap-1 align-start justify-center flex-wrap gap-5">
                            <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                            <div class="popover__wrapper mt-0">
                              <v-icon
                                small
                                class="white--text"
                                v-if="mdUp"
                              >
                                mdi-help-circle-outline
                              </v-icon>
                              <div class="popover__content">
                                <p class="popover__message">This is an optional button you can add that appears at the top of the eBook.</p>  
                                <div class="popover__actions">
                                  <v-btn
                                    depressed
                                    x-small
                                    class="white--text info"
                                    @click="openLink('https://vimeo.com/1038769019')"
                                  >
                                  View sample video
                                  </v-btn>
                                </div>
                              </div>
                            </div>
                            Header Call-To-Action:
                          </div>
                        </span>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                        <div class="col-12 col-lg-4">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              <div class="d-flex align-start justify-start flex-wrap gap-5">
                                <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                                <div class="popover__wrapper mt-0">
                                  <v-icon
                                    small
                                    class="white--text"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                  <div class="popover__content">
                                    <p class="popover__message">This is the text the client sees when the button is visible in the promo recipe eBook.</p>  
                                  </div>
                                </div>
                                Header Button Name
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData.headerButtonName"
                              outlined
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              <div class="d-flex gap-1 align-start justify-center flex-wrap gap-5">
                              <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                              <div class="popover__wrapper mt-0">
                                <v-icon
                                  small
                                  class="white--text"
                                >
                                  mdi-help-circle-outline
                                </v-icon>
                                <div class="popover__content">
                                  <p class="popover__message">This is the URL your clients will navigate to if they click the button</p>  
                                </div>
                              </div>
                              Header Button Link
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData.headerButtonLink"
                              outlined
                              hint="Please add 'http://' in the front"
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-center justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-6">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              <div class="d-flex gap-1 align-center justify-center flex-wrap gap-5">
                              <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                                <div class="popover__wrapper mt-0">
                                  <v-icon
                                    small
                                    class="white--text"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                  <div class="popover__content">
                                    <p class="popover__message">The header button is optional. You can switch it on or off</p>  
                                  </div>
                                </div>
                                Header Button On/Off
                                <v-switch
                                  v-model="promoData.headerButton"
                                  color="primary"
                                  class="ml-3"
                                ></v-switch>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-6">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              <div class="d-flex gap-1 align-center justify-center flex-wrap gap-5">
                              <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                                <div class="popover__wrapper mt-0">
                                  <v-icon
                                    small
                                    class="white--text"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                  <div class="popover__content">
                                    <p class="popover__message">You can set the colour of your button. Be sure to test it on various books to see how it presents. It's recommended to use the same colour as your brand colour for the header button.</p>  
                                  </div>
                                </div>
                                Header Button Color 
                                <input
                                  type="color"
                                  id="head"
                                  name="header button color"
                                  class="ml-3"
                                  v-model="promoData.headerButtonColor"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-card>
                    </div>
                    <div class="col-12 col-lg-10 col-xl-6">
                      <v-card
                        class="my-5 box-shadow primary pa-2 pa-md-5 pa-lg-5 ribbon-container"
                        outlined
                      >
                        <span class="ribbon-name">
                          <div class="d-flex gap-1 align-start justify-center flex-wrap gap-5">
                          <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                            <div class="popover__wrapper mt-0">
                              <v-icon
                                small
                                class="white--text"
                                v-if="mdUp"
                              >
                                mdi-help-circle-outline
                              </v-icon>
                              <div class="popover__content">
                                <p class="popover__message">This is an optional button you can add that appears on the footer of the eBook.</p>  
                              </div>
                            </div>
                            Footer Call-To-Action:
                          </div>
                        </span>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              <div class="d-flex align-start justify-start flex-wrap gap-5">
                                <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                                <div class="popover__wrapper mt-0">
                                  <v-icon
                                    small
                                    class="white--text"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                  <div class="popover__content">
                                    <p class="popover__message">This is the text the client sees when the button is visible in the promo recipe eBook.</p>  
                                  </div>
                                </div>
                                Footer Button Name
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData.footerButtonName"
                              outlined
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              <div class="d-flex align-start justify-start flex-wrap gap-5">
                                <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                                <div class="popover__wrapper mt-0">
                                  <v-icon
                                    small
                                    class="white--text"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                  <div class="popover__content">
                                    <p class="popover__message">This is the URL your clients will navigate to if they click the button.</p>  
                                  </div>
                                </div>
                                Footer Button Link
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData.footerButtonLink"
                              outlined
                              hint="Please add 'http://' in the front"
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-6">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              <div class="d-flex gap-1 align-center justify-center flex-wrap gap-5">
                              <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                                <div class="popover__wrapper mt-0">
                                  <v-icon
                                    small
                                    class="white--text"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                  <div class="popover__content">
                                    <p class="popover__message">The footer button is optional. You can switch it on or off.</p>  
                                  </div>
                                </div>
                                Footer Button On/Off
                                <v-switch
                                  v-model="promoData.footerButton"
                                  color="primary"
                                  class="ml-3"
                                ></v-switch>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-6">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                             <div class="d-flex gap-1 align-center justify-center flex-wrap gap-5">
                              <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                                <div class="popover__wrapper mt-0">
                                  <v-icon
                                    small
                                    class="white--text"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                  <div class="popover__content">
                                    <p class="popover__message">You can set the colour of your button. It's recommended to use a contrasting colour to that of your brand colour. Black seems to work well for most colours. Be sure to test it on various books to see how it presents.</p>  
                                  </div>
                                </div>
                                Footer Button Color
                                <input
                                  type="color"
                                  id="head"
                                  name="header button color"
                                  class="ml-3"
                                  v-model="promoData.footerButtonColor"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-12 col-md-2 col-lg-2"></div> -->
              </div>
              <div class="d-flex gap-20 align-start px-10 flex-wrap flex-lg-nowrap">
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">This determines how often a client can download a promo recipe eBook. If they attempt to complete the client questionnaire twice within the interval frequency, they will see a pop-up message that you have customised in the 'Interval Message'</p>  
                    <div class="popover__actions">
                      <v-btn
                        depressed
                        x-small
                        class="white--text info"
                        @click="openLink('https://vimeo.com/1038770112')"
                      >
                      View sample video
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div class="caption mb-2">Recipe eBook download available every:</div>
                <div class="d-flex justify-start justify-md-center justify-lg-center flex-wrap gap-12">
                  <div>
                    <v-select
                      dense
                      outlined
                      :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                      placeholder="1"
                      v-model="promoData.dateNumber"
                    ></v-select>
                  </div>
                  <div>
                    <v-select
                      outlined
                      dense
                      placeholder="week"
                      :items="['day', 'week', 'month', 'year']"
                      v-model="promoData.dateName"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="d-flex gap-1 align-start px-0 px-md-5 px-lg-8 flex-wrap flex-xl-nowrap">
                <div class="col-12 col-lg-11 col-xl-5">
                  <v-card class="my-5 box-shadow primary pa-0 pa-md-3 pa-lg-5 ribbon-container" outlined>
                    <span class="ribbon-name">
                      <div class="d-flex gap-1 align-start justify-center flex-wrap gap-5">
                        <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                        <div class="popover__wrapper mt-0">
                          <v-icon
                            small
                            class="white--text"
                            v-if="mdUp"
                          >
                            mdi-help-circle-outline
                          </v-icon>
                          <div class="popover__content">
                            <p class="popover__message">This is the message users will see if they attempt to complete the client questionnaire within the set interval frequency. You can customise this message to whatever you like. </p>  
                          </div>
                        </div>
                        Interval Message: 
                      </div>
                    </span>
                    <div class="d-flex gap-1 align-start justify-start">
                      <div class="col-12">
                        <div style="background-color: white">
                          <vue-editor
                            v-model="promoData.intervalMessage"
                            background-color="white"
                            placeholder="Interval Message (500 Character Limit)"
                            class="mb-3"
                          />
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
                <div class="col-12 col-md-6 col-lg-6 col-xl-5">
                  <v-card class="my-5 box-shadow primary pa-2 pa-lg-5 ribbon-container" outlined>
                    <span class="ribbon-name">Page Ads: </span>
                    <div class="d-flex gap-1 align-start justify-start">
                      <div class="col-12 col-lg-7">
                        <div
                          class="ma-2 custom-chip"
                          label
                          style="background-color: #463f57; color: #fff"
                        >
                         <div class="d-flex gap-1 align-center justify-center flex-wrap gap-5">
                          <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                            <div class="popover__wrapper mt-0">
                              <v-icon
                                small
                                class="white--text"
                              >
                                mdi-help-circle-outline
                              </v-icon>
                              <div class="popover__content">
                                <p class="popover__message">This is an optional page you can add to the eBook. It functions identically to page 1. It is called page 3 as every eBook has an instruction page as page 2. If you toggle this page on, you will need to add the content you want to appear on this page. Like page 1, page 3 has a page title, and optional call to action button. If you decide to add a page 3, this is where you may place offers or promotions for your business. Once you have added your content to this page, click the 'Add Details' button and then click 'Save Details'.</p>  
                              </div>
                            </div>
                            Page 3 of Promo eBook
                            <v-switch
                              v-model="promoData.pageAd1"
                              color="primary"
                              class="ml-3"
                            ></v-switch>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  :disabled="!promoData.pageAd1"
                                  v-on="on"
                                  color="white"
                                  icon
                                  depressed
                                  small
                                  @click="pageAd1Dialog = true"
                                >
                                  <v-icon>mdi-note-edit-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>Click to open</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-wrap flex-lg-nowrap mb-3"
              >
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">Hide Nutrition Info: Toggle this on to hide the nutrition info from the recipe eBook.
                    <br/>
                    <br/>
                    Hide Meal Tags: Toggle this on to hide the meal tags from the recipe eBook.
                    <br/>
                    <br/>
                    Hide Nutrition Tags: Toggle this on to hide the nutrition tags from the recipe eBook.
                    <br/>
                    <br/>
                    Hide Recipe Customisation: Toggle this on to hide the ability for users to customise recipes within the eBook. This is useful if you are the one creating the recipe eBook to then share with multiple users. If an eBook is shared with multiple people, it is a good idea to hide the recipe customisation feature.</p>  
                  </div>
                </div>
                <div class="caption mb-2">Hide these from pdf's/ebooks:</div>

                <div class="d-flex gap-15 flex-wrap flex-lg-nowrap">
                  <v-switch
                    label="Hide Nutrition Info"
                    v-model="promoData.hideEnergy"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                  <v-switch
                    label="Hide Meal Tags"
                    v-model="promoData.hideMealTags"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                  <v-switch
                    label="Hide Nutrition Tags"
                    v-model="promoData.hideNutriTags"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                  <v-switch
                    label="Hide Recipe Customization"
                    v-model="promoData.hideCustomization"
                    hide-details=""
                    class="mt-0 pt-0"
                    dense
                  >
                  </v-switch>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-wrap flex-lg-nowrap justify-space-between"
              >
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">
                      You can pre-load diet preferences so that when someone generates a recipe eBook, the recipes are all aligned with this preference. For example, if you add the 'Gluten-free' tag here. All recipe in this eBook will be 'Gluten-free'.
                    </p>
                  </div>
                </div>
                <div class="caption mb-2">
                  Please list any diet preferences i.e. low carb or gluten-free:
                </div>
                <div class="d-flex" style="width: 100%">
                  <v-sheet width="350" color="transparent">
                    <v-combobox
                      placeholder="PLEASE PRESS ENTER AFTER EACH DIETARY PREFERENCE BEFORE ADDING THE NEXT"
                      v-model="promoData.tags"
                      class="meal-tags align-start"
                      append-icon=""
                      deletable-chips
                      @change="($event) => filterNutriTags($event, 1)"
                      width="100%"
                      small-chips
                      multiple
                      outlined
                      chips
                      dense
                    ></v-combobox>
                  </v-sheet>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start"
              >
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">
                      Our recipe eBooks will not go below 5000kJ (1200Cal). If you believe this is too low, you can set your own calorie limit. This means that the recipe eBook Calorie average will not go below your set limit. If you set it at 2000Cal, then the recipes provided will not equate to less than 2000Cal per day.
                    </p>
                  </div>
                </div>
                <div class="caption mb-2">
                  Set a Calorie limit:
                </div>
                <div class="d-flex gap-15">
                  <v-switch
                    v-model="promoData.setCalorieLimit"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start"
                v-if="promoData?.setCalorieLimit"
              >
                <div class="caption mb-2">
                  Calorie limit:
                </div>
                <div class="d-flex gap-15">
                  <v-text-field
                    :rules="[
                      (value) =>
                        [
                          'dietitian',
                          'personal_trainer',
                          'nutritionist',
                          'naturopath',
                        ].includes(user.role) ||
                        (!value && value <= 0) ||
                        value >= 5000 ||
                        `Value can't be less than 5000kJ`,
                    ]"
                    type="number"
                    v-model.number="promoData.calorieLimit"
                    suffix="kJ"
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    :value="Math.round((promoData.calorieLimit || 0) / 4.18)"
                    @input="promoData.calorieLimit = $event * 4.18"
                    type="number"
                    suffix="Cal"
                    outlined
                    dense
                  ></v-text-field>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start mb-4"
              >
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">
                      You may charge a fee or subscription for your clients to access your recipe eBooks. This feature allows you to add the email addresses of those who have subscribed. If this is toggled on, you will need to add the emails of those who have subscribed. Anyone whose email is not on this list and attempts to complete the 'Client Questionnaire', will not be able to download a recipe eBook.
                    </p>
                  </div>
                </div>
                <div class="caption mb-2">
                  Set an Exclusive List:
                </div>
                <div class="d-flex gap-15">
                  <v-switch
                    v-model="promoData.setExclusiveList"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start"
                v-if="promoData?.setExclusiveList"
              >
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">
                      This is where you can add individual emails to the Exclusive List.
                    </p>
                  </div>
                </div>
                <div class="caption mb-2">
                  Add an email to your Exclusive List:
                </div>
                <v-sheet color="transparent" min-width="450">
                  <v-combobox
                    v-model="promoData.exclusiveList"
                    class="meal-tags align-start"
                    hint="Press enter to add"
                    @change="autoSave()"
                    append-icon=""
                    deletable-chips
                    width="100%"
                    small-chips
                    multiple
                    outlined
                    chips
                    dense
                  ></v-combobox>
                </v-sheet>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start"
                v-if="promoData?.setExclusiveList"
              >
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">
                      You can add emails in bulk by uploading a CSV file. Simply have all emails in one column.
                    </p>
                  </div>
                </div>
                <div class="caption mb-2">
                  Bulk email upload via csv file:
                </div>
                <v-sheet min-width="300">
                  <v-file-input
                      label="File input"
                      outlined
                      dense
                      v-model="file"
                  >
                  </v-file-input>
                  <v-btn :disabled="!file" class="mb-5 white--text" @click="convertToJson(1)" small depressed color="grey darken-1" :loading="loading">Upload</v-btn>
                </v-sheet>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start mb-4"
                v-if="promoData?.setExclusiveList"
              >
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">
                      This is the message users will see if they attempt to complete the 'Client Questionnaire' yet their email has not been added to the Exclusive List.
                    </p>
                  </div>
                </div>
                <div class="caption mb-2">
                  Access Upgrade Message:
                </div>
                <div class="d-flex gap-1 align-start justify-start">
                  <div class="col-12">
                    <div style="background-color: white">
                      <vue-editor
                        v-model="promoData.intervalMessageForExclusiveList"
                        background-color="white"
                        placeholder="Interval Message (500 Character Limit)"
                        class="mb-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex gap-20 align-start flex-sm-wrap flex-lg-nowrap mt-8 mb-5 px-5">
                <v-btn @click="submitData" :loading="submitting" class="info" depressed
                  >Save Details</v-btn
                >
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Promo Recipe eBook 2</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <div class="d-flex gap-1 align-start px-0 px-md-5 px-lg-8 flex-wrap flex-lg-nowrap">
                <div class="col-12 col-lg-11 px-0">
                  <v-card class="my-0 my-md-5 my-lg-5 box-shadow primary pa-1 pa-md-5 pa-lg-5 ribbon-container" outlined>
                    <span class="ribbon-name">Ebook Title Page: </span>
                    <span class="ribbon-button" v-if="lgUp">
                      <v-btn
                        class="mt-2"
                        @click="copyRecipeLink(2, true)"
                        color="primary"
                        x-small
                        depressed
                      >
                        <v-icon class="mr-1" x-small>mdi-content-copy</v-icon>
                        Client Questionnaire Link for Promo Recipe eBook 2
                      </v-btn>
                    </span>
                    <span class="ribbon-button-sm" v-else>
                      <v-btn
                        class="mt-2 mr-5"
                        @click="copyRecipeLink(1, true)"
                        color="primary"
                        x-small
                        depressed
                      >
                        <v-icon class="mr-1" x-small>mdi-content-copy</v-icon>
                        Copy Link
                      </v-btn>
                    </span>
                    <div class="d-flex gap-1 align-start justify-start justify-md-center justify-lg-center flex-wrap">
                      <!-- TO BE OPTIMIZED (PUT IN IT"S OWN CONTAINER) -->
                      <div class="popover__wrapper">
                        <v-icon
                          small
                          class="white--text"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                        <div class="popover__content">
                          <p class="popover__message">This will show your clients or potentional clients who is behind the promo recipe eBook. The eBooks are also branded with your logo and colours.</p>  
                          <div class="popover__actions">
                            <v-btn
                              depressed
                              x-small
                              class="white--text info"
                              @click="openLink('https://vimeo.com/1038769900?')"
                            >
                            View sample video
                            </v-btn>
                          </div>
                        </div>
                      </div>
                      <v-chip
                        class="ma-1 ma-lg-2"
                        label
                        style="background-color: #463f57; color: #fff"
                      >
                        Business Name
                      </v-chip>
                      <v-text-field
                        class="mr-2"
                        v-model="promoData2.businessName"
                        outlined
                        dense
                        background-color="white"
                      >
                      </v-text-field>
                     <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                      <div class="popover__wrapper">
                        <v-icon
                          small
                          class="white--text"
                          v-if="mdUp"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                        <div class="popover__content" style="left: -1000%">
                          <p class="popover__message">Click the preview button to see what the page will look like on various screen sizes. Be mindful that most people will be looking at the recipe eBooks on a mobile device; therefore adding smaller images may provide a better user experience. A good pixel width for mobile is 250px</p>  
                          <div class="popover__actions">
                            <v-btn
                              depressed
                              x-small
                              class="white--text info"
                              @click="openLink('https://vimeo.com/1038774160')"
                            >
                            View sample video
                            </v-btn>
                          </div>
                        </div>
                      </div>
                      <v-btn
                        depressed
                        icon
                        @click="() => {
                            previewTitleDialog2 = true;
                            itemToShow = 'pageTitle';
                          }
                          "
                        :disabled="!promoData2?.businessName || !promoData2.bio"
                      >
                        <v-icon v-if="mdUp" large style="color: #463f57"> mdi-eye </v-icon>
                      </v-btn>
                    </div>
                    <div class="d-flex gap-1 align-start justify-start flex-wrap">
                      <!-- TO BE OPTIMIZED (PUT IN IT"S OWN CONTAINER) -->
                      <div class="popover__wrapper">
                        <v-icon
                          small
                          class="white--text"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                        <div class="popover__content">
                          <p class="popover__message">Promo recipe eBooks are generated on the spot for anyone who completes a 'Client Questionnaire'. For that reason, you need to pre-build the page(s) within the book. Page 1 is the very first page people will see once the eBook is generated. Think of this as a web or landing page to introduce yourself to your clients or potential clients. You can format this page with different font colours, font sizes and alignment. You can add images and Youtube videos, and can also hyerlink images or text to point to a webpage of your choice.</p>  
                          <div class="popover__actions">
                            <v-btn
                              depressed
                              x-small
                              class="white--text info"
                              @click="openLink('https://vimeo.com/1038769705?')"
                            >
                            View sample video
                            </v-btn>
                          </div>
                        </div>
                      </div>
                      <v-chip
                        class="ma-2"
                        label
                        style="background-color: #463f57; color: #fff"
                      >
                        Page 1 of Promo eBook
                      </v-chip>
                    </div>
                    <div style="background-color: white">
                      <vue-editor
                        v-model="promoData2.bio"
                        background-color="white"
                        placeholder="(500 Character Limit)"
                        class="mb-3"
                      />
                    </div>
                  </v-card>
                </div>
              </div>
              <div class="d-flex gap-1 px-0 px-md-5 px-lg-8 align-start flex-sm-wrap flex-lg-nowrap">
                <div class="col-12 col-md-10 col-lg-11 col-xl-10">
                  <div
                    class="d-flex flex-wrap flex-xl-nowrap align-start justify-center gap-3"
                  >
                    <div class="col-12 col-lg-10 col-xl-6">
                      <v-card
                        class="my-5 box-shadow primary pa-2 pa-md-5 pa-lg-5 ribbon-container"
                        outlined
                      >
                        <span class="ribbon-name">
                          <div class="d-flex gap-1 align-start justify-center flex-wrap gap-5">
                            <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                            <div class="popover__wrapper mt-0">
                              <v-icon
                                small
                                class="white--text"
                                v-if="mdUp"
                              >
                                mdi-help-circle-outline
                              </v-icon>
                              <div class="popover__content">
                                <p class="popover__message">This is an optional button you can add that appears at the top of the eBook.</p>  
                                <div class="popover__actions">
                                  <v-btn
                                    depressed
                                    x-small
                                    class="white--text info"
                                    @click="openLink('https://vimeo.com/1038769019')"
                                  >
                                  View sample video
                                  </v-btn>
                                </div>
                              </div>
                            </div>
                            Header Call-To-Action:
                          </div>
                        </span>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              <div class="d-flex align-start justify-start flex-wrap gap-5">
                                <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                                <div class="popover__wrapper mt-0">
                                  <v-icon
                                    small
                                    class="white--text"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                  <div class="popover__content">
                                    <p class="popover__message">This is the text the client sees when the button is visible in the promo recipe eBook.</p>  
                                  </div>
                                </div>
                                Header Button Name
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData2.headerButtonName"
                              outlined
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              <div class="d-flex gap-1 align-start justify-center flex-wrap gap-5">
                              <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                              <div class="popover__wrapper mt-0">
                                <v-icon
                                  small
                                  class="white--text"
                                >
                                  mdi-help-circle-outline
                                </v-icon>
                                <div class="popover__content">
                                  <p class="popover__message">This is the URL your clients will navigate to if they click the button</p>  
                                </div>
                              </div>
                              Header Button Link
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData2.headerButtonLink"
                              outlined
                              hint="Please add 'http://' in the front"
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-6">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              <div class="d-flex gap-1 align-center justify-center flex-wrap gap-5">
                              <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                                <div class="popover__wrapper mt-0">
                                  <v-icon
                                    small
                                    class="white--text"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                  <div class="popover__content">
                                    <p class="popover__message">The header button is optional. You can switch it on or off</p>  
                                  </div>
                                </div>
                                Header Button On/Off
                                <v-switch
                                  v-model="promoData2.headerButton"
                                  color="primary"
                                  class="ml-3"
                                ></v-switch>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-6">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              <div class="d-flex gap-1 align-center justify-center flex-wrap gap-5">
                              <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                                <div class="popover__wrapper mt-0">
                                  <v-icon
                                    small
                                    class="white--text"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                  <div class="popover__content">
                                    <p class="popover__message">You can set the colour of your button. Be sure to test it on various books to see how it presents. It's recommended to use the same colour as your brand colour for the header button.</p>  
                                  </div>
                                </div>
                                Header Button Color 
                                <input
                                  type="color"
                                  id="head"
                                  name="header button color"
                                  class="ml-3"
                                  v-model="promoData2.headerButtonColor"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-card>
                    </div>
                    <div class="col-12 col-lg-10 col-xl-6">
                      <v-card
                        class="my-5 box-shadow primary pa-2 pa-md-5 pa-lg-5 ribbon-container"
                        outlined
                      >
                        <span class="ribbon-name">
                          <div class="d-flex gap-1 align-start justify-center flex-wrap gap-5">
                          <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                            <div class="popover__wrapper mt-0">
                              <v-icon
                                small
                                class="white--text"
                                v-if="mdUp"
                              >
                                mdi-help-circle-outline
                              </v-icon>
                              <div class="popover__content">
                                <p class="popover__message">This is an optional button you can add that appears on the footer of the eBook.</p>  
                              </div>
                            </div>
                            Footer Call-To-Action:
                          </div>
                        </span>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              <div class="d-flex align-start justify-start flex-wrap gap-5">
                                <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                                <div class="popover__wrapper mt-0">
                                  <v-icon
                                    small
                                    class="white--text"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                  <div class="popover__content">
                                    <p class="popover__message">This is the text the client sees when the button is visible in the promo recipe eBook.</p>  
                                  </div>
                                </div>
                                Footer Button Name
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData2.footerButtonName"
                              outlined
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Footer Button Link
                            </v-chip>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData2.footerButtonLink"
                              outlined
                              hint="Please add 'http://' in the front"
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-6">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              <div class="d-flex gap-1 align-center justify-center flex-wrap gap-5">
                              <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                                <div class="popover__wrapper mt-0">
                                  <v-icon
                                    small
                                    class="white--text"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                  <div class="popover__content">
                                    <p class="popover__message">The footer button is optional. You can switch it on or off.</p>  
                                  </div>
                                </div>
                                Footer Button On/Off
                                <v-switch
                                  v-model="promoData2.footerButton"
                                  color="primary"
                                  class="ml-3"
                                ></v-switch>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-6">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                             <div class="d-flex gap-1 align-center justify-center flex-wrap gap-5">
                              <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                                <div class="popover__wrapper mt-0">
                                  <v-icon
                                    small
                                    class="white--text"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                  <div class="popover__content">
                                    <p class="popover__message">You can set the colour of your button. It's recommended to use a contrasting colour to that of your brand colour. Black seems to work well for most colours. Be sure to test it on various books to see how it presents.</p>  
                                  </div>
                                </div>
                                Footer Button Color
                                <input
                                  type="color"
                                  id="head"
                                  name="header button color"
                                  class="ml-3"
                                  v-model="promoData2.footerButtonColor"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-4 col-sm-12 col-lg-2"></div> -->
              </div>
              <div class="d-flex gap-20 align-start px-10 flex-wrap flex-lg-nowrap">
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">This determines how often a client can download a promo recipe eBook. If they attempt to complete the client questionnaire twice within the interval frequency, they will see a pop-up message that you have customised in the 'Interval Message'</p>  
                    <div class="popover__actions">
                      <v-btn
                        depressed
                        x-small
                        class="white--text info"
                        @click="openLink('https://vimeo.com/1038770112')"
                      >
                      View sample video
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div class="caption mb-2">Recipe eBook download available every:</div>

                <div class="d-flex justify-start justify-md-center justify-lg-center flex-wrap gap-12">
                  <div>
                    <v-select
                      dense
                      outlined
                      :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                      placeholder="1"
                      v-model="promoData2.dateNumber"
                    ></v-select>
                  </div>
                  <div>
                    <v-select
                      outlined
                      dense
                      placeholder="week"
                      :items="['day', 'week', 'month', 'year']"
                      v-model="promoData2.dateName"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="d-flex gap-1 align-start px-0 px-md-5 px-lg-8 flex-wrap flex-xl-nowrap">
                <div class="col-12 col-lg-11 col-xl-5">
                  <v-card class="my-5 box-shadow primary pa-0 pa-md-3 pa-lg-5 ribbon-container" outlined>
                    <span class="ribbon-name">
                      <div class="d-flex gap-1 align-start justify-center flex-wrap gap-5">
                        <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                        <div class="popover__wrapper mt-0">
                          <v-icon
                            small
                            class="white--text"
                            v-if="mdUp"
                          >
                            mdi-help-circle-outline
                          </v-icon>
                          <div class="popover__content">
                            <p class="popover__message">This is the message users will see if they attempt to complete the client questionnaire within the set interval frequency. You can customise this message to whatever you like. </p>  
                          </div>
                        </div>
                        Interval Message: 
                      </div>
                    </span>
                    <div class="d-flex gap-1 align-start justify-start">
                      <div class="col-12">
                        <div style="background-color: white">
                          <vue-editor
                            v-model="promoData2.intervalMessage"
                            background-color="white"
                            placeholder="Interval Message (500 Character Limit)"
                            class="mb-3"
                          />
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
                <div class="col-12 col-md-6 col-lg-6 col-xl-5">
                  <v-card class="my-5 box-shadow primary pa-2 pa-lg-5 ribbon-container" outlined>
                    <span class="ribbon-name">Page Ads: </span>
                    <div class="d-flex gap-1 align-start justify-start">
                      <div class="col-12 col-lg-7">
                        <div
                          class="ma-2 custom-chip"
                          label
                          style="background-color: #463f57; color: #fff"
                        >
                         <div class="d-flex gap-1 align-center justify-center flex-wrap gap-5">
                          <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                            <div class="popover__wrapper mt-0">
                              <v-icon
                                small
                                class="white--text"
                              >
                                mdi-help-circle-outline
                              </v-icon>
                              <div class="popover__content">
                                <p class="popover__message">This is an optional page you can add to the eBook. It functions identically to page 1. It is called page 3 as every eBook has an instruction page as page 2. If you toggle this page on, you will need to add the content you want to appear on this page. Like page 1, page 3 has a page title, and optional call to action button. If you decide to add a page 3, this is where you may place offers or promotions for your business. Once you have added your content to this page, click the 'Add Details' button and then click 'Save Details'.</p>  
                              </div>
                            </div>
                            Page 3 of Promo eBook
                            <v-switch
                              v-model="promoData2.pageAd1"
                              color="primary"
                              class="ml-3"
                            ></v-switch>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  :disabled="!promoData2.pageAd1"
                                  v-on="on"
                                  color="white"
                                  icon
                                  depressed
                                  small
                                  @click="pageAd2Dialog = true"
                                >
                                  <v-icon>mdi-note-edit-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>Click to open</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-wrap flex-lg-nowrap mb-3"
              >
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">Hide Nutrition Info: Toggle this on to hide the nutrition info from the recipe eBook.
                    <br/>
                    <br/>
                    Hide Meal Tags: Toggle this on to hide the meal tags from the recipe eBook.
                    <br/>
                    <br/>
                    Hide Nutrition Tags: Toggle this on to hide the nutrition tags from the recipe eBook.
                    <br/>
                    <br/>
                    Hide Recipe Customisation: Toggle this on to hide the ability for users to customise recipes within the eBook. This is useful if you are the one creating the recipe eBook to then share with multiple users. If an eBook is shared with multiple people, it is a good idea to hide the recipe customisation feature.</p>  
                  </div>
                </div>
                <div class="caption mb-2">Hide these from pdf's/ebooks:</div>

                <div class="d-flex gap-15 flex-wrap flex-lg-nowrap">
                  <v-switch
                    label="Hide Nutrition Info"
                    v-model="promoData2.hideEnergy"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                  <v-switch
                    label="Hide Meal Tags"
                    v-model="promoData2.hideMealTags"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                  <v-switch
                    label="Hide Nutrition Tags"
                    v-model="promoData2.hideNutriTags"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                  <v-switch
                    label="Hide Recipe Customization"
                    v-model="promoData2.hideCustomization"
                    hide-details=""
                    class="mt-0 pt-0"
                    dense
                  >
                  </v-switch>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-wrap flex-lg-nowrap justify-space-between"
              >
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">
                      You can pre-load diet preferences so that when someone generates a recipe eBook, the recipes are all aligned with this preference. For example, if you add the 'Gluten-free' tag here. All recipe in this eBook will be 'Gluten-free'.
                    </p>
                  </div>
                </div>
                <div class="caption mb-2">
                  Please list any diet preferences i.e. low carb or gluten-free:
                </div>
                <div class="d-flex" style="width: 100%">
                  <v-sheet width="350" color="transparent">
                    <v-combobox
                      placeholder="PLEASE PRESS ENTER AFTER EACH DIETARY PREFERENCE BEFORE ADDING THE NEXT"
                      v-model="promoData2.tags"
                      class="meal-tags align-start"
                      append-icon=""
                      deletable-chips
                      @change="($event) => filterNutriTags($event, 2)"
                      width="100%"
                      small-chips
                      multiple
                      outlined
                      chips
                      dense
                    ></v-combobox>
                  </v-sheet>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start"
              >
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">
                      Our recipe eBooks will not go below 5000kJ (1200Cal). If you believe this is too low, you can set your own calorie limit. This means that the recipe eBook Calorie average will not go below your set limit. If you set it at 2000Cal, then the recipes provided will not equate to less than 2000Cal per day.
                    </p>
                  </div>
                </div>
                <div class="caption mb-2">
                  Set a Calorie limit:
                </div>
                <div class="d-flex gap-15">
                  <v-switch
                    v-model="promoData2.setCalorieLimit"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start"
                v-if="promoData2?.setCalorieLimit"
              >
                <div class="caption mb-2">
                  Calorie limit:
                </div>
                <div class="d-flex gap-15">
                  <v-text-field
                    :rules="[
                      (value) =>
                        [
                          'dietitian',
                          'personal_trainer',
                          'nutritionist',
                          'naturopath',
                        ].includes(user.role) ||
                        (!value && value <= 0) ||
                        value >= 5000 ||
                        `Value can't be less than 5000kJ`,
                    ]"
                    type="number"
                    v-model.number="promoData2.calorieLimit"
                    suffix="kJ"
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    :value="Math.round((promoData2.calorieLimit || 0) / 4.18)"
                    @input="promoData2.calorieLimit = $event * 4.18"
                    type="number"
                    suffix="Cal"
                    outlined
                    dense
                  ></v-text-field>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start mb-4"
              >
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">
                      You may charge a fee or subscription for your clients to access your recipe eBooks. This feature allows you to add the email addresses of those who have subscribed. If this is toggled on, you will need to add the emails of those who have subscribed. Anyone whose email is not on this list and attempts to complete the 'Client Questionnaire', will not be able to download a recipe eBook.
                    </p>
                  </div>
                </div>
                <div class="caption mb-2">
                  Set an Exclusive List:
                </div>
                <div class="d-flex gap-15">
                  <v-switch
                    v-model="promoData2.setExclusiveList"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start"
                v-if="promoData2?.setExclusiveList"
              >
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">
                      This is where you can add individual emails to the Exclusive List.
                    </p>
                  </div>
                </div>
                <div class="caption mb-2">
                  Add an email to your Exclusive List:
                </div>
                <v-sheet color="transparent" min-width="450">
                  <v-combobox
                    v-model="promoData2.exclusiveList"
                    @change="autoSave2()"
                    class="meal-tags align-start"
                    hint="Press enter to add"
                    append-icon=""
                    deletable-chips
                    width="100%"
                    small-chips
                    multiple
                    outlined
                    chips
                    dense
                  ></v-combobox>
                </v-sheet>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start"
                v-if="promoData2?.setExclusiveList"
              >
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">
                      You can add emails in bulk by uploading a CSV file. Simply have all emails in one column.
                    </p>
                  </div>
                </div>
                <div class="caption mb-2">
                  Bulk email upload via csv file:
                </div>
                <v-sheet min-width="300">
                  <v-file-input
                      label="File input"
                      outlined
                      dense
                      v-model="file"
                  >
                  </v-file-input>
                  <v-btn :disabled="!file" class="mb-5 white--text" @click="convertToJson(2)" small depressed color="grey darken-1" :loading="loading">Upload</v-btn>
                </v-sheet>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start mb-4"
                v-if="promoData2?.setExclusiveList"
              >
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">
                      This is the message users will see if they attempt to complete the 'Client Questionnaire' yet their email has not been added to the Exclusive List.
                    </p>
                  </div>
                </div>
                <div class="caption mb-2">
                  Access Upgrade Message:
                </div>
                <div class="d-flex gap-1 align-start justify-start">
                  <div class="col-12">
                    <div style="background-color: white">
                      <vue-editor
                        v-model="promoData2.intervalMessageForExclusiveList"
                        background-color="white"
                        placeholder="Interval Message (500 Character Limit)"
                        class="mb-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex gap-20 align-start flex-sm-wrap flex-lg-nowrap mt-8 mb-5 px-5">
                <v-btn @click="submitData2" :loading="submitting" class="info" depressed
                  >Save Details</v-btn
                >
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Promo Recipe eBook 3</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <div class="d-flex gap-1 align-start px-0 px-md-5 px-lg-8 flex-wrap flex-lg-nowrap">
                <div class="col-12 col-lg-11 px-0">
                  <v-card class="my-0 my-md-5 my-lg-5 box-shadow primary pa-1 pa-md-5 pa-lg-5 ribbon-container" outlined>
                    <span class="ribbon-name">Ebook Title Page: </span>
                    <span class="ribbon-button" v-if="lgUp">
                      <v-btn
                        class="mt-2"
                        @click="copyRecipeLink(3, true)"
                        color="primary"
                        x-small
                        depressed
                      >
                        <v-icon class="mr-1" x-small>mdi-content-copy</v-icon>
                        Client Questionnaire Link for Promo Recipe eBook 3
                      </v-btn>
                    </span>
                    <span class="ribbon-button-sm" v-else>
                      <v-btn
                        class="mt-2 mr-5"
                        @click="copyRecipeLink(1, true)"
                        color="primary"
                        x-small
                        depressed
                      >
                        <v-icon class="mr-1" x-small>mdi-content-copy</v-icon>
                        Copy Link
                      </v-btn>
                    </span>
                    <div class="d-flex gap-1 align-start justify-start justify-md-center justify-lg-center flex-wrap">
                      <!-- TO BE OPTIMIZED (PUT IN IT"S OWN CONTAINER) -->
                      <div class="popover__wrapper">
                        <v-icon
                          small
                          class="white--text"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                        <div class="popover__content">
                          <p class="popover__message">This will show your clients or potentional clients who is behind the promo recipe eBook. The eBooks are also branded with your logo and colours.</p>  
                          <div class="popover__actions">
                            <v-btn
                              depressed
                              x-small
                              class="white--text info"
                              @click="openLink('https://vimeo.com/1038769900?')"
                            >
                            View sample video
                            </v-btn>
                          </div>
                        </div>
                      </div>
                      <v-chip
                        class="ma-1 ma-lg-2"
                        label
                        style="background-color: #463f57; color: #fff"
                      >
                        Business Name
                      </v-chip>
                      <v-text-field
                        class="mr-2"
                        v-model="promoData3.businessName"
                        outlined
                        dense
                        background-color="white"
                      >
                      </v-text-field>
                     <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                      <div class="popover__wrapper">
                        <v-icon
                          small
                          class="white--text"
                          v-if="mdUp"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                        <div class="popover__content" style="left: -1000%">
                          <p class="popover__message">Click the preview button to see what the page will look like on various screen sizes. Be mindful that most people will be looking at the recipe eBooks on a mobile device; therefore adding smaller images may provide a better user experience. A good pixel width for mobile is 250px</p>  
                          <div class="popover__actions">
                            <v-btn
                              depressed
                              x-small
                              class="white--text info"
                              @click="openLink('https://vimeo.com/1038774160')"
                            >
                            View sample video
                            </v-btn>
                          </div>
                        </div>
                      </div>
                      <v-btn
                        depressed
                        icon
                        @click="() => {
                            previewTitleDialog3 = true;
                            itemToShow = 'pageTitle';
                          }
                          "
                        :disabled="!promoData3?.businessName || !promoData.bio"
                      >
                        <v-icon v-if="mdUp" large style="color: #463f57"> mdi-eye </v-icon>
                      </v-btn>
                    </div>
                    <div class="d-flex gap-1 align-start justify-start flex-wrap">
                      <!-- TO BE OPTIMIZED (PUT IN IT"S OWN CONTAINER) -->
                      <div class="popover__wrapper">
                        <v-icon
                          small
                          class="white--text"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                        <div class="popover__content">
                          <p class="popover__message">Promo recipe eBooks are generated on the spot for anyone who completes a 'Client Questionnaire'. For that reason, you need to pre-build the page(s) within the book. Page 1 is the very first page people will see once the eBook is generated. Think of this as a web or landing page to introduce yourself to your clients or potential clients. You can format this page with different font colours, font sizes and alignment. You can add images and Youtube videos, and can also hyerlink images or text to point to a webpage of your choice.</p>  
                          <div class="popover__actions">
                            <v-btn
                              depressed
                              x-small
                              class="white--text info"
                              @click="openLink('https://vimeo.com/1038769705?')"
                            >
                            View sample video
                            </v-btn>
                          </div>
                        </div>
                      </div>
                      <v-chip
                        class="ma-2"
                        label
                        style="background-color: #463f57; color: #fff"
                      >
                        Page 1 of Promo eBook
                      </v-chip>
                    </div>
                    <div style="background-color: white">
                      <vue-editor
                        v-model="promoData3.bio"
                        background-color="white"
                        placeholder="(500 Character Limit)"
                        class="mb-3"
                      />
                    </div>
                  </v-card>
                </div>
                <!-- <div class="col-12 col-md-4 col-lg-2">
        <v-card
          class="my-5 box-shadow primary pa-5 ribbon-container text-center"
          outlined
        >
          <span class="ribbon-name">QR Code: </span>
          <div>
            <qrcode-vue :value="generatedLink" :size="size" level="M" id="qr-code" />
          </div>
          <v-btn @click="printQR" x-small depressed color="info"> Download </v-btn>
        </v-card>
      </div> -->
              </div>
              <div class="d-flex gap-1 px-0 px-md-5 px-lg-8 align-start flex-sm-wrap flex-lg-nowrap">
                <div class="col-12 col-md-10 col-lg-11 col-xl-10">
                  <div
                    class="d-flex flex-wrap flex-xl-nowrap align-start justify-center gap-3"
                  >
                    <div class="col-12 col-lg-10 col-xl-6">
                      <v-card
                        class="my-5 box-shadow primary pa-2 pa-md-5 pa-lg-5 ribbon-container"
                        outlined
                      >
                        <span class="ribbon-name">
                          <div class="d-flex gap-1 align-start justify-center flex-wrap gap-5">
                            <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                            <div class="popover__wrapper mt-0">
                              <v-icon
                                small
                                class="white--text"
                                v-if="mdUp"
                              >
                                mdi-help-circle-outline
                              </v-icon>
                              <div class="popover__content">
                                <p class="popover__message">This is an optional button you can add that appears at the top of the eBook.</p>  
                                <div class="popover__actions">
                                  <v-btn
                                    depressed
                                    x-small
                                    class="white--text info"
                                    @click="openLink('https://vimeo.com/1038769019')"
                                  >
                                  View sample video
                                  </v-btn>
                                </div>
                              </div>
                            </div>
                            Header Call-To-Action:
                          </div>
                        </span>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              <div class="d-flex align-start justify-start flex-wrap gap-5">
                                <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                                <div class="popover__wrapper mt-0">
                                  <v-icon
                                    small
                                    class="white--text"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                  <div class="popover__content">
                                    <p class="popover__message">This is the text the client sees when the button is visible in the promo recipe eBook.</p>  
                                  </div>
                                </div>
                                Header Button Name
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData3.headerButtonName"
                              outlined
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              <div class="d-flex gap-1 align-start justify-center flex-wrap gap-5">
                              <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                              <div class="popover__wrapper mt-0">
                                <v-icon
                                  small
                                  class="white--text"
                                >
                                  mdi-help-circle-outline
                                </v-icon>
                                <div class="popover__content">
                                  <p class="popover__message">This is the URL your clients will navigate to if they click the button</p>  
                                </div>
                              </div>
                              Header Button Link
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData3.headerButtonLink"
                              outlined
                              hint="Please add 'http://' in the front"
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-6">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              <div class="d-flex gap-1 align-center justify-center flex-wrap gap-5">
                              <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                                <div class="popover__wrapper mt-0">
                                  <v-icon
                                    small
                                    class="white--text"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                  <div class="popover__content">
                                    <p class="popover__message">The header button is optional. You can switch it on or off</p>  
                                  </div>
                                </div>
                                Header Button On/Off
                                <v-switch
                                  v-model="promoData3.headerButton"
                                  color="primary"
                                  class="ml-3"
                                ></v-switch>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-6">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              <div class="d-flex gap-1 align-center justify-center flex-wrap gap-5">
                              <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                                <div class="popover__wrapper mt-0">
                                  <v-icon
                                    small
                                    class="white--text"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                  <div class="popover__content">
                                    <p class="popover__message">You can set the colour of your button. Be sure to test it on various books to see how it presents. It's recommended to use the same colour as your brand colour for the header button.</p>  
                                  </div>
                                </div>
                                Header Button Color 
                                <input
                                  type="color"
                                  id="head"
                                  name="header button color"
                                  class="ml-3"
                                  v-model="promoData3.headerButtonColor"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-card>
                    </div>
                    <div class="col-12 col-lg-10 col-xl-6">
                      <v-card
                        class="my-5 box-shadow primary pa-2 pa-md-5 pa-lg-5 ribbon-container"
                        outlined
                      >
                        <span class="ribbon-name">
                          <div class="d-flex gap-1 align-start justify-center flex-wrap gap-5">
                          <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                            <div class="popover__wrapper mt-0">
                              <v-icon
                                small
                                class="white--text"
                                v-if="mdUp"
                              >
                                mdi-help-circle-outline
                              </v-icon>
                              <div class="popover__content">
                                <p class="popover__message">This is an optional button you can add that appears on the footer of the eBook.</p>  
                              </div>
                            </div>
                            Footer Call-To-Action:
                          </div>
                        </span>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              <div class="d-flex align-start justify-start flex-wrap gap-5">
                                <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                                <div class="popover__wrapper mt-0">
                                  <v-icon
                                    small
                                    class="white--text"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                  <div class="popover__content">
                                    <p class="popover__message">This is the text the client sees when the button is visible in the promo recipe eBook.</p>  
                                  </div>
                                </div>
                                Footer Button Name
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData3.footerButtonName"
                              outlined
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Footer Button Link
                            </v-chip>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData3.footerButtonLink"
                              outlined
                              hint="Please add 'http://' in the front"
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-6">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              <div class="d-flex gap-1 align-center justify-center flex-wrap gap-5">
                              <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                                <div class="popover__wrapper mt-0">
                                  <v-icon
                                    small
                                    class="white--text"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                  <div class="popover__content">
                                    <p class="popover__message">The footer button is optional. You can switch it on or off.</p>  
                                  </div>
                                </div>
                                Footer Button On/Off
                                <v-switch
                                  v-model="promoData3.footerButton"
                                  color="primary"
                                  class="ml-3"
                                ></v-switch>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-6">
                            <div
                              class="ma-0 ma-lg-2 custom-chip"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                             <div class="d-flex gap-1 align-center justify-center flex-wrap gap-5">
                              <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                                <div class="popover__wrapper mt-0">
                                  <v-icon
                                    small
                                    class="white--text"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                  <div class="popover__content">
                                    <p class="popover__message">You can set the colour of your button. It's recommended to use a contrasting colour to that of your brand colour. Black seems to work well for most colours. Be sure to test it on various books to see how it presents.</p>  
                                  </div>
                                </div>
                                Footer Button Color
                                <input
                                  type="color"
                                  id="head"
                                  name="header button color"
                                  class="ml-3"
                                  v-model="promoData3.footerButtonColor"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-4 col-sm-12 col-lg-2"></div> -->
              </div>
              <div class="d-flex gap-20 align-start px-10 flex-wrap flex-lg-nowrap">
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">This determines how often a client can download a promo recipe eBook. If they attempt to complete the client questionnaire twice within the interval frequency, they will see a pop-up message that you have customised in the 'Interval Message'</p>  
                    <div class="popover__actions">
                      <v-btn
                        depressed
                        x-small
                        class="white--text info"
                        @click="openLink('https://vimeo.com/1038770112')"
                      >
                      View sample video
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div class="caption mb-2">Recipe eBook download available every:</div>

                <div class="d-flex justify-start justify-md-center justify-lg-center flex-wrap gap-12">
                  <div>
                    <v-select
                      dense
                      outlined
                      :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                      placeholder="1"
                      v-model="promoData3.dateNumber"
                    ></v-select>
                  </div>
                  <div>
                    <v-select
                      outlined
                      dense
                      placeholder="week"
                      :items="['day', 'week', 'month', 'year']"
                      v-model="promoData3.dateName"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="d-flex gap-1 align-start px-0 px-md-5 px-lg-8 flex-wrap flex-xl-nowrap">
                <div class="col-12 col-lg-11 col-xl-5">
                  <v-card class="my-5 box-shadow primary pa-0 pa-md-3 pa-lg-5 ribbon-container" outlined>
                    <span class="ribbon-name">
                      <div class="d-flex gap-1 align-start justify-center flex-wrap gap-5">
                        <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                        <div class="popover__wrapper mt-0">
                          <v-icon
                            small
                            class="white--text"
                            v-if="mdUp"
                          >
                            mdi-help-circle-outline
                          </v-icon>
                          <div class="popover__content">
                            <p class="popover__message">This is the message users will see if they attempt to complete the client questionnaire within the set interval frequency. You can customise this message to whatever you like. </p>  
                          </div>
                        </div>
                        Interval Message: 
                      </div>
                    </span>
                    <div class="d-flex gap-1 align-start justify-start">
                      <div class="col-12">
                        <div style="background-color: white">
                          <vue-editor
                            v-model="promoData3.intervalMessage"
                            background-color="white"
                            placeholder="Interval Message (500 Character Limit)"
                            class="mb-3"
                          />
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
                <div class="col-12 col-md-6 col-lg-6 col-xl-5">
                  <v-card class="my-5 box-shadow primary pa-2 pa-lg-5 ribbon-container" outlined>
                    <span class="ribbon-name">Page Ads: </span>
                    <div class="d-flex gap-1 align-start justify-start">
                      <div class="col-12 col-lg-7">
                        <div
                          class="ma-2 custom-chip"
                          label
                          style="background-color: #463f57; color: #fff"
                        >
                        <div class="d-flex gap-1 align-center justify-center flex-wrap gap-5">
                          <!-- TO BE OPTIMIZED (PUT IN IT"S OWN COMPONENT) -->
                            <div class="popover__wrapper mt-0">
                              <v-icon
                                small
                                class="white--text"
                              >
                                mdi-help-circle-outline
                              </v-icon>
                              <div class="popover__content">
                                <p class="popover__message">This is an optional page you can add to the eBook. It functions identically to page 1. It is called page 3 as every eBook has an instruction page as page 2. If you toggle this page on, you will need to add the content you want to appear on this page. Like page 1, page 3 has a page title, and optional call to action button. If you decide to add a page 3, this is where you may place offers or promotions for your business. Once you have added your content to this page, click the 'Add Details' button and then click 'Save Details'.</p>  
                              </div>
                            </div>
                            Page 3 of Promo eBook
                            <v-switch
                              v-model="promoData3.pageAd1"
                              color="primary"
                              class="ml-3"
                            ></v-switch>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  :disabled="!promoData3.pageAd1"
                                  v-on="on"
                                  color="white"
                                  icon
                                  depressed
                                  small
                                  @click="pageAd3Dialog = true"
                                >
                                  <v-icon>mdi-note-edit-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>Click to open</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-wrap flex-lg-nowrap mb-3"
              >
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">Hide Nutrition Info: Toggle this on to hide the nutrition info from the recipe eBook.
                    <br/>
                    <br/>
                    Hide Meal Tags: Toggle this on to hide the meal tags from the recipe eBook.
                    <br/>
                    <br/>
                    Hide Nutrition Tags: Toggle this on to hide the nutrition tags from the recipe eBook.
                    <br/>
                    <br/>
                    Hide Recipe Customisation: Toggle this on to hide the ability for users to customise recipes within the eBook. This is useful if you are the one creating the recipe eBook to then share with multiple users. If an eBook is shared with multiple people, it is a good idea to hide the recipe customisation feature.</p>  
                  </div>
                </div>
                <div class="caption mb-2">Hide these from pdf's/ebooks:</div>

                <div class="d-flex gap-15 flex-wrap flex-lg-nowrap">
                  <v-switch
                    label="Hide Nutrition Info"
                    v-model="promoData3.hideEnergy"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                  <v-switch
                    label="Hide Meal Tags"
                    v-model="promoData3.hideMealTags"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                  <v-switch
                    label="Hide Nutrition Tags"
                    v-model="promoData3.hideNutriTags"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                  <v-switch
                    label="Hide Recipe Customization"
                    v-model="promoData3.hideCustomization"
                    hide-details=""
                    class="mt-0 pt-0"
                    dense
                  >
                  </v-switch>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-wrap flex-lg-nowrap justify-space-between"
              >
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">
                      You can pre-load diet preferences so that when someone generates a recipe eBook, the recipes are all aligned with this preference. For example, if you add the 'Gluten-free' tag here. All recipe in this eBook will be 'Gluten-free'.
                    </p>
                  </div>
                </div>
                <div class="caption mb-2">
                  Please list any diet preferences i.e. low carb or gluten-free:
                </div>
                <div class="d-flex" style="width: 100%">
                  <v-sheet width="350" color="transparent">
                    <v-combobox
                      placeholder="PLEASE PRESS ENTER AFTER EACH DIETARY PREFERENCE BEFORE ADDING THE NEXT"
                      v-model="promoData3.tags"
                      class="meal-tags align-start"
                      append-icon=""
                      deletable-chips
                      @change="($event) => filterNutriTags($event, 3)"
                      width="100%"
                      small-chips
                      multiple
                      outlined
                      chips
                      dense
                    ></v-combobox>
                  </v-sheet>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start"
              >
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">
                      Our recipe eBooks will not go below 5000kJ (1200Cal). If you believe this is too low, you can set your own calorie limit. This means that the recipe eBook Calorie average will not go below your set limit. If you set it at 2000Cal, then the recipes provided will not equate to less than 2000Cal per day.
                    </p>
                  </div>
                </div>
                <div class="caption mb-2">
                  Set a Calorie limit:
                </div>
                <div class="d-flex gap-15">
                  <v-switch
                    v-model="promoData3.setCalorieLimit"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start"
                v-if="promoData3?.setCalorieLimit"
              >
                <div class="caption mb-2">
                  Calorie limit:
                </div>
                <div class="d-flex gap-15">
                  <v-text-field
                    :rules="[
                      (value) =>
                        [
                          'dietitian',
                          'personal_trainer',
                          'nutritionist',
                          'naturopath',
                        ].includes(user.role) ||
                        (!value && value <= 0) ||
                        value >= 5000 ||
                        `Value can't be less than 5000kJ`,
                    ]"
                    type="number"
                    v-model.number="promoData3.calorieLimit"
                    suffix="kJ"
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    :value="Math.round((promoData3.calorieLimit || 0) / 4.18)"
                    @input="promoData3.calorieLimit = $event * 4.18"
                    type="number"
                    suffix="Cal"
                    outlined
                    dense
                  ></v-text-field>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start mb-4"
              >
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">
                      You may charge a fee or subscription for your clients to access your recipe eBooks. This feature allows you to add the email addresses of those who have subscribed. If this is toggled on, you will need to add the emails of those who have subscribed. Anyone whose email is not on this list and attempts to complete the 'Client Questionnaire', will not be able to download a recipe eBook.
                    </p>
                  </div>
                </div>
                <div class="caption mb-2">
                  Set an Exclusive List:
                </div>
                <div class="d-flex gap-15">
                  <v-switch
                    v-model="promoData3.setExclusiveList"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start"
                v-if="promoData3?.setExclusiveList"
              >
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">
                      This is where you can add individual emails to the Exclusive List.
                    </p>
                  </div>
                </div>
                <div class="caption mb-2">
                  Add an email to your Exclusive List:
                </div>
                <v-sheet color="transparent" min-width="450" max-width="550">
                  <v-combobox
                    v-model="promoData3.exclusiveList"
                    @change="autoSave3()"
                    class="meal-tags align-start"
                    hint="Press enter to add"
                    append-icon=""
                    deletable-chips
                    width="100%"
                    small-chips
                    multiple
                    outlined
                    chips
                    dense
                  ></v-combobox>
                </v-sheet>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start"
                v-if="promoData3?.setExclusiveList"
              >
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">
                      You can add emails in bulk by uploading a CSV file. Simply have all emails in one column.
                    </p>
                  </div>
                </div>
                <div class="caption mb-2">
                  Bulk email upload via csv file:
                </div>
                <v-sheet min-width="300">
                  <v-file-input
                      label="File input"
                      outlined
                      dense
                      v-model="file"
                  >
                  </v-file-input>
                  <v-btn :disabled="!file" class="mb-5 white--text" @click="convertToJson(3)" small depressed color="grey darken-1" :loading="loading">Upload</v-btn>
                </v-sheet>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start mb-4"
                v-if="promoData3?.setExclusiveList"
              >
                <div class="popover__wrapper mt-0">
                  <v-icon
                    small
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div class="popover__content">
                    <p class="popover__message">
                      This is the message users will see if they attempt to complete the 'Client Questionnaire' yet their email has not been added to the Exclusive List.
                    </p>
                  </div>
                </div>
                <div class="caption mb-2">
                  Access Upgrade Message:
                </div>
                <div class="d-flex gap-1 align-start justify-start">
                  <div class="col-12">
                    <div style="background-color: white">
                      <vue-editor
                        v-model="promoData3.intervalMessageForExclusiveList"
                        background-color="white"
                        placeholder="Interval Message (500 Character Limit)"
                        class="mb-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex gap-20 align-start flex-sm-wrap flex-lg-nowrap mt-8 mb-5 px-5">
                <v-btn @click="submitData3" :loading="submitting" class="info" depressed
                  >Save Details</v-btn
                >
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <!-- <div class="d-flex gap-1 align-start px-8 flex-wrap flex-lg-nowrap">
      <div class="col-12">
        <v-card class="my-5 box-shadow pa-5 ribbon-container" outlined>
          <span class="ribbon-name">Client List</span>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              outlined
              dense
              single-line
              hide-details
              background-color="white"
            ></v-text-field>

            <vue-json-to-csv :json-data="clients">
              <v-btn small class="ml-3 info" depressed>
                CSV <v-icon>mdi-download</v-icon>
              </v-btn>
            </vue-json-to-csv>
          </v-card-title>
          <v-data-table :headers="headers" :items="clients" :search="search">
            <template v-slot:[`item.link`]="{ item }">
              <v-btn depressed x-small class="primary mr-2" @click="goToLink(item.link)">
                Ebook Link
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div> -->
    <v-dialog v-model="pageAd1Dialog"  max-width="900" scrollable>
      <close-button @click="pageAd1Dialog = false" overflow />
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <div class="d-flex gap-1 align-start justify-center">
            <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
              Page Title
            </v-chip>
            <v-text-field
              class="mr-2"
              v-model="pageAd1Data.pageTitle"
              outlined
              dense
              background-color="white"
            >
            </v-text-field>
            <v-btn
              depressed
              icon
              @click="() => {
                  previewTitleDialog = true;
                  itemToShow = 'pageAd';
                }
                "
              :disabled="!pageAd1Data?.pageTitle ||
                !pageAd1Data.pageContent ||
                !pageAd1Data.actionName ||
                !pageAd1Data.actionLink ||
                !pageAd1Data.actionButton ||
                !pageAd1Data.actionLink
                "
            >
              <v-icon large style="color: #463f57"> mdi-eye </v-icon>
            </v-btn>
          </div>
          <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
            Page Content
          </v-chip>
          <div style="background-color: white">
            <vue-editor
              v-model="pageAd1Data.pageContent"
              background-color="white"
              placeholder="Page Content (500 Character Limit)"
              class="mb-3"
            />
          </div>
          <div class="d-flex align-start justify-center">
            <div class="col-4">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action Name
              </v-chip>
            </div>
            <div class="col-8">
              <v-text-field
                class="mr-2"
                v-model="pageAd1Data.actionName"
                outlined
                dense
                background-color="white"
              >
              </v-text-field>
            </div>
          </div>
          <div class="d-flex gap-1 align-start justify-center">
            <div class="col-4">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action Link
              </v-chip>
            </div>
            <div class="col-8">
              <v-text-field
                class="mr-2"
                v-model="pageAd1Data.actionLink"
                outlined
                hint="Please add 'http://' in the front"
                dense
                background-color="white"
              >
              </v-text-field>
            </div>
          </div>
          <div class="d-flex gap-1 align-start justify-center">
            <div class="col-6">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action On/Off
                <v-switch
                  v-model="pageAd1Data.actionButton"
                  color="primary"
                  class="ml-3"
                ></v-switch>
              </v-chip>
            </div>
            <div class="col-6">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action Button Color
                <input
                  type="color"
                  id="head"
                  name="header button color"
                  class="ml-3"
                  v-model="pageAd1Data.actionButtonColor"
                />
              </v-chip>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="pageAd1Dialog = false" class="primary" depressed
            >Add Details</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pageAd2Dialog" max-width="900" scrollable>
      <close-button @click="pageAd2Dialog = false" overflow />
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <div class="d-flex gap-1 align-start justify-center">
            <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
              Page Title
            </v-chip>
            <v-text-field
              class="mr-2"
              v-model="pageAd2Data.pageTitle"
              outlined
              dense
              background-color="white"
            >
            </v-text-field>
            <v-btn
              depressed
              icon
              @click="() => {
                  previewTitleDialog2 = true;
                  itemToShow = 'pageAd';
                }
                "
              :disabled="!pageAd2Data?.pageTitle ||
                !pageAd2Data.pageContent ||
                !pageAd2Data.actionName ||
                !pageAd2Data.actionLink ||
                !pageAd2Data.actionButton ||
                !pageAd2Data.actionLink
                "
            >
              <v-icon large style="color: #463f57"> mdi-eye </v-icon>
            </v-btn>
          </div>
          <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
            Page Content
          </v-chip>
          <div style="background-color: white">
            <vue-editor
              v-model="pageAd2Data.pageContent"
              background-color="white"
              placeholder="Page Content (500 Character Limit)"
              class="mb-3"
            />
          </div>
          <div class="d-flex align-start justify-center">
            <div class="col-4">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action Name
              </v-chip>
            </div>
            <div class="col-8">
              <v-text-field
                class="mr-2"
                v-model="pageAd2Data.actionName"
                outlined
                dense
                background-color="white"
              >
              </v-text-field>
            </div>
          </div>
          <div class="d-flex gap-1 align-start justify-center">
            <div class="col-4">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action Link
              </v-chip>
            </div>
            <div class="col-8">
              <v-text-field
                class="mr-2"
                v-model="pageAd2Data.actionLink"
                outlined
                hint="Please add 'http://' in the front"
                dense
                background-color="white"
              >
              </v-text-field>
            </div>
          </div>
          <div class="d-flex gap-1 align-start justify-center">
            <div class="col-6">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action On/Off
                <v-switch
                  v-model="pageAd2Data.actionButton"
                  color="primary"
                  class="ml-3"
                ></v-switch>
              </v-chip>
            </div>
            <div class="col-6">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action Button Color
                <input
                  type="color"
                  id="head"
                  name="header button color"
                  class="ml-3"
                  v-model="pageAd2Data.actionButtonColor"
                />
              </v-chip>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="pageAd2Dialog = false" class="primary" depressed
            >Add Details</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="pageAd3Dialog" max-width="900" scrollable>
      <close-button @click="pageAd3Dialog = false" overflow />
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <div class="d-flex gap-1 align-start justify-center">
            <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
              Page Title
            </v-chip>
            <v-text-field
              class="mr-2"
              v-model="pageAd3Data.pageTitle"
              outlined
              dense
              background-color="white"
            >
            </v-text-field>
            <v-btn
              depressed
              icon
              @click="() => {
                  previewTitleDialog3 = true;
                  itemToShow = 'pageAd';
                }
                "
              :disabled="!pageAd3Data?.pageTitle ||
                !pageAd3Data.pageContent ||
                !pageAd3Data.actionName ||
                !pageAd3Data.actionLink ||
                !pageAd3Data.actionButton ||
                !pageAd3Data.actionLink
                "
            >
              <v-icon large style="color: #463f57"> mdi-eye </v-icon>
            </v-btn>
          </div>
          <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
            Page Content
          </v-chip>
          <div style="background-color: white">
            <vue-editor
              v-model="pageAd3Data.pageContent"
              background-color="white"
              placeholder="Page Content (500 Character Limit)"
              class="mb-3"
            />
          </div>
          <div class="d-flex align-start justify-center">
            <div class="col-4">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action Name
              </v-chip>
            </div>
            <div class="col-8">
              <v-text-field
                class="mr-2"
                v-model="pageAd3Data.actionName"
                outlined
                dense
                background-color="white"
              >
              </v-text-field>
            </div>
          </div>
          <div class="d-flex gap-1 align-start justify-center">
            <div class="col-4">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action Link
              </v-chip>
            </div>
            <div class="col-8">
              <v-text-field
                class="mr-2"
                v-model="pageAd3Data.actionLink"
                outlined
                hint="Please add 'http://' in the front"
                dense
                background-color="white"
              >
              </v-text-field>
            </div>
          </div>
          <div class="d-flex gap-1 align-start justify-center">
            <div class="col-6">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action On/Off
                <v-switch
                  v-model="pageAd3Data.actionButton"
                  color="primary"
                  class="ml-3"
                ></v-switch>
              </v-chip>
            </div>
            <div class="col-6">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action Button Color
                <input
                  type="color"
                  id="head"
                  name="header button color"
                  class="ml-3"
                  v-model="pageAd3Data.actionButtonColor"
                />
              </v-chip>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="pageAd3Dialog = false" class="primary" depressed
            >Add Details</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewTitleDialog" max-width="1000" scrollable>
      <close-button @click="previewTitleDialog = false" overflow />
      <v-card>
        <device picker>
          <v-carousel
            auto
            :continuous="false"
            :cycle="false"
            :show-arrows="true"
            show-arrows-on-hover
            :hide-delimiters="true"
            delimiter-icon="mdi-minus"
            :height="mdUp ? '900' : '800'"
          >
            <v-carousel-item v-if="itemToShow == 'pageTitle'">
              <v-sheet height="100%" class="pa-0 pt-3">
                <v-row justify="center" align="center">
                  <v-col cols="12" class="pb-0">
                    <v-card
                      class="px-3 custom-card"
                      :style="{ height: mdUp ? '830px' : '800px' }"
                    >
                      <v-row justify="end" align="end">
                        <v-col lg="9" xl="9" cols="7" class="text-end mr-3">
                          <div
                            class="font-weight-bold"
                            :class="mdUp ? 'text-h4' : 'text-subtitle1'"
                            :style="{
                              color: user?.brandColor ? user?.brandColor : '#97c81f',
                            }"
                          >
                            Client Name
                          </div>
                          <div class="text-caption">
                            A Recipe eBook courtesy of {{ promoData?.businessName }}
                          </div>
                        </v-col>
                        <v-col cols="4" lg="2" xl="2">
                          <v-img
                            :src="user?.customLogoUrl"
                            contain
                            height="70px"
                            width="110px"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  :color="user?.brandColor ? user.brandColor : '#97c81f'"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                      </v-row>
                      <div class="mt-3 px-8">
                        <v-divider />
                      </div>
                      <v-row justify="center" class="py-3 mt-2">
                        <div>
                          <v-img
                            v-if="mdUp"
                            :src="user?.customLogoUrl"
                            width="225"
                            height="225"
                            contain
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  :color="user?.brandColor ? user.brandColor : '#97c81f'"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                          <v-img
                            v-else
                            :src="user?.customLogoUrl"
                            width="155"
                            height="155"
                            contain
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  :color="user?.brandColor ? user.brandColor : '#97c81f'"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </div>
                      </v-row>
                      <div
                        class="py-3"
                        style="overflow: hidden auto"
                        :style="{ height: mdUp ? '385px' : '340px' }"
                      >
                        <v-row class="mt-5 px-10">
                          <v-col cols="12">
                            <div class="ql-editor" v-html="promoData?.bio"></div>
                          </v-col>
                        </v-row>
                      </div>
                      <div
                        class="bg"
                        :style="{
                          backgroundColor: user?.brandColor
                            ? user?.brandColor
                            : '#97c81f',
                        }"
                      />
                      <div class="text-caption bg-text">Copyright 2023 ©</div>
                      <div class="social-medias d-flex justify-end" style="gap: 0.5rem">
                        <a v-if="user.facebook" :href="user.facebook" target="_blank">
                          <v-icon medium color="white"> mdi-facebook </v-icon>
                        </a>
                        <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-facebook
                    </v-icon> -->
                        <a v-if="user.website" :href="user.website" target="_blank">
                          <v-icon medium color="white"> mdi-web </v-icon>
                        </a>
                        <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-web
                    </v-icon> -->
                        <a v-if="user.instagram" :href="user.instagram" target="_blank">
                          <v-icon medium color="white"> mdi-instagram </v-icon>
                        </a>
                        <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-instagram
                    </v-icon> -->
                      </div>
                      <div
                        v-if="user?.isStudent"
                        class="text-body2 red--text sample-text"
                      >
                        *This eBook was created by a student
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item v-else>
              <v-sheet height="100%" class="pa-0">
                <v-row justify="center" align="center">
                  <v-col sm="12">
                    <v-card
                      class="px-3 custom-card"
                      :style="{ height: mdUp ? '830px' : '800px' }"
                    >
                      <v-row justify="start" align="center" class="py-2">
                        <v-col cols="12" class="text-center mr-3">
                          <div
                            class="font-weight-bold text-subtitle1"
                            :style="{
                              color: user?.brandColor ? user?.brandColor : '#97c81f',
                            }"
                          >
                            {{ pageAd1Data?.pageTitle }}
                          </div>
                        </v-col>
                        <v-col cols="12" class="text-center mr-3">
                          <v-btn
                            depressed
                            rounded
                            small
                            v-show="pageAd1Data?.actionButton"
                            :style="{
                              backgroundColor: pageAd1Data?.actionButtonColor,
                              color: '#fff',
                            }"
                            >{{ pageAd1Data?.actionName }}</v-btn
                          >
                        </v-col>
                      </v-row>
                      <div class="mt-3 px-8">
                        <v-divider />
                      </div>
                      <div class="py-3" style="overflow: hidden auto; height: 600px">
                        <v-row class="px-2">
                          <v-col cols="12">
                            <div
                              class="ql-editor"
                              v-html="pageAd1Data?.pageContent"
                            ></div>
                          </v-col>
                        </v-row>
                      </div>
                      <div
                        class="bg"
                        :style="{
                          backgroundColor: user?.brandColor
                            ? user?.brandColor
                            : '#97c81f',
                        }"
                      />
                      <div class="text-caption bg-text">© 2023</div>
                      <div class="social-medias d-flex justify-end" style="gap: 0.5rem">
                        <a v-if="user.facebook" :href="user.facebook" target="_blank">
                          <v-icon medium color="white"> mdi-facebook </v-icon>
                        </a>
                        <a v-if="user.website" :href="user.website" target="_blank">
                          <v-icon medium color="white"> mdi-web </v-icon>
                        </a>
                        <a v-if="user.instagram" :href="user.instagram" target="_blank">
                          <v-icon medium color="white"> mdi-instagram </v-icon>
                        </a>
                      </div>
                      <div
                        v-if="user?.isStudent"
                        class="text-body2 red--text sample-text"
                      >
                        *This eBook was created by a student
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </device>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewTitleDialog2" max-width="1000" scrollable>
      <close-button @click="previewTitleDialog2 = false" overflow />
      <v-card>
        <device picker>
          <v-carousel
            auto
            :continuous="false"
            :cycle="false"
            :show-arrows="true"
            show-arrows-on-hover
            :hide-delimiters="true"
            delimiter-icon="mdi-minus"
            :height="mdUp ? '900' : '800'"
          >
            <v-carousel-item v-if="itemToShow == 'pageTitle'">
              <v-sheet height="100%" class="pa-0 pt-3">
                <v-row justify="center" align="center">
                  <v-col cols="12" class="pb-0">
                    <v-card
                      class="px-3 custom-card"
                      :style="{ height: mdUp ? '830px' : '800px' }"
                    >
                      <v-row justify="end" align="end">
                        <v-col lg="9" xl="9" cols="7" class="text-end mr-3">
                          <div
                            class="font-weight-bold"
                            :class="mdUp ? 'text-h4' : 'text-subtitle1'"
                            :style="{
                              color: user?.brandColor ? user?.brandColor : '#97c81f',
                            }"
                          >
                            Client Name
                          </div>
                          <div class="text-caption">
                            A Recipe eBook courtesy of {{ promoData2?.businessName }}
                          </div>
                        </v-col>
                        <v-col cols="4" lg="2" xl="2">
                          <v-img
                            :src="user?.customLogoUrl"
                            contain
                            height="70px"
                            width="110px"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  :color="user?.brandColor ? user.brandColor : '#97c81f'"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                      </v-row>
                      <div class="mt-3 px-8">
                        <v-divider />
                      </div>
                      <v-row justify="center" class="py-3 mt-2">
                        <div>
                          <v-img
                            v-if="mdUp"
                            :src="user?.customLogoUrl"
                            width="225"
                            height="225"
                            contain
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  :color="user?.brandColor ? user.brandColor : '#97c81f'"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                          <v-img
                            v-else
                            :src="user?.customLogoUrl"
                            width="155"
                            height="155"
                            contain
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  :color="user?.brandColor ? user.brandColor : '#97c81f'"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </div>
                      </v-row>
                      <div
                        class="py-3"
                        style="overflow: hidden auto"
                        :style="{ height: mdUp ? '385px' : '340px' }"
                      >
                        <v-row class="mt-5 px-10">
                          <v-col cols="12">
                            <div class="ql-editor" v-html="promoData2?.bio"></div>
                          </v-col>
                        </v-row>
                      </div>
                      <div
                        class="bg"
                        :style="{
                          backgroundColor: user?.brandColor
                            ? user?.brandColor
                            : '#97c81f',
                        }"
                      />
                      <div class="text-caption bg-text">Copyright 2023 ©</div>
                      <div class="social-medias d-flex justify-end" style="gap: 0.5rem">
                        <a v-if="user.facebook" :href="user.facebook" target="_blank">
                          <v-icon medium color="white"> mdi-facebook </v-icon>
                        </a>
                        <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-facebook
                    </v-icon> -->
                        <a v-if="user.website" :href="user.website" target="_blank">
                          <v-icon medium color="white"> mdi-web </v-icon>
                        </a>
                        <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-web
                    </v-icon> -->
                        <a v-if="user.instagram" :href="user.instagram" target="_blank">
                          <v-icon medium color="white"> mdi-instagram </v-icon>
                        </a>
                        <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-instagram
                    </v-icon> -->
                      </div>
                      <div
                        v-if="user?.isStudent"
                        class="text-body2 red--text sample-text"
                      >
                        *This eBook was created by a student
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item v-else>
              <v-sheet height="100%" class="pa-0">
                <v-row justify="center" align="center">
                  <v-col sm="12">
                    <v-card
                      class="px-3 custom-card"
                      :style="{ height: mdUp ? '830px' : '800px' }"
                    >
                      <v-row justify="start" align="center" class="py-2">
                        <v-col cols="12" class="text-center mr-3">
                          <div
                            class="font-weight-bold text-subtitle1"
                            :style="{
                              color: user?.brandColor ? user?.brandColor : '#97c81f',
                            }"
                          >
                            {{ pageAd2Data?.pageTitle }}
                          </div>
                        </v-col>
                        <v-col cols="12" class="text-center mr-3">
                          <v-btn
                            depressed
                            rounded
                            small
                            v-show="pageAd2Data?.actionButton"
                            :style="{
                              backgroundColor: pageAd2Data?.actionButtonColor,
                              color: '#fff',
                            }"
                            >{{ pageAd2Data?.actionName }}</v-btn
                          >
                        </v-col>
                      </v-row>
                      <div class="mt-3 px-8">
                        <v-divider />
                      </div>
                      <div class="py-3" style="overflow: hidden auto; height: 600px">
                        <v-row class="px-2">
                          <v-col cols="12">
                            <div
                              class="ql-editor"
                              v-html="pageAd2Data?.pageContent"
                            ></div>
                          </v-col>
                        </v-row>
                      </div>
                      <div
                        class="bg"
                        :style="{
                          backgroundColor: user?.brandColor
                            ? user?.brandColor
                            : '#97c81f',
                        }"
                      />
                      <div class="text-caption bg-text">© 2023</div>
                      <div class="social-medias d-flex justify-end" style="gap: 0.5rem">
                        <a v-if="user.facebook" :href="user.facebook" target="_blank">
                          <v-icon medium color="white"> mdi-facebook </v-icon>
                        </a>
                        <a v-if="user.website" :href="user.website" target="_blank">
                          <v-icon medium color="white"> mdi-web </v-icon>
                        </a>
                        <a v-if="user.instagram" :href="user.instagram" target="_blank">
                          <v-icon medium color="white"> mdi-instagram </v-icon>
                        </a>
                      </div>
                      <div
                        v-if="user?.isStudent"
                        class="text-body2 red--text sample-text"
                      >
                        *This eBook was created by a student
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </device>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewTitleDialog3" max-width="1000" scrollable>
      <close-button @click="previewTitleDialog3 = false" overflow />
      <v-card>
        <device picker>
          <v-carousel
            auto
            :continuous="false"
            :cycle="false"
            :show-arrows="true"
            show-arrows-on-hover
            :hide-delimiters="true"
            delimiter-icon="mdi-minus"
            :height="mdUp ? '900' : '800'"
          >
            <v-carousel-item v-if="itemToShow == 'pageTitle'">
              <v-sheet height="100%" class="pa-0 pt-3">
                <v-row justify="center" align="center">
                  <v-col cols="12" class="pb-0">
                    <v-card
                      class="px-3 custom-card"
                      :style="{ height: mdUp ? '830px' : '800px' }"
                    >
                      <v-row justify="end" align="end">
                        <v-col lg="9" xl="9" cols="7" class="text-end mr-3">
                          <div
                            class="font-weight-bold"
                            :class="mdUp ? 'text-h4' : 'text-subtitle1'"
                            :style="{
                              color: user?.brandColor ? user?.brandColor : '#97c81f',
                            }"
                          >
                            Client Name
                          </div>
                          <div class="text-caption">
                            A Recipe eBook courtesy of {{ promoData3?.businessName }}
                          </div>
                        </v-col>
                        <v-col cols="4" lg="2" xl="2">
                          <v-img
                            :src="user?.customLogoUrl"
                            contain
                            height="70px"
                            width="110px"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  :color="user?.brandColor ? user.brandColor : '#97c81f'"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                      </v-row>
                      <div class="mt-3 px-8">
                        <v-divider />
                      </div>
                      <v-row justify="center" class="py-3 mt-2">
                        <div>
                          <v-img
                            v-if="mdUp"
                            :src="user?.customLogoUrl"
                            width="225"
                            height="225"
                            contain
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  :color="user?.brandColor ? user.brandColor : '#97c81f'"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                          <v-img
                            v-else
                            :src="user?.customLogoUrl"
                            width="155"
                            height="155"
                            contain
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  :color="user?.brandColor ? user.brandColor : '#97c81f'"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </div>
                      </v-row>
                      <div
                        class="py-3"
                        style="overflow: hidden auto"
                        :style="{ height: mdUp ? '385px' : '340px' }"
                      >
                        <v-row class="mt-5 px-10">
                          <v-col cols="12">
                            <div class="ql-editor" v-html="promoData3?.bio"></div>
                          </v-col>
                        </v-row>
                      </div>
                      <div
                        class="bg"
                        :style="{
                          backgroundColor: user?.brandColor
                            ? user?.brandColor
                            : '#97c81f',
                        }"
                      />
                      <div class="text-caption bg-text">Copyright 2023 ©</div>
                      <div class="social-medias d-flex justify-end" style="gap: 0.5rem">
                        <a v-if="user.facebook" :href="user.facebook" target="_blank">
                          <v-icon medium color="white"> mdi-facebook </v-icon>
                        </a>
                        <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-facebook
                    </v-icon> -->
                        <a v-if="user.website" :href="user.website" target="_blank">
                          <v-icon medium color="white"> mdi-web </v-icon>
                        </a>
                        <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-web
                    </v-icon> -->
                        <a v-if="user.instagram" :href="user.instagram" target="_blank">
                          <v-icon medium color="white"> mdi-instagram </v-icon>
                        </a>
                        <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-instagram
                    </v-icon> -->
                      </div>
                      <div
                        v-if="user?.isStudent"
                        class="text-body2 red--text sample-text"
                      >
                        *This eBook was created by a student
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item v-else>
              <v-sheet height="100%" class="pa-0">
                <v-row justify="center" align="center">
                  <v-col sm="12">
                    <v-card
                      class="px-3 custom-card"
                      :style="{ height: mdUp ? '830px' : '800px' }"
                    >
                      <v-row justify="start" align="center" class="py-2">
                        <v-col cols="12" class="text-center mr-3">
                          <div
                            class="font-weight-bold text-subtitle1"
                            :style="{
                              color: user?.brandColor ? user?.brandColor : '#97c81f',
                            }"
                          >
                            {{ pageAd3Data?.pageTitle }}
                          </div>
                        </v-col>
                        <v-col cols="12" class="text-center mr-3">
                          <v-btn
                            depressed
                            rounded
                            small
                            v-show="pageAd3Data?.actionButton"
                            :style="{
                              backgroundColor: pageAd3Data?.actionButtonColor,
                              color: '#fff',
                            }"
                            >{{ pageAd3Data?.actionName }}</v-btn
                          >
                        </v-col>
                      </v-row>
                      <div class="mt-3 px-8">
                        <v-divider />
                      </div>
                      <div class="py-3" style="overflow: hidden auto; height: 600px">
                        <v-row class="px-2">
                          <v-col cols="12">
                            <div
                              class="ql-editor"
                              v-html="pageAd3Data?.pageContent"
                            ></div>
                          </v-col>
                        </v-row>
                      </div>
                      <div
                        class="bg"
                        :style="{
                          backgroundColor: user?.brandColor
                            ? user?.brandColor
                            : '#97c81f',
                        }"
                      />
                      <div class="text-caption bg-text">© 2023</div>
                      <div class="social-medias d-flex justify-end" style="gap: 0.5rem">
                        <a v-if="user.facebook" :href="user.facebook" target="_blank">
                          <v-icon medium color="white"> mdi-facebook </v-icon>
                        </a>
                        <a v-if="user.website" :href="user.website" target="_blank">
                          <v-icon medium color="white"> mdi-web </v-icon>
                        </a>
                        <a v-if="user.instagram" :href="user.instagram" target="_blank">
                          <v-icon medium color="white"> mdi-instagram </v-icon>
                        </a>
                      </div>
                      <div
                        v-if="user?.isStudent"
                        class="text-body2 red--text sample-text"
                      >
                        *This eBook was created by a student
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </device>
      </v-card>
    </v-dialog>

    <v-dialog v-model="videoDialog" width="1200" eager>
      <close-button @click="videoDialog = false" overflow />
      <v-card>
        <div style="padding: 56.25% 0 0 0; position: relative">
          <iframe
            src="https://player.vimeo.com/video/956833351?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
            title="MEALZEE How To.mp4"
          ></iframe>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showVideoDialog" persistent max-width="1120px">
      <close-button @click="showVideoDialog = false" overflow />
      <v-card>
        <v-card-text class="px-0 py-lg-15">
          <div
            class="d-flex flex-row flex-wrap justify-center align-center"
            style="overflow-y: scroll; max-height: 620px"
          >
            <div
              class="col-12 col-md-8 col-lg-8"
              style="position: relative; display: block; width: 100%"
            >
              <div
                class="box-shadow"
                style="
                  position: relative;
                  display: block;
                  width: 100%;
                  min-height: 320px;
                  max-height: 500px;
                  height: 100%;
                "
              >
              <video width="320" height="240" controls>
                <source :src="videoToDisplay" type="video/mp4">
                <source :src="videoToDisplay" type="video/ogg">
                Your browser does not support the video tag.
              </video>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import QrcodeVue from "qrcode.vue";
import { VueEditor } from "vue2-editor";
import { goals } from "@/options";
import Device from "vue-device";
import * as XLSX from "xlsx";

import { debounce } from "lodash";

import { nutriTagsKeywords } from "@/options"

// import VueJsonToCsv from "vue-json-to-csv";

import { mapState, mapActions } from "vuex";

export default {
  name: "PromoEbook",
  data() {
    return {
      goals,
      size: 180,
      search: "",
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Age",
          value: "age",
        },
        {
          text: "Goal",
          value: "goal",
        },
        {
          text: "BMI (kg/m2)",
          value: "bmi",
        },
        {
          text: "PAL",
          value: "pal",
        },
        {
          text: "Meal Pattern",
          value: "mealPattern",
        },
        {
          text: "Meal Tags",
          value: "mealTags",
        },
        {
          text: "Nutrition Tags",
          value: "nutritionTags",
        },
        {
          text: "Excluded Ingredients",
          value: "ingredientTags",
        },
        {
          text: "Ebook Link",
          align: "start",
          value: "link",
        },
      ],
      promoData: {
        tags: [],
      },
      promoData2: {
        tags: [],
      },
      promoData3: {
        tags: [],
      },
      file: "",
      itemToShow: "",
      pageAd1Data: {},
      pageAd2Data: {},
      pageAd3Data: {},
      submitting: false,
      videoDialog: false,
      pageAd1Dialog: false,
      pageAd2Dialog: false,
      pageAd3Dialog: false,
      previewTitleDialog: false,
      previewTitleDialog2: false,
      previewTitleDialog3: false,
      nutriTagsKeywords,
      loading: false,
      showVideoDialog: false,
      videoToDisplay: ""
    };
  },
  components: {
    Device,
    // QrcodeVue,
    VueEditor,
    // VueJsonToCsv,
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      allPlans: (state) => state.mealplans.plans,
      allFolders: (state) => state.mealplans.folders,
    }),
    generatedLink() {
      // return `${window.origin}/promoform/${this.removeSpace(
      //   this.user.firstName + this.user.lastName
      // )}?i=${this.user.id}`;
      return `https://mealguide.pro/promoform/${this.removeSpace(
        this.user.firstName + this.user.lastName
      )}?i=${this.user.id}`;
    },

    folders() {
      return this.allFolders.filter((folder) => folder?.promo);
    },

    mealplans() {
      return this.allPlans.filter((plan) => plan?.promo);
    },

    clients() {
      if (!this.folders.length) return [];

      if (!this.mealplans.length) return [];

      return this.folders.map((folder) => {
        let mealplan = this.mealplans.find((p) => p.folderId == folder?.id);
        const link = `${window.origin}/promoebookpage/${this.removeSpace(
          `${this.user.firstName}${this.user.lastName}`
        )}/${this.removeSpace(folder.client.replace(/#/gi, ""))}?i=${mealplan?.id}`;

        let mealTags = [];
        let mealPattern = [];
        let nutritionTags = [];
        let ingredientTags = [];

        if (folder?.mainMeals) mealPattern = [...folder?.mainMeals];

        if (folder?.snacks) {
          for (let s = 1; s <= folder?.snacks; s++) {
            mealPattern.push(`Snack ${s}`);
          }
        }

        if (mealplan?.mealTags)
          mealTags = mealplan.mealTags.map((t) => {
            if (this.$store.getters["tags/checkIfUnpublish"](t));
            return this.$store.getters["tags/getName"](t);
          });

        if (mealplan?.nutritionTags)
          nutritionTags = mealplan.nutritionTags.map((t) => {
            if (this.$store.getters["nutritiontags/checkIfUnpublish"](t))
              return this.$store.getters["nutritiontags/tagData"](t).name;
          });

        if (mealplan?.ingredientTags) ingredientTags = mealplan.ingredientTags;

        return {
          link,
          name: folder.client,
          email: folder.email,
          age: folder.age,
          goal: this.getGoalValue(folder.goal).text,
          bmi: Math.round(
            folder.weight / ((folder.height / 100) * (folder.height / 100))
          ),
          pal: folder.physicalActivityLevel.name,
          mealPattern,
          mealTags,
          nutritionTags,
          ingredientTags,
        };
      });
    },

    findEmail() {
      return this.allFolders.filter((f) => f.email == "csh267@gmail.com");
    },
    lgUp: function () {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    mdUp: function () {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    smDown: function () {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions("mealplans", ["getPlans", "getFolders"]),

    removeSpace(text) {
      return text.replace(/\s/g, "%20");
    },

    async submitData3 () {
      this.submitting = true;

      try {
        await Promise.resolve(
          this.$store.dispatch("user/updateUserField", {
            value: this.promoData3,
            field: "promoData3",
            silent: true,
          })
        );

        if (this.promoData3?.pageAd1)
          await Promise.resolve(
            this.$store.dispatch("user/updateUserField", {
              value: this.pageAd3Data,
              field: "pageAd3Data",
              silent: true,
            })
          );
        
        this.$store.dispatch("showSuccess", "Success! Details saved!");
      } catch (error) {
        console.log(error);
      } finally {
        this.submitting = false;
      }
    },

    async submitData2 () {
      this.submitting = true;

      try {
        await Promise.resolve(
          this.$store.dispatch("user/updateUserField", {
            value: this.promoData2,
            field: "promoData2",
            silent: true,
          })
        );
        if (this.promoData2?.pageAd1)
          await Promise.resolve(
            this.$store.dispatch("user/updateUserField", {
              value: this.pageAd2Data,
              field: "pageAd2Data",
              silent: true,
            })
          );
        this.$store.dispatch("showSuccess", "Success! Details saved!");
      } catch (error) {
        console.log(error);
      } finally {
        this.submitting = false;
      }
      
    },

    async submitData() {
      this.submitting = true;

      try {
        await Promise.resolve(
          this.$store.dispatch("user/updateUserField", {
            value: this.promoData,
            field: "promoData",
            silent: true,
          })
        );


        if (this.promoData?.pageAd1)
          await Promise.resolve(
            this.$store.dispatch("user/updateUserField", {
              value: this.pageAd1Data,
              field: "pageAd1Data",
              silent: true,
            })
          );
        this.$store.dispatch("showSuccess", "Success! Details saved!");
      } catch (error) {
        console.log(error);
      } finally {
        this.submitting = false;
      }
    },

    printQR() {
      const link = document.createElement("a");
      link.download = `qr-code.png`;
      link.href = document.getElementById("qr-code").childNodes[0].toDataURL();
      link.click();
    },
    getGoalValue(val) {
      return this.goals.find((g) => g.value == val);
    },
    goToLink(link) {
      console.log(link);
      setTimeout(() => {
        window.open(link, "_blank");
      });
    },
    copyRecipeLink(promoPage, autoGen) {
      const link = `https://mealguide.pro/promoform/${this.removeSpace(
        this.user.firstName + this.user.lastName
      )}?i=${this.user.id}&promopage=${promoPage}&autogen=${autoGen}`;

      // const link =  `${window.origin}/promoform/${this.removeSpace(
      //   this.user.firstName + this.user.lastName
      // )}?i=${this.user.id}&promopage=${promoPage}&autogen=${autoGen}`;

      navigator.clipboard.writeText(this.removeSpace(link));
      this.$store.dispatch("showSuccess", "URL copied to clipboard");
    },
    filterNutriTags(val, promo) {
      if (!val.length) return;

      let result;
      let BreakException = {};

      // let re = new RegExp("\\b" + val[val.length - 1] +  "\\b", "i")
      let re = new RegExp(val[val.length - 1], "gi");

      this.nutriTagsKeywords.forEach((tag) => {
        result = tag.keywords.some((k) => re.test(k));

        if (result) {
          if (promo == 1) {
            this.promoData.tags.pop();

            let exist = this.promoData.tags.some((t) => t == tag.name);

            if (exist) {
              this.$store.dispatch("showSuccess", "Tag is already in used.");
            } else {
              this.promoData.tags.push(tag.name);
            }
          } else if (promo == 2) {
            this.promoData2.tags.pop();

            let exist = this.promoData2.tags.some((t) => t == tag.name);

            if (exist) {
              this.$store.dispatch("showSuccess", "Tag is already in used.");
            } else {
              this.promoData2.tags.push(tag.name);
            }
          } else {
            this.promoData3.tags.pop();

            let exist = this.promoData3.tags.some((t) => t == tag.name);

            if (exist) {
              this.$store.dispatch("showSuccess", "Tag is already in used.");
            } else {
              this.promoData3.tags.push(tag.name);
            }
          }

          throw BreakException;
        }
      });

      if (!result && !this.nutriTagsKeywords.some((t) => t.name == val[val.length - 1])) {
        this.$store.dispatch("showError", "Sorry, the selected tag is not available");

        if (promo == 1)
          this.promoData.tags.pop();
        else if (promo == 2)
          this.promoData2.tags.pop();
        else
          this.promoData3.tags.pop();
      }
    },
    async formatData(promoNum) {
      return new Promise((res) => {
        this.loading = true;
        let dataItems;
        let fileReader = new FileReader()
        fileReader.readAsBinaryString(this.file);
        fileReader.onload = (event) => {
          let data = event.target.result;
          let workbook = XLSX.read(data, { type: "binary" });

          workbook.SheetNames.forEach(sheet => {
            let rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet])
            dataItems = rowObject
          })

          const items = dataItems.map(d => Object.values(d)[0])

          if (promoNum == 1) {
            this.promoData.exclusiveList = items
          } else if (promoNum == 2) {
            this.promoData2.exclusiveList = items
          } else {
            this.promoData3.exclusiveList = items
          }

          this.loading = false;
          this.$store.dispatch('showSuccess', 'Successfully converted!')
          res()
        }
      })
    },
    async convertToJson(promoNum) {
      if (this.file) {
        await this.formatData(promoNum)
        this.submitData()
      }
    },

    openVideoDialog(url) {
      this.showVideoDialog = true;

      this.videoToDisplay = url;
    },

    openLink(url) {
      setTimeout(() => {
        window.open(url, "_blank");
      })
    },

    // AUTO SAVE
    autoSave: debounce(function () {
      this.submitData();
    }, 1000),

    autoSave2: debounce(function () {
      this.submitData2();
    }, 1000),

    autoSave3: debounce(function () {
      this.submitData3();
    }, 1000),

  },

  mounted() {
    if (this.user) {
      if (this.user?.promoData) this.promoData = this.user?.promoData;

      if (this.user?.promoData2) this.promoData2 = this.user?.promoData2;

      if (this.user?.promoData3) this.promoData3 = this.user?.promoData3;

      if (this.user?.pageAd1Data) this.pageAd1Data = this.user?.pageAd1Data;
      if (this.user?.pageAd2Data) this.pageAd2Data = this.user?.pageAd2Data;
      if (this.user?.pageAd3Data) this.pageAd3Data = this.user?.pageAd3Data;
    }

    this.getPlans();
    this.getFolders();

    if (!this.$store.state.tags.status.firstLoad) this.$store.dispatch("tags/getTags");

    this.$store.dispatch("nutritiontags/getTags");
  },
};
</script>

<style scoped>
.ribbon-container {
  position: relative;
}

.ribbon-name {
  position: absolute;
  top: -25px;
  left: 0px;
  background-color: #463f57;
  color: white;
  padding: 0 1rem;
}

.ribbon-button {
  position: absolute;
  top: -33px;
  left: 17%;
  color: white;
  padding: 0 1rem;
}

.ribbon-button-sm {
  position: absolute;
  top: -33px;
  left: 50%;
  color: white;
  padding: 0 1rem;
}

#device {
  position: relative !important;
  background-color: #fff !important;
  padding: 20px;
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg {
  height: 5%;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}

.bg-text {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 10px;
  padding: 0 10px;
  color: #fff;
}

.bg-btn {
  width: 100%;
  position: absolute;
  bottom: 8px;
  left: 20%;
  padding: 0 10px;
  color: #fff;
}

.social-medias {
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 10px;
  padding: 0 10px;
  color: #fff;
}

.cursor-pointer {
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
}

/* POPOVER */

.popover__wrapper {
  position: relative;
  margin-top: .5rem;
  cursor: pointer;
}

.popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -220%;
  top: 180%;
  color: #000 !important;
  font-size: 0.8rem;
  /* transform: translate(-50%, -50%); */
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 10px 30px 0 rgba(52, 73, 94, .2);
  z-index: 9999;
  border-radius: 5px;
  min-width: 350px !important;
  max-width: 500px !important;
  white-space: wrap;
}

.popover__actions {
  text-align: center;
}

/* .popover__content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(50% - 10px);
  top: -8px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff transparent;
  transition-duration: 0.3s;
  transition-property: transform;
} */

/* .popover__content:before {
  position: absolute;
  z-index: 9998;
  content: "";
  top: -8px;
  background-color: transparent;
  padding: 10px;
  height: 110%;
  width: 110%;
} */
.popover__wrapper:hover .popover__content,
.popover__wrapper:focus .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.popover__message {
  text-align: center;
}

.custom-chip {
  margin: 5px;
  font-size: 14px;
  height: 32px;
  border-radius: 4px;
  padding: 0 5px;
  position: relative;
  outline: none;
  max-width: 100%;
  min-width: 100%;
  line-heigh: 20px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  white-space: nowrap;
}
</style>
