<template>
  <div>
    <div v-if="ratings.length">
      <div
        v-for="rating in ratings"
        :key="rating.id"
      >
        
      </div>
    </div>

    <v-card v-if="rate" outlined>
      <v-card-text>
        <v-form ref="ratingForm" @submit.prevent="validateForm()">
          <div class="d-flex">
            <div caption>Your rating</div>
            <v-spacer></v-spacer>
            <div class="text-right">
              <v-rating
                v-model="data.rating"
                :rules="[rules.required]"
                background-color="grey lighten-1"
                color="orange"
                size="50"
                small
                hover
                dense
              ></v-rating>

              <div v-if="data.id" class="caption">
                {{ data.createdAt | formatDate }}
              </div>
            </div>
          </div>

          <v-textarea
            v-model="data.comment"
            label="Comment"
            class="mt-5"
            style="font-size: 0.875rem"
            outlined
          ></v-textarea>

          <div class="d-flex justify-space-between">
            <v-btn
              @click="validateForm()"
              :loading="status.creating"
              color="primary" 
              small
            >Save</v-btn>

            <v-tooltip v-if="data.id" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="deleteRating()"
                  :loading="status.deleting"
                  color="red"
                  v-on="on"
                  small
                  icon 
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete rating</span>
            </v-tooltip>
          </div>
        </v-form>

        <v-list v-if="ratings.length">
          <v-list-item
            v-for="testimonial in ratings"
            :key="testimonial.id"
          >
            <v-list-item-avatar class="align-self-start">
              <UserPhoto :id="testimonial.user" :size="40" photoSize="thumb" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{  $store.getters['members/userData'](testimonial.user).firstName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-rating
                  :value="testimonial.rating"
                  background-color="grey lighten-1"
                  color="orange"
                  size="50"
                  readonly
                  small
                  dense
                ></v-rating>
              </v-list-item-subtitle>
              <div :style="{ lineHeight: '1.4', fontSize: '0.8rem' }">
                {{ testimonial.comment }}
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import rules from '@/rules'
import firebase from 'firebase'
import db from '@/firebase/init'

export default {
  name: 'RatingForm',

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    recipe: {
      type: Object,
      required: true,
    },
    rate: Boolean,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      ratings: [],
    }
  },
 
  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: state => state.rating.data,
      status: state => state.rating.status,
    })
  },
  
  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'recipe': function () {
      this.$store.commit('rating/setData', {})
      this.getRatings()
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('rating', [
      'addRating',
      'delete'
    ]),

    validateForm() {
      if (this.$refs.ratingForm.validate()) {
        this.addRating(this.recipe)
      }
    },

    getRatings() {
      let user = firebase.auth().currentUser

      db.collection('recipes')
      .doc(this.recipe.id)
      .collection('ratings')
      .get()
      .then(snapshot => {
        this.ratings = []
        
        if (snapshot.size) {
          snapshot.forEach(doc => {
            let rating = doc.data()
            rating.ref = doc.ref
            rating.id = doc.id
            
            this.$store.dispatch('members/getMember', rating.user)
            if (rating.user == user.uid) this.$store.commit('rating/setData', rating)
            if (!this.ratings.find(r => r.id == rating.id)) 
              this.ratings.push(rating)
          })
        }
      })
      .catch(error => {
        this.$store.dispatch('showError', error.message)
        console.log(error.message)
      })
    },

    deleteRating() {
      this.delete()
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.ratings = []
    this.getRatings()
  }
}
</script>