<template>
  <div>
    <v-card width="100%" class="mx-auto" v-if="categories">
      <v-card-title :class="$classes.cardTitle">
        <span class="font-weight-bold">Food Categories ({{ categories.length }})</span>

        <v-text-field
          v-model="search"
          placeholder="Search food categories"
          append-icon="mdi-magnify"
          style="max-width: 450px"
          hide-details
          clearable
          rounded
          dense
          solo
        ></v-text-field>

        <v-menu bottom left offset-y transition="slide-x-transition">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item dense @click="getCategories()">
              <v-list-item-icon class="mr-2"><v-icon small>mdi-refresh</v-icon></v-list-item-icon>
              <v-list-item-title>Refresh List</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-simple-table dense v-if="!status.getting && categories.length">
          <template v-slot:default>
            <tbody>
              <tr v-for="category in confCategories" :key="category.id" style="vertical-align: middle;" :class="{'active' : toEdit == category}">
                <td>{{ category.name }}</td>
                <td width="15">
                  <v-menu bottom left offset-y transition="slide-x-transition">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        small
                      >
                        <v-icon small>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item dense @click="setToEditCategory(category)" v-if="!toEdit">
                        <v-list-item-icon class="mr-2"><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
                        <v-list-item-title>Edit</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item dense @click="setDeleteCategory(category)">
                        <v-list-item-icon class="mr-2"><v-icon color="red" small>mdi-delete</v-icon></v-list-item-icon>
                        <v-list-item-title> <span class="red--text">Delete</span> </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <preloader v-if="status.getting" />

        <div class="pa-7" v-if="!status.getting && !categories.length">
          <v-alert type="info" class="mb-0">
            You don't have any food categories yet.
          </v-alert>
        </div>
      </v-card-text>
    </v-card>

    <v-card
      width="100%"
      class="mx-auto mt-5"
      flat
      color="transparent"
    >

      <v-alert v-if="status.error" text border="left" class="white" type="error">
        {{ status.error }}
      </v-alert>

      <v-row align="center">
        <v-col cols="12" md="6">
          <v-form v-if="!toEdit" ref="categoryForm" @submit.prevent="validateForm()">
            <v-text-field
              v-model="data.name"
              placeholder="Food category name"
              :rules="[rules.required]"
              solo
              hide-details
            ></v-text-field>
          </v-form>

          <v-form v-else ref="updateForm" @submit.prevent="validateUpdateForm()">
            <v-text-field
              v-model="toEdit.name"
              placeholder="New food category name"
              :rules="[rules.required]"
              solo
              hide-details
            ></v-text-field>
          </v-form>
        </v-col>
        <v-col cols="12" md="6">
          <template v-if="toEdit">
            <v-btn
              @click="validateUpdateForm()"
              :loading="status.updating"
              class="mr-2"
              color="accent"
              depressed
              large
              dark
            >Update</v-btn>
            <v-btn
              @click="cancelEdit()"
              :disabled="status.updating"
              depressed
              large
            >Cancel</v-btn>
          </template>
          <template v-else>
            <v-btn
              @click="validateForm()"
              :loading="status.adding"
              color="accent"
              depressed
              large
              dark
            >Add</v-btn>
          </template>
        </v-col>
      </v-row>
    </v-card>

    <ConfirmDelete
      :showDialog="showDelete"
      :deleting="status.deleting"
      :message="deleteMessage"
      @cancel="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import rules from '@/rules'
import _ from 'lodash'

export default {
  name: 'Categories',

  metaInfo: {
    title: 'Food Categories'
  },

  data() {
    return {
      rules,
      showDelete: false,
      deleteMessage: null,
      toDelete: null,
      toEdit: null,
      trueValue: {},
      search: null,
    }
  },

  computed: {
    ...mapState({
      categories: state => state.categories.categories,
      status: state => state.categories.status,
      data: state => state.categories.data,
    }),

    confCategories() {
      let categories = _.orderBy(this.categories, ['name'], ['asc'])

      if (this.search) {

        console.log(this.search)

        categories = categories.filter(category => {
          return category.name.toLowerCase().includes(this.search.toLowerCase())
        })
      }

      return categories
    }
  },

  methods: {
    ...mapActions('categories', [
      'getCategories',
      'addCategory',
      'deleteCategory',
      'updateCategory',
    ]),

    validateForm() {
      if (this.$refs.categoryForm.validate()) {
        this.addCategory()
      }
    },

    setDeleteCategory(category) {
      this.toDelete = category
      this.deleteMessage = `Are you sure you want to delete food category "${category.name}?"`
      this.showDelete = true
    },

    deleteConfirmed() {
      Promise.all([this.deleteCategory(this.toDelete)])
      .then(() => {
        this.showDelete = false
      })
    },

    setToEditCategory(category) {
      this.toEdit = category
      Object.assign(this.trueValue, category)
    },

    cancelEdit() {
      let category = this.confCategories.find(category => category == this.toEdit)
      this.toEdit = null
      Object.assign(category, this.trueValue)
    },

    validateUpdateForm() {
      if (this.$refs.updateForm.validate()) {
        Promise.all([this.updateCategory(this.toEdit)])
        .then(() => {
          this.toEdit = null
        })
      }
    }
  },

  created() {
    if (!this.status.firstLoad) this.getCategories()
  }
}

</script>
