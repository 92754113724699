<template>
  <div class="d-flex">
    <v-rating
      :value="rating"
      background-color="grey lighten-1"
      color="orange"
      size="50"
      readonly
      small
      dense
    ></v-rating> <span class="grey--text caption ml-2">({{ reviews }})</span>
  </div>
</template>

<script>

import _sumBy from 'lodash/sumBy'
import { mapState } from 'vuex'
import db from '@/firebase/init'

export default {
  name: 'RecipeRating',

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    recipe: {
      type: Object,
      required: true
    },
    global: Boolean,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      ratings: []
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      globalRatings: state => state.rating.ratings
    }),

    rating: function () {
      let sum = 0

      if (this.ratings.length) {
        sum = _sumBy(this.ratings, 'rating')
      }

      return sum / this.reviews
    },

    reviews: function () {
      return this.ratings.length
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'recipe': function () {
      this.resetData()
      this.getRatings()
    },

    'globalRatings': function(val) {
      if (this.global) {
        this.resetData()
  
        if (val.length) {
          this.ratings = val
        }
      }
      
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    getRatings() {
      if (this.global) {
        this.$store.dispatch('rating/getRatings', this.recipe)
      }
      else {
        db.collection('recipes')
        .doc(this.recipe.id)
        .collection('ratings')
        .get()
        .then(snapshot => {
          if (snapshot.size) {
            this.ratings = []
  
            snapshot.forEach(doc => {
              let data = this.$formatData(doc)
              this.ratings.push(data)
            })
          }
          else {
            this.resetData()
          }
        })
        .catch(error => {
          this.$store.dispatch('showError', error.message)
          console.log(error.message)
        })
      }
    },

    resetData() {
      this.ratings = []
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getRatings(this.recipe)
  }
}
</script>