<template>
  <div>
    <v-card max-width="1000" class="mx-auto box-shadow" outlined>
      <v-card-title :class="$classes.cardTitle">
        <div class="d-flex" style="gap: 0.5rem">
            <img
            :src="user?.customLogoUrl"
              height="70px"
            />
            <div>
              <div class="text-body-1">
                Personal Recipe eBook
              </div>
              <div class="text-caption">
                by <span>{{ user?.firstName }} {{ user?.lastName }}</span>
              </div>
            </div>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-overlay :value="!user">
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <div
        v-if="plans?.length <= $store.getters['user/mealPlans'] || $store.getters['user/mealPlans'] == -1"
      >
        <v-card-text v-if="user && !done" class="pa-0">
          <v-stepper class="elevation-0" v-model="step">
            <v-stepper-header>
              <v-stepper-step :complete="step > 1" step="1">Goals</v-stepper-step>
              <v-divider></v-divider>
              <!-- <v-stepper-step :complete="step > 2" step="2">Mealplan Details</v-stepper-step>
              <v-divider></v-divider> -->
              <v-stepper-step :complete="step > 2" step="2">Tags</v-stepper-step>
              <v-divider></v-divider>
            </v-stepper-header>

            <v-stepper-items>
              <!-- Primary Goal -->
              <v-stepper-content step="1">
                <v-card
                  class="mb-5 step-energy-dialog"
                  data-step="3"
                  flat
                >
                  <v-card-text>
                    <v-form ref="goalForm">
                      <v-row justify="center">
                        <v-col sm="12" md="8" lg="8" xl="8">
                          <v-text-field
                            v-model="data.email"
                            label="Client Email"
                            :rules="[rules.email, rules.required]"
                            outlined
                            type="email"
                            :loading="loading"
                            @blur="$event => { data.email && searchClient($event)}"
                            dense
                          ></v-text-field>

                          <v-text-field
                            v-model="data.client"
                            label="Full Name"
                            :loading="loading"
                            :disabled="loading"
                            outlined
                            dense
                          ></v-text-field>

                            <v-text-field
                            v-model.number="data.age"
                            :rules="[rules.required]"
                            label="Age"
                            :loading="loading"
                            :disabled="loading"
                            type="number"
                            outlined
                            dense
                          ></v-text-field>

                          <v-radio-group 
                            :rules="[rules.required]"
                            v-model="data.gender" 
                            label="Gender Assigned at Birth: " 
                            class="mt-0" 
                            dense
                            row 
                          >
                            <v-radio label="Male" value="male"></v-radio>
                            <v-radio label="Female" value="female"></v-radio>
                          </v-radio-group>

                          <v-select
                            :label="plan ? 'Primary goal' : 'What is your primary goal?'"
                            :rules="[rules.required]"
                            v-model="data.goal"
                            item-value="value"
                            item-text="text"
                            :items="goals"
                            outlined
                            dense
                          ></v-select>

                          <v-text-field
                            :label="plan ? 'Height (in cm)' : 'Your Height (in cm)'"
                            v-model.number="data.height"
                            :rules="[rules.required]"
                            type="number"
                            suffix="cm"
                            outlined
                            dense
                          ></v-text-field>

                          <v-text-field
                            :label="plan ? 'Weight (in kg)' : 'Your Weight (in kg)'"
                            v-model.number="data.weight"
                            :rules="[rules.required]"
                            type="number"
                            suffix="kg"
                            outlined
                            dense
                          ></v-text-field>

                          <v-text-field
                            :label="plan ? 'Body Mass Index (BMI) is' : 'Your Body Mass Index (BMI) is'"
                            :value="Math.round(bmi)"
                            suffix="kg/m2"
                            outlined
                            readonly
                            dense
                          ></v-text-field>

                          <!-- <v-text-field
                            label="Your Goal Weight (in kg)"
                            v-model="data.goalWeight"
                            :rules="[rules.required]"
                            v-if="!plan"
                            suffix="kg"
                            outlined
                            dense
                          ></v-text-field> -->

                          <v-select
                            :hint="data.physicalActivityLevel ? data.physicalActivityLevel.description : ''"
                            :persistent-hint="!!data.physicalActivityLevel"
                            v-model="data.physicalActivityLevel"
                            label="Physical Activity Level"
                            :rules="[rules.required]"
                            item-text="name"
                            :items="levels"
                            return-object
                            outlined
                            dense
                          ></v-select>
                        </v-col>

                        <!-- <v-col cols="4">
                          <v-card class="mb-3"
                            :style="{
                              backgroundColor: user?.brandColor
                                ? user?.brandColor
                                : '#97c81f',
                            }"
                          >
                            <v-card-text class="white--text">
                              <div class="font-weight-bold mb-3">
                                BMI Categories:
                              </div>
                              Underweight =  &lt; 18.5 <br>
                              Normal weight = 18.5 - 24.9 <br>
                              Overweight = 25 - 29.9 <br>
                              Obesity = BMI of 30 or greater <br>
                            </v-card-text>
                          </v-card>

                          <v-card class="d-none" color="success">
                            <v-card-text class="white--text">
                              <div class="font-weight-bold mb-3">
                                Ideal Body Weight
                              </div>
                              <div class="display-1 font-weight-bold">
                                {{ Math.round(idealWeight) }}kg
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col> -->
                      </v-row>
                    </v-form>

                    <v-row justify="end">
                      <v-btn
                        @click="goToPageTwo()"
                        depressed
                        class="white--text"
                        :style="{
                          backgroundColor: user?.brandColor
                            ? user?.brandColor
                            : '#97c81f',
                        }"
                      >
                        Continue
                      </v-btn>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-stepper-content>

              <!-- TAGS -->
              <v-stepper-content step="2">
                <p class="mb-3 grey--text text--darken-1">Please select any meal tags that suit your lifestyle?</p>
                <v-sheet max-width="590" color="transparent">
                  <v-select
                    :items="mealTags"
                    v-model="customModel.mealTags"
                    placeholder="Select meal tags"
                    class="meal-tags align-start"
                    v-if="mealTags.length"
                    item-text="name"
                    item-value="id"
                    deletable-chips
                    height="50"
                    width="100%"
                    small-chips
                    multiple
                    outlined
                    chips
                    dense
                  ></v-select>
                </v-sheet>
                <p class="mb-3 grey--text text--darken-1">Please select any nutrition tags or diet requirements you have? (use this feature to exclude food groups)</p>
                <v-sheet max-width="590" color="transparent">
                  <v-select
                    :items="nutritionTags"
                    v-model="customModel.nutritionTags"
                    placeholder="Select nutrition tags"
                    class="meal-tags align-start"
                    v-if="nutritionTags.length"
                    item-text="name"
                    item-value="id"
                    deletable-chips
                    height="50"
                    width="100%"
                    small-chips
                    multiple
                    outlined
                    chips
                    dense
                  ></v-select>
                </v-sheet>
                <p class="mb-3 grey--text text--darken-1">Please list (by pressing Enter key) any food allergies, intolerances or dislikes. These foods will be excluded from your meal plan. (use this box to exclude individual ingredients,)</p>
                <v-sheet max-width="590" color="transparent">
                  <!-- <v-autocomplete
                    placeholder="Type the ingredients you would like to exclude then press enter"
                    v-model="customModel.ingredientTags"
                    class="meal-tags align-start"
                    :items="foods"
                    deletable-chips
                    width="100%"
                    small-chips
                    multiple
                    outlined
                    chips
                  ></v-autocomplete> -->
                  <v-combobox
                    placeholder="Type the ingredients you would like to exclude then press enter"
                    v-model="customModel.ingredientTags"
                    class="meal-tags align-start"
                    deletable-chips
                    @change="$event => filterIngredientTags($event)"
                    width="100%"
                    small-chips
                    multiple
                    outlined
                    chips
                    dense
                  ></v-combobox>
                </v-sheet>

                <v-row justify="end" class="pa-3">
                  <v-btn
                    @click="step = 1"
                    :disable="adding"
                    text
                    class="mr-2"
                  >Back</v-btn>
                  <v-btn
                    :loading="adding"
                    @click="validateForm()"
                    depressed
                    class="white--text mr-2"
                    :style="{
                      backgroundColor: user?.brandColor
                        ? user?.brandColor
                        : '#97c81f',
                    }"
                  >
                  Submit
                  </v-btn>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-text v-if="done">
          <v-alert
            text
            type="success"
          >
          Success! Your form has been submitted to <strong>{{ user.firstName }} {{ user.lastName }}</strong>. Thank you!
          </v-alert>
          <div class="d-flex justify-center align-center flex-wrap" v-if="user?.allowGenerate">
            <div class="col-12 pa-0 text-center">
                <div class="text-subtitle1">Generating your eBook. Please wait..</div>
            </div>
            <div class="col-12 text-center">
                <v-progress-circular indeterminate size="34"></v-progress-circular>
            </div>
          </div>
        </v-card-text>
      </div>
      <v-card-text 
        v-else
      >
        <v-alert
          text
          type="error"
        >
          This {{ determineRole(user.role) }} has reach his/her meal plans limits
        </v-alert>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import rules from '@/rules'
import { mapState, mapGetters, mapActions } from 'vuex'
import { goals, frequencies, levels, patterns, stress } from '@/options'
import { startCase } from 'lodash'

export default {
  name: 'Form',

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    plan: Object,
    add: {
      type: Boolean,
      default: true
    },
    editNewMealplan: Boolean
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      goals,
      rules,
      levels,
      stress,
      step: 1,
      patterns,
      startCase,
      done:false,
      frequencies,
      adding: false,
      loading: false,
      customModel: {},
      currentClient: null,
      existingClient: false
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: state => state.goal.data,
      goal: state => state.goal.goal,
      user: state => state.user.user,
      status: state => state.goal.status,
      foods: state => state.foods.foodNames,
      plans: state => state.mealplans.plans,
      mealplanData: state => state.mealplans.data,
      mealplanStatus: state => state.mealplans.status,
      currentFolder: state => state.mealplans.currentFolder,
    }),

    nutritionTags: function () {
      return this.$store.getters['nutritiontags/tags']
    },

    mealTags: function () {
      return this.$store.getters['tags/tags']
    },

    ...mapGetters('goal', [
      'customDailyEnergyRequirementLose',
      'dailyEnergyRequirementLose',
      'dailyEnergyRequirementCal',
      'dailyEnergyRequirement',
      'weightLossEnergyTarget',
      'energyRequirementsLose',
      'mainMealsEnergy',
      'energyTarget',
      'snacksEnergy',
      'idealWeight',
      'adjusted',
      'eermwCal',
      'eerlwCal',
      'eermwKj',
      'eerlwKj',
      'sctbg',
      'sktbg',
      'bmi',
    ]),

    dailyEnergyRequirementComp: function () {
      return this.dailyEnergyRequirement
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'goal': function (value) {
      if (value) {
        this.$store.commit('goal/setData', value)
      }
    },
    
    // 'data': function (value) {
    //   if (value) {
    //     console.log(value)
    //     this.$store.commit('goal/setData', value)
    //   }
    // },

    'sktbg': {
      handler(value) {
        if (value < 5000) this.data.customTarget = 5000
        else this.data.customTarget = 0
      },
      immediate: true
    },

    'data.customTarget': function (val) {
      if (val) this.data.customTarget = Math.round(val)
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('goal', [
      'deleteCustomTarget',
      'getGoal',
      'create',
      'update',
    ]),

    ...mapActions('mealplans', [
      'autoGenerateFromForm',
      'updateFolderFromForm',
      'addPlanToFolderForm',
      'addFolderFromForm',
      'getPlansFromForm',
      'searchFolder',
    ]),

    ...mapActions('user', [
        'getUserInfo'
    ]),

    ...mapActions('foods', [
      'getFoodsForForm'
    ]),

    goToPageTwo() {
      if(this.existingClient)
        this.$store.commit('goal/setAddNewMealplan', { mainMeals: [], snacks: 0, customTarget: 0 })

      if (this.$refs.goalForm.validate()) {
        this.step = 2
      }
    },

    goToPageThree() {
      if (this.$refs.parameterForm.validate()) {
        this.step = 3
      }
    },

    gotoPageFour() {
      if (this.$refs.clinicalForm.validate()) {
        this.step = 4
      }
    },

    validateForm() {
      this.adding = true
      let task

      this.data.mainMeals = ["breakfast","lunch","dinner"]
      this.data.snacks = 2

      if(this.existingClient) 
        task = Promise.resolve(this.updateFolderFromForm({ id: this.currentClient.id, ref: this.currentClient.ref, ...this.data }))
      else
        task = Promise.resolve(this.addFolderFromForm({ user: this.user, ...this.data }))

      task.then(({ newData: taskData }) => {
        let mealplan, name

        let goalData = this.existingClient ? this.data : taskData
        const details = {
          user: {
            id: this.user.id,
            role: this.user.role,
            lastName: this.user.lastName,
            firstName: this.user.firstName,
            brandColor: this.user.brandColor,
            energyUnit: this.user.energyUnit,
            isStudent: this.user?.isStudent ? true : false,
            customLogoUrl: this.user.customLogo ? this.user.customLogoUrl : "",
            promoData: this.user.promoData || {},
            pageAd1Data: this.user.pageAd1Data || {},
          },
          recipes: [],
          mainMeals: [],
          meals: [],
          goal: goalData,
        };

        if(this.existingClient) {
          name = `${this.data.client} ${this.data.mealplans.length + 1}`
          mealplan = Promise.resolve(this.$store.dispatch('mealplans/createFromForm', { id: this.user.id, folderId: this.currentClient.id, name, recipeplan: true, linkDetails: details }))
        }
        else {
          name = `${this.data.client} 1`

          mealplan = Promise.resolve(this.$store.dispatch('mealplans/createFromForm', { id: this.user.id, folderId: taskData.id, name, recipeplan: true, linkDetails: details }))
        }

        /**
         * TODO: Find a way to optimize this
        */
        mealplan.then(({ error, newData, data }) => {
          if(!error) {
            setTimeout(() => {
              Promise.resolve(this.$store.dispatch('goal/createFromForm', { id: newData.id, user: this.user}))
              .then(() => {

                this.$store.dispatch('saveDocument', {...newData, ...this.customModel})

                setTimeout(() => {
                  if(this.existingClient) {
                    this.addPlanToFolderForm({ id: newData.id, folder: this.currentClient })

                  }
                  else {
                    this.addPlanToFolderForm({ id: newData.id, folder: taskData })
                  }

                  setTimeout(() => {
                    Promise.resolve(this.autoGenerateFromForm({ plan: {...newData, ...data, ...this.customModel }, roleType: 'all', user: this.user })).then(() => {
                      setTimeout(() => {
                        let emailData = {
                          recipient: this.user.email,
                          subject: 'Meal Plan Submission',
                          message: `Hi ${this.user.firstName} ${this.user.lastName}, <br/> <br/> ${this.data.client} is requesting a new meal plan via a new submission of the Meal Plan Questionnaire. <br/><br/>
                          If they are a new client, a new folder would have been created with their meal plan within that folder. <br/><br/>
                          If they are an existing client, and the email address they submitted matches the one in your profile, then a new meal plan will have been added to their existing folder. <br/><br/>
                          If they are an existing client however, no folder will exist for them due to being added to the system before the creation of the folder system. Instead, you will find their meal plan in the 'Others' folder. <br/> <br/>
                          Feel free to reach, email us at <a href="mailto:hello@mealzee.com" style="text-decoration: underline">hello@mealzee.com</a> if you have any question. <br/> <br/>
                          Regards<br/> <br/>

                          Chris Hughes - Dietitian & Founder of MEALZEE
                          `
                        }


                        const link = `https://mealguide.pro/ebook/${this.removeSpace(
                          `${this.user.firstName}${this.user.lastName}`
                        )}/${this.removeSpace(data.name.replace(/#/gi, ""))}?i=${
                          newData.id
                        }`;

                        // const link = `${
                        //   window.origin
                        // }/ebook/${this.removeSpace(
                        //   `${this.user.firstName}${this.user.lastName}`
                        // )}/${this.removeSpace(data.name.replace(/#/gi, ""))}?i=${
                        //   newData.id
                        // }`;

                        this.$store.dispatch('mail/notify', emailData)
                        this.adding = false
                        this.done = true
                        this.$store.dispatch('mealplans/updateMealField', {
                          value: true,
                          field: 'mealsLoaded',
                          meal: newData,
                          silent: true
                        })

                        if(this.user?.allowGenerate) {
                          setTimeout(() => {
                              window.location.href = link
                          }, 4500)
                        }
                      }, 200)
                    })
                  }, 400)
                }, 800)

              })
            }, 800)
          }
          else {
            console.log(error)
          }
        }).catch((e) => {
          console.log(e)
        })
      }).catch(e => {
        console.log(e)
      })
      
    },

    setGoal () {
      if(this.editNewMealplan)
        this.getGoal(this.plan ? this.plan.id : null)
    },

    searchClient(e) {
      this.loading = true
      Promise.resolve(this.searchFolder(e.target.value))
      .then(res => {

        if(res) {
          this.$store.dispatch('showSuccess', 'Client info found')
          this.$store.commit('goal/setGoalData', res)
          this.existingClient = true
          this.currentClient = res

        } else {
          if(this.data.client) {
            this.$store.commit('goal/setGoalData', { mainMeals: [] })
            this.currentClient = null
          }

          this.existingClient = false
        }
      })
      .finally(() => {
        this.loading = false
      })
    },

    determineRole (role) {
      return role === 'personal_trainer' ? 'Health & Fitness Professional' : startCase(role)
    },

    filterIngredientTags (val) {
      let result

      let re = new RegExp(val[val.length - 1], 'gi')
      result = this.foods.some(food => re.test(food))

      if(!result) {
        this.$store.dispatch('showError', 'Sorry, selected ingredient is not found in our database')

        this.customModel.ingredientTags.pop()
      }
    },

    removeSpace(text) {
      return text.replace(/\s/g, "%20");
    },

  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    Promise.resolve(this.getUserInfo(this.$route.query.i)).then(() => {
      this.getPlansFromForm(this.user)
    })
    if (!this.$store.state.tags.status.firstLoad) {
      this.$store.dispatch('tags/getTags')
    }
    this.$store.dispatch('nutritiontags/getTags')
    this.getFoodsForForm(this.user)
  }
}

</script>