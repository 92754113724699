<template>
  <v-container fluid>
    <v-alert v-if="user.status == 'pending'" type="warning" border="left" text>
      Your account is currently pending for approval.
    </v-alert>

    <template v-if="user.status == 'active'">
      <v-card class="mb-5 box-shadow" outlined>
        <v-card-title :class="$classes.cardTitle">
          <div class="d-flex flex-column flex-md-row flex-lg-row flex-xl-row justify-space-between mb-3" style="width:100%">
            <span class="font-weight-bold">Foods</span>
            <div>
              <v-btn
                @click="addNew()"
                color="primary"
                class="mr-2 step-add-new"
                depressed
                small
                data-step="1"
              >Add New</v-btn>
              
              <v-btn
                v-if="user.role == 'admin'"
                @click="showCategories()"
                depressed
                small
                dark
                class="mr-2"
              >Categories</v-btn>
              <v-btn
                @click="startIntro()"
                x-small
                fab
                depressed
                color="info button-help"
              >
                <v-icon dark>
                  mdi-help-circle-outline
                </v-icon>
              </v-btn>
            </div>
          </div>
          <div class="d-flex flex-column align-end flex-md-row flex-lg-row align-md-center align-lg-center justify-lg-space-between" style="width:100%; gap: 0.5rem">
            <v-text-field
              v-model="search"
              @input="startSearch()"
              @click:clear="
                page = 0
                results = []
              "
              class="font-weight-normal white"
              placeholder="Search by name"
              :readonly="!!results.length"
              style="max-width: 1000px;width: 100%"
              hide-details
              clearable
              outlined
              rounded
              dense
            >
              <template #append>
                <v-btn
                  :loading="searching"
                  small
                  icon 
                >
                  <v-icon>mdi-text-box-search-outline</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </div>

        </v-card-title>

        <div class="d-flex align-center px-5 pt-5 flex-wrap" :style="{ width: '100%', gap: '8px' }">
          <v-btn
            @click="view = 'published'"
            :color="view == 'published' ? 'primary' : ''"
            class="text-none"
            outlined
            small 
          >Published</v-btn>
            
          <v-btn
            @click="view = 'pending'"
            :color="view == 'pending' ? 'primary' : ''"
            class="text-none step-pending"
            outlined
            small 
            data-step="3"
          >
            <span>Pending</span>
            
            <v-icon 
              v-if="pendingFoods.length"
              color="error" 
              small right
            >mdi-alert-circle-outline</v-icon>
          </v-btn>
          
          <v-btn
            @click="view = 'hidden'"
            :color="view == 'hidden' ? 'primary' : ''"
            class="text-none"
            outlined
            small 
            v-if="user.role == 'admin'"
          >
            <span>Hidden</span>
            <v-icon
              v-if="hiddenFoods.length" 
              color="error" 
              small right
            >mdi-alert-circle-outline</v-icon>
          </v-btn>
          
          <v-btn
            v-if="user && user.role == 'admin'"
            @click="view = 'reports'"
            :color="view == 'reports' ? 'primary' : ''"
            class="text-none"
            outlined
            small 
          >
            <span>Reports</span>
            
            <v-icon
              v-if="reports.length" 
              color="error" 
              small right
            >mdi-alert-circle-outline</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-chip 
            v-if="($cookies.get('bookmark') && !removed) || bookmarked" 
            @click:close="removeBookmark()"
            close
            small
          >Bookmarked: {{ $cookies.get('bookmark') || bookmarked }}</v-chip>
        </div>

        <preloader v-if="status.getting && !status.firstLoad" />

        <v-card-text v-if="!status.getting && !confFoods.length">
          <v-alert
            border="left"
            type="info"
            text
          >No food found.</v-alert>
        </v-card-text>

        <v-simple-table v-if="confFoods.length">
          <template #default>
            <thead>
              <tr>
                <th>
                  <div class="d-flex align-center">
                    <v-checkbox
                      @change="selectAll"
                      class="ma-0"
                      hide-details
                      dense
                    ></v-checkbox>

                    <span>Name</span>
                  </div>
                </th>
                <th>Tags</th>
                <th>ID</th>
                <th>Category</th>
                <th v-if="user.role == 'admin'">Exclude from shopping list</th>
                <th v-if="user.role == 'admin'">Alternate words</th>
                <template v-if="view == 'reports'">
                  <th>Issue/s</th>
                </template>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="food in confFoods" :key="food.id">
                <td>
                  <div class="d-flex align-center">
                    <v-checkbox
                      v-model="selected"
                      class="ma-0 pa-0"
                      :value="food.id"
                      hide-details
                      dense
                    ></v-checkbox>

                    <div 
                      @blur="titleUpdated($event.target.textContent, food)"
                      :style="{ fontSize: '0.8rem' }" 
                      contenteditable="true"
                      spellcheck="false"
                    >{{ food.name }}</div>
                  </div>
                </td>
                <td>
                  <v-btn 
                    color="primary" 
                    :disabled="!hasTags(food)"
                    depressed 
                    small 
                    text
                    @click="showFoodTagDialog(food)"
                  >
                  View
                </v-btn>
                </td>
                <td class="caption grey--text">
                  {{ food.id }}

                  <v-btn 
                    @click="$copyText(food.id).then(() => $store.dispatch('showSuccess', 'Copied to clipboard'))"
                    class="ml-1" 
                    x-small 
                    icon
                  >
                    <v-icon color="grey" x-small>mdi-content-copy</v-icon>
                  </v-btn>
                </td>
                <td class="caption grey--text text--darken-1">{{ $store.getters['foods/categoryData'](food.category).name  }}</td>
                <td class="text-center" v-if="user.role == 'admin'">
                  <v-switch 
                    v-model="food.hideShoppingList"
                    @change="
                      $store.dispatch('foods/updateFoodField', {
                        food,
                        field: 'hideShoppingList',
                        value: food.hideShoppingList,
                      })
                    "
                    class="ma-0 mt-3 pa-0" 
                    dense
                  ></v-switch>
                </td>
                <td v-if="view == 'pending' && user.role == 'admin'" class="text-center">
                  <v-btn 
                    @click="approveFood(food)" 
                    :loading="!!status.approving.find(a => a.id == food.id)"
                    color="primary" 
                    outlined 
                    x-small
                  >Approve</v-btn>
                </td>
                <td>
                  <v-btn
                    v-if="user.role == 'admin'"
                    @click="showAddAlternateWordsDialog(food)"
                    color="info"
                    x-small
                    text
                  >
                    Open
                  </v-btn>
                </td>
                <template v-if="view == 'reports'">
                  <td width="50%">
                    <template v-if="$store.getters['foods/reports'](food.id)">
                      <div
                        v-for="report in $store.getters['foods/reports'](food.id)"
                        :key="report.id"
                        class="mb-1"
                      >
                        <em>{{ report.message }}</em>
                        <div class="caption grey--text">{{ $store.getters['members/userData'](report.user).firstName }} {{ $store.getters['members/userData'](report.user).lastName }} ({{ report.created.toDate() | fromNow }})</div>
                        <v-btn 
                          @click="markReportAsResolved(report)" 
                          :loading="status.resolving == report.id"
                          outlined 
                          x-small
                        >Mark as resolved</v-btn>
                      </div>
                    </template>
                  </td>
                </template>
                <td width="15" v-if="user.role == 'admin'">
                  <v-menu offset-y left>
                    <template #activator="{ on }">
                      <v-btn 
                        v-on="on"
                        small
                        icon
                      >
                        <v-icon small>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item @click="showFooDetails(food)">
                        <v-list-item-title>Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="$refs.recipesDialog.showDialog(food)">
                        <v-list-item-title>Recipes</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="showFooPortions(food)">
                        <v-list-item-title>Portions</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="bookmark(food.id)">
                        <v-list-item-title>Bookmark</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="food.status == 'published'" @click="hideFood(food)">
                        <v-list-item-title>Hide Food</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="food.status == 'hidden'" @click="publishFood(food)">
                        <v-list-item-title>Publish Food</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="showFoodColumnDialog(food)">
                        <v-list-item-title>Add Column</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item @click="confirmDelete(food)">
                        <v-list-item-title class="error--text">Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>

      <div class="d-flex align-center gap-10">
        <v-btn
          v-if="foods.length && ['published', 'pending'].includes(view)"
          @click="getFoods()"
          :loading="status.getting"
          color="primary"
          depressed
        >Load More</v-btn>
        
        <v-btn
          v-if="hiddenFoods.length && view == 'hidden'"
          @click="getHiddenFoods()"
          :loading="status.gettingHiddenFoods"
          color="primary"
          depressed
        >Load More</v-btn>
        
        <v-btn
          v-else
          @click="
            page++
            startSearch()
          "
          :loading="searching"
          color="primary"
          depressed
        >Load More</v-btn>

        <span class="caption">Showing {{ confFoods.length }} foods</span>
      </div>
    </template>

    <!-- FOOD CATEGORIES -->
    <food-categories ref="foodCategories" />

    <!-- FOOD DETAILS DIALOG -->
    <food-details ref="foodDetails" :food="food" :user="user" />

    <!-- FOOD PORTIONS -->
    <food-portions ref="foodPortions" :food="food" />

    <!-- FOOD TAGS -->
    <food-tags ref="foodTags" :food="food" :user="user" />

    <!-- FOOD COLUMNS -->
    <food-column ref="foodColumn" :food="food" />

    <!-- CONFIRM DELETE -->
    <confirm-delete
      :showDialog="showDelete"
      :message="deleteMessage"
      :deleting="status.deleting"
      @confirmed="deleteConfirmed()"
      @cancel="closeDeleteDialog()"
    />

    <!-- SNACKBAR -->
    <v-snackbar
      :style="{ zIndex: '2 !important' }"
      :value="!!selected.length"
      max-width="750"
      timeout="-1"
      width="100%"
      light
      top
      app
    >
      <div class="d-flex justify-space-between align-center gap-10">
        <v-select
          :items="categories"
          v-model="category"
          :multiple="false"
          item-text="name"
          label="Category"
          item-value="id"
          hide-details
          outlined
          dense
        ></v-select>

        <v-btn 
          @click.prevent="updateCategories()"
          :loading="status.updating"
          :disabled="!category"
          color="primary" 
          depressed
          height="38"
          small
        >Confirm</v-btn>

        <v-btn v-if="view == 'published'" @click.prevent="bulkHideFoods()" :loading="hiding" color="primary" height="38" depressed small>Hide Foods</v-btn>
        <v-btn v-if="view == 'hidden'" @click.prevent="bulkPublishFoods()" :loading="hiding" color="primary" height="38" depressed small>Publish Foods</v-btn>

        <v-divider class="mx-3" vertical></v-divider>

        <v-btn
          @click="bulkDeleteDialog = true"
          :loading="status.deleting"
          color="error" 
          height="30" 
          width="30" 
          depressed
          small 
          plan 
          fab 
        >
          <v-icon small>mdi-delete</v-icon>
        </v-btn>

      </div>
    </v-snackbar>

    <!-- DELETE FOODS DIALOG -->
    <confirm-delete
      :showDialog="bulkDeleteDialog"
      message="Bulk delete selected foods?"
      :deleting="status.deleting"
      @cancel="bulkDeleteDialog = false"
      @confirmed="bulkDelete()"
    />

    <!-- RECIPES DIALOG -->
    <recipes-dialog ref="recipesDialog" />

    <!-- ADD ALTERNATE WORDS DIALOG  -->
    <v-dialog max-width="450" v-model="addAlternateDialog">
      <close-button @click="addAlternateDialog = false" overflow />
      <v-card>
        <v-card-title class="caption">
          Add Alternate Words for "{{food.name}} "
        </v-card-title>
        <v-card-text>
          <v-combobox
            v-model="food.alternateWords"
            label="Alternate Words"
            multiple
            width="100%"
            class="align-start"
            chips
            small-chips
            deletable-chips
            outlined
            dense
            height="150"
            hint="Type the alternate words then press enter"
          ></v-combobox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="addAlternateWords()"
            :loading="addingAlternateWords"
            small
            color="primary"
            depressed
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import db from '@/firebase/init'
import algoliasearch from 'algoliasearch'
import { debounce } from 'lodash'
import { mapState, mapActions } from 'vuex'

import FoodDetails from './components/FoodDetails.vue'
import FoodPortions from './components/FoodPortions.vue'
import FoodColumn from './components/FoodColumn.vue'
import RecipesDialog from './components/RecipesDialog.vue'
import FoodCategories from './components/FoodCategories.vue'
import FoodTags from './components/FoodTags.vue'

const client = algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID, 'b01a34dc50c42c2e6fe90f9ec6d376ad')
const index = client.initIndex('foods')

import IntroJs from 'intro.js'

export default {
  name: 'Foods',
  metaInfo: {
    title: 'Foods'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      page: 0,
      food: {},
      results: [],
      selected: [],
      search: null,
      hiding: false,
      noResult: null,
      toDelete: null,
      category: null,
      removed: false,
      searching: false,
      bookmarked: null,
      showDelete: false,
      view: 'published',
      deleteMessage: null,
      bulkDeleteDialog: false,
      addAlternateDialog: false,
      addingAlternateWords: false
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      foods: state => state.foods.foods,
      status: state => state.foods.status,
      reports: state => state.foods.reports,
      categories: state => state.foods.categories,
      hiddenFoods: state => state.foods.hiddenFoods,
      pendingFoods: state => state.foods.pendingFoods,
    }),

    confFoods: function () {
      if (this.results.length) {
        return this.results.filter(result => {
          return result.status == this.view
        })
      }
      else {
        if (this.view == 'reports') {
          return this.foods.filter(food => {
            return this.reports.find(r => r.food == food.id)
          })
        }
        else if (this.view == 'hidden') return this.hiddenFoods
        else return this.view == 'published' ? this.foods : this.pendingFoods
      }
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    FoodDetails,
    FoodPortions,
    RecipesDialog,
    FoodCategories,
    FoodColumn,
    FoodTags
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('foods', [
      'bulkUpdateCategories',
      'markReportAsResolved',
      'getReportedFoods',
      'getPendingFoods',
      'bulkDeleteFoods',
      'updateFoodField',
      'getHiddenFoods',
      'getCategories',
      'approveFood',
      'deleteFood',
      'getFoods',
    ]),

    showFooDetails(food) {
      this.food =  food
      this.$refs.foodDetails.dialog = true
    },

    showAddAlternateWordsDialog(food) {
      this.food = food
      this.addAlternateDialog = true
    },

    async addAlternateWords() {
      let food = this.food
      this.addingAlternateWords = true
      try {
        await Promise.resolve(
          this.$store.dispatch('foods/updateFoodField', {
            food,
            field: 'alternateWords',
            value: food.alternateWords,
            silent: true,
          })
        )
        this.$store.dispatch("showSuccess", "Success! Atternate words saved!");
        this.addAlternateDialog = false
      } catch (error) {
        console.log(error)
      } finally {
        this.addingAlternateWords = false
      }
    },

    addNew() {
      let food = {
        name: null,
        carbohydrate: {
          name: 'Carbohydrate',
          unit: 'g',
          val: '0'
        },
        energyKj: {
          name: 'Energy',
          unit: 'kJ',
          val: '0'
        },
        fat: {
          name: 'Fat',
          unit: 'g',
          val: '0'
        },
        fibre: {
          name: 'Fibre',
          unit: 'g',
          val: '0'
        },
        protein: {
          name: 'Protein',
          unit: 'g',
          val: '0'
        },
        sodium: {
          name: 'Sodium (Na)',
          unit: 'mg',
          val: '0'
        },
        sugars: {
          name: 'Sugars',
          unit: 'g',
          val: '0'
        },
      }

      this.showFooDetails(food)
    },
    
    showFooPortions(food) {
      this.food =  food
      this.$refs.foodPortions.dialog = true
    },

     /*------------------------------------------------------------------------------
     * START SEARCH
     *----------------------------------------------------------------------------*/
    startSearch: debounce(function () {
      this.searching = true
      this.noResult = false
      
      if (this.search && this.search.length > 2) {
        index.search(`${this.search} -by`, { page: this.page })
        .then(({ hits }) => {
          if (!hits.length) {
            this.$store.dispatch('showError', 'Food not found')
            this.searching = false
          }
          else {
            let promises = []

            for (let hit of hits) {
              promises.push(db.collection('foods').doc(hit.objectID).get())
            }

            Promise.all(promises)
            .then(docs => {
              docs.forEach(doc => {
                if (doc.exists) {
                  let food = doc.data()
                  food.id = doc.id
                  food.ref = doc.ref
                  if (!this.results.find(r => r.id == food.id))
                    this.results.push(food)
                }
                
                this.searching = false
              })
            })

          }
        })
        .catch(error => {
          console.log(error)
          this.noResult = false
          this.searching = false
        })
      }
      else {
        this.searching = false
      }
    }, 500),

    /*------------------------------------------------------------------------------
     * CONFIRM DELETE FOOD
     *----------------------------------------------------------------------------*/
    confirmDelete(food) {
      this.toDelete = food
      this.deleteMessage = `Delete food ${food.name}?`
      this.showDelete = true
    },

    /*------------------------------------------------------------------------------
     * CLOSE DELETE DIALOG
     *----------------------------------------------------------------------------*/
    closeDeleteDialog() {
      this.toDelete = null
      this.deleteMessage = null
      this.showDelete = false
    },

    deleteConfirmed() {
      Promise.resolve(
        this.deleteFood(this.toDelete)
      )
      .then(() => {
        this.closeDeleteDialog()
        
        let food = this.results.find(f => f.id == this.toDelete.id)
        if (food) this.results.splice(this.results.indexOf(food), 1)
      })
    },

    showCategories() {
      this.$refs.foodCategories.drawer = true
    },

    updateCategories() {
      Promise.resolve(
        this.bulkUpdateCategories({ foods: this.selected, category: this.category })
      )
      .then(() => { this.selected = [] })
    },

    bulkDelete() {
      Promise.resolve(this.bulkDeleteFoods(this.selected))
      .then(() => {
        this.bulkDeleteDialog = false
        this.selected = []
      })
    },

    selectAll(value) {
      if (value) {
        this.confFoods.forEach(doc => {
          this.selected.push(doc.id)
        })
      }
      else {
        this.selected = []
      }
    },

    titleUpdated(text, food) {
      if (text !== food.name) {
        this.updateFoodField({
          food,
          field: 'name',
          value: text,
          message: 'Food name updated',
        })
      }
    },

    bookmark(id) {
      this.$cookies.set('bookmark', id)
      this.$store.dispatch('showSuccess', 'Bookmarked')
      this.bookmarked = true
    },

    removeBookmark() {
      this.$cookies.remove('bookmark')
      this.$store.dispatch('showSuccess', 'Bookmark removed')
      this.removed = true
      this.bookmarked = null
    },

    hideFood(food) {
      Promise.resolve(
        this.$store.dispatch('foods/updateFoodField', {
          food,
          field: 'status',
          value: 'hidden',
        })
      )
      .then(() => {
        this.$store.commit('foods/addToHiddenFoods', food)
        this.$store.commit('foods/removeFood', food)
      })
    },
    
    publishFood(food) {
      Promise.resolve(
        this.$store.dispatch('foods/updateFoodField', {
          food,
          field: 'status',
          value: 'published',
        })
      )
      .then(() => {
        this.$store.commit('foods/addFood', food)
        this.$store.commit('foods/removeHiddenFood', food)
      })
    },

    bulkHideFoods() {
      this.hiding = true
      let promises = []
      
      this.selected.forEach(id => {
        let food = this.foods.find(f => f.id == id)

        promises.push(
          this.$store.dispatch('foods/updateFoodField', {
            food,
            field: 'status',
            value: 'hidden',
            silent: true,
          })
        )
      })

      Promise.all(promises)
      .then(() => {
        this.hiding = false
        this.$store.dispatch('showSuccess', 'Foods hidden')

        this.selected.forEach(id => {
          let food = this.foods.find(f => f.id == id)
          this.$store.commit('foods/addToHiddenFoods', food)
          this.$store.commit('foods/removeFood', food)
        })

        this.selected = []
      })
    },
    
    bulkPublishFoods() {
      this.hiding = true
      let promises = []
      
      this.selected.forEach(id => {
        let food = this.hiddenFoods.find(f => f.id == id)

        promises.push(
          this.$store.dispatch('foods/updateFoodField', {
            food,
            field: 'status',
            value: 'published',
            silent: true,
          })
        )
      })

      Promise.all(promises)
      .then(() => {
        this.hiding = false
        this.$store.dispatch('showSuccess', 'Foods published')

        this.selected.forEach(id => {
          let food = this.hiddenFoods.find(f => f.id == id)
          this.$store.commit('foods/addFood', food)
          this.$store.commit('foods/removeHiddenFood', food)
        })

        this.selected = []
      })
    },
    startIntro () {
      const introJs = IntroJs()

      introJs.setOptions({
        steps: [
          {
            title: 'Step 1',
            element: document.querySelector('.step-add-new'),
            intro: "Click on this 'Add New' button"
          },
          {
            title: 'Step 2',
            element: document.querySelector('.step-food-dialog'),
            intro: 'Fill out this form and click save'
          },
          {
            title: 'Step 3',
            element: document.querySelector('.step-pending'),
            intro: 'Your food will be submitted for approval. You can view it here by clicking this button'
          },
        ]
      }).onbeforechange(async(target) => {
        const step = target.getAttribute('data-step')
        if( step === null || step === '2') {
          this.addNew()
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve()
            }, 600)
          })
        }
        if( step === '3' || step === '1') { 
          this.$refs.foodDetails.closeDialog()
        }
      }).start()
    },
    firstTimeUser() {
      const introJs = IntroJs()

      setTimeout(() => {
        introJs.setOptions({
          steps: [
            {
              title: 'Guide',
              element: document.querySelector('.button-help'),
              intro: "Click on this button to launch the demo on how to add food"
            }
          ],
          disableInteraction: true,
          doneLabel: 'Got it',
          showBullets: false,
          showProgress: false,
          dontShowAgain: true
        }).start()
      }, 300)
    },

    showFoodTagDialog(food) {
      this.food = food
      this.$refs.foodTags.dialog = true
    },
    hasTags: function (val) {
      return val?.hasTags ? true : false
    },
    showFoodColumnDialog(food) {
      this.food = food
      this.$refs.foodColumn.dialog = true
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.results = []
    this.search = null

    if(this.user.role !== 'admin') {
      if(!this.$store.getters['subscriptions/subscribed']) {
          this.overlay = false
          this.$root.$emit('showSubscribeDialog')
          return
      }
    }
    
    if (this.$cookies.get('bookmark') && !this.status.firstLoad) {
      db.collection('foods')
      .doc(this.$cookies.get('bookmark'))
      .get()
      .then(doc => {
        if (doc.exists) {
          this.$store.commit('foods/updateStatus', { lastVisible: doc })
          this.getFoods()    
        }
      })
    }
    else {
      if (!this.status.firstLoad) this.getFoods()
    }
    
    if (!this.status.firstPendingLoad) this.getPendingFoods()
    if (!this.status.categoriesFirstLoad) this.getCategories()
    if (!this.status.reportsFirstLoad) this.getReportedFoods()
    if (!this.status.firstHiddenLoad) this.getHiddenFoods()

    this.firstTimeUser()
  }
}
</script>