<template>
  <div>
    <v-alert
      v-if="$store.getters['user/mealPlans'] == 0"
      type="warning"
      border="left"
      text
    >
      <span class="mr-2">
        This Nutrition professional has reached his/her maximum number of meal plans for the month. 
      </span>
    </v-alert>
    <v-card max-width="1000" class="mx-auto box-shadow" outlined>
      <v-card-title :class="$classes.cardTitle">
        <div class="d-flex" style="gap: 0.5rem">
          <img :src="user?.customLogoUrl" height="70px" />
          <div>
            <div class="text-body-1">Personal Meal Plans</div>
            <div class="text-caption">
              by <span>{{ user?.firstName }} {{ user?.lastName }}</span>
            </div>
          </div>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-overlay :value="!user">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <div>
        <v-card-text v-if="user && !done" class="pa-0">
          <v-stepper class="elevation-0" v-model="step">
            <v-stepper-header>
              <v-stepper-step :complete="step > 1" step="1">Goals</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 2" step="2"
                >Mealplan Details</v-stepper-step
              >
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 3" step="3">Tags</v-stepper-step>
              <v-divider></v-divider>
            </v-stepper-header>

            <v-stepper-items>
              <!-- Primary Goal -->
              <v-stepper-content step="1">
                <v-card class="mb-5 step-energy-dialog" data-step="3" flat>
                  <v-card-text>
                    <v-form ref="goalForm">
                      <v-row justify="center">
                        <v-col sm="12" md="8" lg="8" xl="8">
                          <v-text-field
                            v-model="data.email"
                            label="Client Email"
                            :rules="[rules.email, rules.required]"
                            outlined
                            type="email"
                            :loading="loading"
                            :disabled="$store.getters['user/mealPlans'] == 0"
                            @blur="
                              ($event) => {
                                data.email && searchClient($event);
                              }
                            "
                            dense
                          ></v-text-field>

                          <v-text-field
                            v-model="data.client"
                            label="Full Name"
                            :loading="loading"
                            :disabled="loading || $store.getters['user/mealPlans'] == 0"
                            outlined
                            dense
                          ></v-text-field>

                          <v-text-field
                            v-model.number="data.age"
                            :rules="[rules.required]"
                            label="Age"
                            :loading="loading"
                            :disabled="loading || $store.getters['user/mealPlans'] == 0"
                            type="number"
                            outlined
                            dense
                          ></v-text-field>

                          <v-radio-group
                            :rules="[rules.required]"
                            v-model="data.gender"
                            label="Gender Assigned at Birth: "
                            :disabled="$store.getters['user/mealPlans'] == 0"
                            class="mt-0"
                            dense
                            row
                          >
                            <v-radio label="Male" value="male"></v-radio>
                            <v-radio label="Female" value="female"></v-radio>
                          </v-radio-group>

                          <v-select
                            :label="plan ? 'Primary goal' : 'What is your primary goal?'"
                            :rules="[rules.required]"
                            v-model="data.goal"
                            item-value="value"
                            item-text="text"
                            :items="goals"
                            outlined
                            dense
                            :disabled="$store.getters['user/mealPlans'] == 0"
                          ></v-select>

                          <v-text-field
                            :label="plan ? 'Height (in cm)' : 'Your Height (in cm)'"
                            v-model.number="data.height"
                            :rules="[rules.required]"
                            type="number"
                            suffix="cm"
                            outlined
                            dense
                            :disabled="$store.getters['user/mealPlans'] == 0"
                          ></v-text-field>

                          <v-text-field
                            :label="plan ? 'Weight (in kg)' : 'Your Weight (in kg)'"
                            v-model.number="data.weight"
                            :rules="[rules.required]"
                            type="number"
                            suffix="kg"
                            outlined
                            dense
                            :disabled="$store.getters['user/mealPlans'] == 0"
                          ></v-text-field>

                          <v-text-field
                            :label="
                              plan
                                ? 'Body Mass Index (BMI) is'
                                : 'Your Body Mass Index (BMI) is'
                            "
                            :value="Math.round(bmi)"
                            suffix="kg/m2"
                            outlined
                            readonly
                            :disabled="$store.getters['user/mealPlans'] == 0"
                            dense
                          ></v-text-field>

                          <!-- <v-text-field
                            label="Your Goal Weight (in kg)"
                            v-model="data.goalWeight"
                            :rules="[rules.required]"
                            v-if="!plan"
                            suffix="kg"
                            outlined
                            dense
                          ></v-text-field> -->

                          <v-select
                            :hint="
                              data.physicalActivityLevel
                                ? data.physicalActivityLevel.description
                                : ''
                            "
                            :persistent-hint="!!data.physicalActivityLevel"
                            v-model="data.physicalActivityLevel"
                            label="Physical Activity Level"
                            :rules="[rules.required]"
                            item-text="name"
                            :items="levels"
                            return-object
                            outlined
                            dense
                            :disabled="$store.getters['user/mealPlans'] == 0"
                          ></v-select>
                        </v-col>

                        <!-- <v-col cols="4">
                          <v-card class="mb-3"
                            :style="{
                              backgroundColor: user?.brandColor
                                ? user?.brandColor
                                : '#97c81f',
                            }"
                          >
                            <v-card-text class="white--text">
                              <div class="font-weight-bold mb-3">
                                BMI Categories:
                              </div>
                              Underweight =  &lt; 18.5 <br>
                              Normal weight = 18.5 - 24.9 <br>
                              Overweight = 25 - 29.9 <br>
                              Obesity = BMI of 30 or greater <br>
                            </v-card-text>
                          </v-card>

                          <v-card class="d-none" color="success">
                            <v-card-text class="white--text">
                              <div class="font-weight-bold mb-3">
                                Ideal Body Weight
                              </div>
                              <div class="display-1 font-weight-bold">
                                {{ Math.round(idealWeight) }}kg
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col> -->
                      </v-row>
                    </v-form>

                    <v-row justify="end">
                      <v-btn
                        @click="goToPageTwo()"
                        depressed
                        class="white--text"
                        :disabled="$store.getters['user/mealPlans'] == 0"
                        :style="{
                          backgroundColor: user?.brandColor
                            ? user?.brandColor
                            : '#97c81f',
                        }"
                      >
                        Continue
                      </v-btn>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-stepper-content>

              <!-- RESULT -->
              <v-stepper-content step="2">
                <v-card class="mb-5" flat>
                  <v-card-text>
                    <v-form ref="parameterForm">
                      <!-- <v-row>
                      <v-col>
                        <p class="mb-3">Enter the Mealplan Name</p>
                        <v-text-field
                          v-model="mealplanData.name"
                          :rules="[rules.required]"
                          label="Name"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row> -->
                      <!-- <p class="mb-3">What does your typical meal pattern look like? This is to provide a guide on how many kilojoules to aim for each meal.</p> -->

                      <v-row>
                        <v-col cols="12">
                          <label
                            >Select the types of meals you would eat at least once a
                            week:</label
                          >

                          <div class="d-flex flex-wrap gap-12">
                            <v-checkbox
                              label="Breakfast"
                              value="breakfast"
                              v-model="data.mainMeals"
                              multiple
                              dense
                            ></v-checkbox>
                            <v-checkbox
                              label="Lunch"
                              value="lunch"
                              v-model="data.mainMeals"
                              multiple
                              dense
                            ></v-checkbox>
                            <v-checkbox
                              label="Dinner"
                              value="dinner"
                              v-model="data.mainMeals"
                              multiple
                              dense
                            ></v-checkbox>
                            <v-checkbox
                              label="Dessert"
                              value="desert"
                              v-model="data.mainMeals"
                              multiple
                              dense
                            ></v-checkbox>
                          </div>
                        </v-col>
                        <v-col>
                          <p class="mb-3">How many snacks do you want to eat each day?</p>

                          <v-select
                            v-model="data.snacks"
                            :items="[0, 1, 2, 3]"
                            id="goalSnacks"
                            outlined
                            dense
                          ></v-select>
                        </v-col>
                      </v-row>

                      <!-- <v-alert v-if="(sktbg < 5000 || sctbg < 1200) && user.role == 'member'" type="warning" border="left" text>
                      It is not recommended to consume less than 5000kJ (or 1200Cal) per day without professional support. If you wish to find a dietitian, please click this link <a href="https://member.dietitiansaustralia.org.au/Portal/Portal/Search-Directories/Find-a-Dietitian.aspx" target="_blank">https://member.dietitiansaustralia.org.au/Portal/Portal/Search-Directories/Find-a-Dietitian.aspx</a>.
                    </v-alert>
                    
                    <v-alert v-else-if="(sktbg < 5000 || sctbg < 1200) && user.role == 'personal_trainer'" type="warning" border="left" text>
                      CAUTION: Providing a meal plan to a client that is less than 5000kJ (1200Cal), requires intensive support from a trained nutrition professional. If suggesting less than 5000kJ (1200Cal), please proceed with caution and ensure you are suitably qualified to do so.
                    </v-alert>
                    
                    <v-alert v-else-if="(sktbg < 5000 || sctbg < 1200) && (user.role == 'dietitian' || user.role == 'nutritionist' || user.role == 'naturopath')" type="warning" border="left" text>
                      CAUTION: Providing a meal plan to a client that is less than 5000kJ (1200Cal), requires intensive nutrition support and monitoring. If suggesting less than 5000kJ (1200Cal), please proceed with caution and monitor your client regularly.
                    </v-alert> -->

                      <!-- <p class="mb-3">Suggested energy target based on your goals</p> -->

                      <!-- <v-row>
                      <v-col>
                        <v-text-field
                          :value="$options.filters.numberFormat(sktbg)"
                          suffix="kJ"
                          readonly
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          :value="$options.filters.numberFormat(sctbg)"
                          suffix="Cal"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row> -->

                      <!-- <p class="mb-3">The above daily energy requirement is an estimate only. If you have a preferred or measured daily energy target, please add it here in either kilojoules or calories, and we will create a meal plan suited to your needs.</p> -->

                      <!-- <v-row>
                      <v-col>
                        <v-text-field
                          :rules="[(value) => (['dietitian', 'personal_trainer', 'nutritionist', 'naturopath'].includes(user.role) || (!value && value <= 0) || value >= 5000) || `Value can't be less than 5000kJ`]"
                          v-model.number="data.customTarget"
                          type="number"
                          suffix="kJ"
                          clearable
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          :value="Math.round((data.customTarget || 0) / 4.18)"
                          @input="data.customTarget = $event * 4.18"
                          type="number"
                          suffix="Cal"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row> -->

                      <div class="d-none">
                        <p class="mb-3">
                          Each of your main meals should contain approximately
                        </p>

                        <v-row>
                          <v-col>
                            <v-text-field
                              :value="$options.filters.numberFormat(mainMealsEnergy)"
                              suffix="kJ"
                              readonly
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <p class="mb-3">
                          Each of your snacks should contain approximately
                        </p>

                        <v-row>
                          <v-col>
                            <v-text-field
                              :value="$options.filters.numberFormat(snacksEnergy)"
                              suffix="kJ"
                              readonly
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </div>
                    </v-form>

                    <!-- BUTTONS -->
                    <v-row justify="end">
                      <v-btn
                        @click="step = 1"
                        :disable="status.creating"
                        class="mr-2"
                        text
                        >Back</v-btn
                      >
                      <v-btn
                        @click="goToPageThree()"
                        depressed
                        class="white--text mr-2"
                        :style="{
                          backgroundColor: user?.brandColor
                            ? user?.brandColor
                            : '#97c81f',
                        }"
                      >
                        Continue
                      </v-btn>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-stepper-content>

              <!-- TAGS -->
              <v-stepper-content step="3">
                <!-- <p class="mb-3 grey--text text--darken-1">Please select any meal tags that suit your lifestyle?</p>
                <v-sheet max-width="590" color="transparent">
                  <v-select
                    :items="mealTags"
                    v-model="customModel.mealTags"
                    placeholder="Select meal tags"
                    class="meal-tags align-start"
                    v-if="mealTags.length"
                    item-text="name"
                    item-value="id"
                    deletable-chips
                    height="50"
                    width="100%"
                    small-chips
                    multiple
                    outlined
                    chips
                    dense
                  ></v-select>
                  <v-combobox
                    placeholder="Type the Meal Tags you would like to exclude then press enter"
                    v-model="customModel.mealTags"
                    class="meal-tags align-start"
                    deletable-chips
                    @change="($event) => filterMealTags($event)"
                    width="100%"
                    small-chips
                    multiple
                    outlined
                    chips
                    dense
                  ></v-combobox>
                </v-sheet> -->
                <!-- <p class="mb-3 grey--text text--darken-1">Please select any nutrition tags or diet requirements you have? (use this feature to exclude food groups)</p> -->
                <p class="mb-3 grey--text text--darken-1">
                  Please list any diet preferences i.e. low carb or gluten-free.
                </p>
                <v-sheet max-width="590" color="transparent">
                  <!-- <v-select
                    :items="nutritionTags"
                    v-model="customModel.nutritionTags"
                    placeholder="Select nutrition tags"
                    class="meal-tags align-start"
                    v-if="nutritionTags.length"
                    item-text="name"
                    item-value="id"
                    deletable-chips
                    height="50"
                    width="100%"
                    small-chips
                    multiple
                    outlined
                    chips
                    dense
                  ></v-select> -->
                  <v-combobox
                    placeholder="PLEASE PRESS ENTER AFTER EACH DIETARY PREFERENCE BEFORE ADDING THE NEXT"
                    v-model="customModel.tags"
                    class="meal-tags align-start"
                    append-icon=""
                    deletable-chips
                    @change="($event) => filterNutriTags($event)" 
                    h="100%"
                    small-chips
                    multiple
                    outlined
                    chips
                    dense
                  >
                  </v-combobox>
                </v-sheet>
                <p class="mb-3 grey--text text--darken-1">
                  List any individual foods you wish to exclude i.e. cashews. To exclude
                  entire food groups such as nuts, please use the diet preferences box
                  above i.e. 'Nut Free'
                </p>
                <v-sheet max-width="590" color="transparent">
                  <!-- <v-autocomplete
                    placeholder="Type the ingredients you would like to exclude then press enter"
                    v-model="customModel.ingredientTags"
                    class="meal-tags align-start"
                    :items="foods"
                    deletable-chips
                    width="100%"
                    small-chips
                    multiple
                    outlined
                    chips
                  ></v-autocomplete> -->
                  <v-combobox
                    placeholder="PLEASE PRESS ENTER AFTER EACH FOOD BEFORE ADDING THE NEXT"
                    v-model="customModel.ingredientTags"
                    append-icon=""
                    class="meal-tags align-start"
                    deletable-chips
                    @change="($event) => filterIngredientTags($event)"
                    width="100%"
                    small-chips
                    multiple
                    outlined
                    chips
                    dense
                    solo
                  >
                    <template v-slot:selection="{ attrs, item, selected, index  }">
                      <v-chip
                        :input-value="selected"
                        v-bind="attrs"
                        close
                        small
                        @click:close="removeIngredientTag(item)"
                        v-if="!hideExcludedWords"
                      >
                       {{ item }}
                      </v-chip>
                      <span v-else>
                        <v-chip
                          :input-value="selected"
                          v-bind="attrs"
                          close
                          small
                          @click:close="removeIngredientTag(item)"
                          v-if="index == 0"
                        >
                        {{ item }}
                        </v-chip>
                        <v-chip
                          :input-value="selected"
                          v-bind="attrs"
                          close
                          small
                          @click:close="removeIngredientTag(item)"
                          v-if="index == 1"
                        >
                        {{ item }}
                        </v-chip>
                        <v-chip
                          :input-value="selected"
                          v-bind="attrs"
                          close
                          small
                          @click:close="removeIngredientTag(item)"
                          v-if="index == 2"
                        >
                        {{ item }}
                        </v-chip>
                        <span
                          v-if="index === 3"
                          class="grey--text text-caption"
                        >
                          (+{{ customModel.ingredientTags.length - 3 }} others)
                        </span>
                      </span>
                    </template>
                  </v-combobox>
                  <v-checkbox
                    v-model="hideExcludedWords"
                    label="Hide Excluded words"
                    class="px-0"
                    color="success"
                    dense
                  ></v-checkbox>
                </v-sheet>

                <v-row
                  justify="end"
                  class="pa-3"
                  v-if="
                    $store.getters['user/mealPlans'] > 0 ||
                    $store.getters['user/mealPlans'] == -1
                  "
                >
                  <v-btn @click="step = 2" :disable="adding" text class="mr-2"
                    >Back</v-btn
                  >
                  <v-btn
                    :loading="adding"
                    @click="validateForm()"
                    depressed
                    class="white--text mr-2"
                    :style="{
                      backgroundColor: user?.brandColor ? user?.brandColor : '#97c81f',
                    }"
                  >
                    Submit
                  </v-btn>
                </v-row>

                <v-row justify="end" class="pa-3" v-else>
                  <p class="text-caption red--text">
                    *This {{ determineRole(user.role) }} has reach his/her meal plans
                    limits
                  </p>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-text v-if="done">
          <v-alert text type="success">
            Success! Your form has been submitted to
            <strong>{{ user.firstName }} {{ user.lastName }}</strong
            >. Thank you!
          </v-alert>
        </v-card-text>
      </div>
      <!-- <v-card-text 
      >
        <v-alert
          text
          type="warning"
          v-if="!gettingUserInfo"
        >
          This {{ determineRole(user.role) }} has reach his/her meal plans limits
        </v-alert>
        <div class="d-flex justify-center align-center flex-wrap" v-else>
          <div class="col-12 pa-0 text-center">
              <div class="text-subtitle1">Please wait..</div>
          </div>
          <div class="col-12 text-center">
              <v-progress-circular indeterminate size="34"></v-progress-circular>
          </div>
        </div>
      </v-card-text> -->
    </v-card>
    <v-dialog max-width="380" v-model="showWarning" persistent>
      <v-card>
        <v-card-title>
          <v-icon left>mdi-alert</v-icon>
          <span class="font-weight-bold">Please Note</span>
        </v-card-title>
        <v-card-text>
          <div>
            This link is inactive. Please contact
            <a :href="`mailto:${user?.email}`">{{ user?.email }}</a> to discuss.
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import rules from "@/rules";
import { mapState, mapGetters, mapActions } from "vuex";
import { goals, frequencies, levels, patterns, stress } from "@/options";
import { startCase } from "lodash";
import { nutriTagsKeywords } from "@/options"

import algoliasearch from "algoliasearch";
const client = algoliasearch(
  process.env.VUE_APP_ALGOLIA_APP_ID,
  "b01a34dc50c42c2e6fe90f9ec6d376ad"
);
const index = client.initIndex("foods");

export default {
  name: "Form",

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    plan: Object,
    add: {
      type: Boolean,
      default: true,
    },
    editNewMealplan: Boolean,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      goals,
      rules,
      levels,
      stress,
      step: 1,
      patterns,
      startCase,
      done: false,
      frequencies,
      adding: false,
      loading: false,
      customModel: {
        tags: [],
      },
      showWarning: false,
      currentClient: null,
      // gettingUserInfo: true,
      existingClient: false,
      nutriTagsKeywords,
      mealTagsKeywords: [
        {
          name: "6 Ingredients or less",
          keywords: [
            "Quick",
            "Easy",
            "Simple",
            "Minimalist",
            "Few",
            "Basic",
            "Effortless",
            "Streamlined",
            "Fast",
            "Convenient",
            "Minimal",
            "Short",
            "Speedy",
            "Basic ingredients",
            "Light",
            "Straightforward",
            "Uncomplicated",
            "Clean",
            "Essentials",
            "Barebones",
            "Quick-fix",
            "Snappy",
            "No-fuss",
            "Hassle-free",
            "Beginner",
            "Speed-cooking",
            "Low-ingredient",
            "Five-ingredient",
            "Six-ingredient",
            "Quick meals",
            "Easy prep",
            "Fast food",
            "Quick dinner",
            "Simple cooking",
            "Express",
            "Time-saver",
            "Efficient",
            "Instant",
            "Rapid",
            "Breezy",
            "Lazy meal",
            "Minimal cooking",
            "Quick lunch",
            "Speedy breakfast",
            "Simple snacks",
            "Fast prep",
            "Short-list ingredients",
            "Four-ingredient",
            "Easy recipes",
            "Basic cooking",
            "4 Ingredient",
            "5 ingredient",
            "6 ingredient",
          ],
        },
        {
          name: "Halal Friendly",
          keywords: [
            "Halal",
            "Permissible",
            "Zabihah",
            "Islamic diet",
            "Muslim-friendly",
            "Shariah compliant",
            "No alcohol",
            "No pork",
            "Halal-certified",
            "Halal meat",
            "Halal poultry",
            "Pure",
            "Clean eating",
            "Halal snacks",
            "Religious diet",
            "Ethical meat",
            "Halal seafood",
            "Halal ingredients",
            "Faith-based diet",
            "Spiritual eating",
            "Halal cooking",
            "Halal dining",
            "Islamic cooking",
            "Muslim meals",
            "Shariah diet",
            "Halal kitchen",
            "Halal recipes",
            "Halal grocery",
            "Halal products",
            "Halal guidelines",
            "Modest eating",
            "Ethical eating",
            "Halal certification",
            "Islamic cuisine",
            "Halal sweets",
            "Halal sauces",
            "Prayerful eating",
            "Ramadan recipes",
            "Eid meals",
            "Islamic fasting",
            "Halal fasting",
            "Halal lifestyle",
            "Halal nourishment",
            "Muslim cooking",
            "Islamic meals",
            "Halal meal prep",
            "Halal food guide",
            "Halal culinary",
            "Halal dining options",
            "Halal food choices",
          ],
        },
        {
          name: "Mediterranean or Anti-Inflammatory",
          keywords: [
            "Mediterranean diet",
            "Anti-inflammatory",
            "Olive oil",
            "Whole grains",
            "Healthy fats",
            "Omega-3",
            "Fiber-rich",
            "Antioxidants",
            "Polyphenols",
            "Turmeric",
            "Heart-healthy",
            "Low-inflammatory",
            "Whole food",
            "Natural ingredients",
            "Fish oil",
            "Flavonoids",
            "Fatty fish",
            "No processed foods",
            "Low sugar",
            "Grilled fish",
            "Traditional Mediterranean",
            "Mediterranean spices",
            "Anti-inflammatory herbs",
            "Mediterranean cooking",
            "Unprocessed",
            "No added sugar",
            "Antioxidant-rich",
            "Nutrient-dense",
          ],
        },
        {
          name: "Paleo Friendly",
          keywords: [
            "Paleo",
            "Grain-free",
            "Legume-free",
            "Natural foods",
            "Whole foods",
            "Caveman diet",
            "Hunter-gatherer diet",
            "Primal diet",
            "Organic vegetables",
            "Grass-fed",
            "Free-range",
            "Wild-caught fish",
            "Paleo snacks",
            "Paleo desserts",
            "Paleo baking",
            "Bone broth",
            "Paleo-friendly",
            "Clean eating",
            "Paleo breakfast",
            "Paleo lunch",
            "Paleo dinner",
            "Autoimmune Paleo",
            "Paleo guidelines",
            "Paleo recipes",
            "Paleo meal prep",
            "Paleo diet plan",
            "Paleo cooking",
            "Whole30",
            "Paleo lifestyle",
            "Paleo bread",
            "Homemade Paleo",
            "Paleo compliant",
          ],
        },
        {
          name: "Pescatarian Friendly",
          keywords: [
            "Pescatarian",
            "Seafood",
            "Fish",
            "Sustainable seafood",
            "Pescatarian recipes",
            "Vegetarian-plus-fish",
            "Flexitarian",
            "Seafood pasta",
            "Fish tacos",
            "Grilled seafood",
            "Sushi",
            "Pescatarian breakfast",
            "Pescatarian lunch",
            "Pescatarian dinner",
            "Seafood salads",
            "Fish soups",
            "Pescatarian snacks",
            "Pescatarian meal plan",
            "Vegetarian meals with fish option",
          ],
        },
        {
          name: "Vegetarian Friendly",
          keywords: [
            "Vegetarian",
            "Meatless",
            "Vegetarian protein",
            "Vegetarian soups",
            "Vegetarian salads",
            "Vegetarian breakfast",
            "Vegetarian lunch",
            "Vegetarian dinner",
            "Vegetarian snacks",
            "Vegetarian baking",
            "Vegetarian cuisine",
            "Global vegetarian",
            "Vegetarian meal plan",
            "Vegetarian dishes",
            "Vegetarian cooking",
            "Vegetarian grilling",
            "Vegetarian pasta",
            "Vegetarian pizza",
            "Meat substitutes",
            "Vegetarian burgers",
            "Vegetarian meal prep",
          ],
        },
        {
          name: "Vegan Friendly",
          keywords: [
            "Vegan",
            "Plant-based",
            "Animal-free",
            "Vegan protein",
            "Vegan cheese",
            "Nutritional yeast",
            "Vegan milk",
            "Vegan butter",
            "Vegan desserts",
            "Vegan baking",
            "Vegan snacks",
            "Vegan breakfast",
            "Vegan lunch",
            "Vegan dinner",
            "Vegan sauces",
            "Vegan dressings",
            "Vegan soups",
            "Vegan salads",
            "Meat substitutes",
            "Vegan pizza",
            "Vegan burgers",
            "Vegan meal prep",
            "Vegan cuisine",
            "Cruelty-free",
            "Vegan lifestyle",
            "Vegan dining",
            "Plant milk",
            "Nut butters",
          ],
        },
      ],
      hideExcludedWords: true,
    };
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: (state) => state.goal.data,
      goal: (state) => state.goal.goal,
      user: (state) => state.user.user,
      status: (state) => state.goal.status,
      plans: (state) => state.mealplans.plans,
      foodNames: (state) => state.foods.foodNames,
      mealplanData: (state) => state.mealplans.data,
      mealplanStatus: (state) => state.mealplans.status,
      alternateWords: (state) => state.foods.alternateWords,
      currentFolder: (state) => state.mealplans.currentFolder,
    }),

    nutritionTags: function () {
      return this.$store.getters["nutritiontags/tags"];
    },

    mealTags: function () {
      return this.$store.getters["tags/tags"];
    },

    ...mapGetters("goal", [
      "customDailyEnergyRequirementLose",
      "dailyEnergyRequirementLose",
      "dailyEnergyRequirementCal",
      "dailyEnergyRequirement",
      "weightLossEnergyTarget",
      "energyRequirementsLose",
      "mainMealsEnergy",
      "energyTarget",
      "snacksEnergy",
      "idealWeight",
      "adjusted",
      "eermwCal",
      "eerlwCal",
      "eermwKj",
      "eerlwKj",
      "sctbg",
      "sktbg",
      "bmi",
    ]),

    dailyEnergyRequirementComp: function () {
      return this.dailyEnergyRequirement;
    },

    mealTagNames: function () {
      return this.mealTags.map((tag) => tag.name.toLowerCase());
    },
    nutritionTagsNames: function () {
      return this.nutritionTags.map((tag) => tag.name.toLowerCase());
    },
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    goal: function (value) {
      if (value) {
        this.$store.commit("goal/setData", value);
      }
    },

    // 'data': function (value) {
    //   if (value) {
    //     console.log(value)
    //     this.$store.commit('goal/setData', value)
    //   }
    // },

    sktbg: {
      handler(value) {
        if (value < 5000) this.data.customTarget = 5000;
        else this.data.customTarget = 0;
      },
      immediate: true,
    },

    "data.customTarget": function (val) {
      if (val) this.data.customTarget = Math.round(val);
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions("goal", ["deleteCustomTarget", "getGoal", "create", "update"]),

    ...mapActions("mealplans", [
      "autoGenerateFromForm",
      "updateFolderFromForm",
      "addPlanToFolderForm",
      "addFolderFromForm",
      "getPlansFromForm",
      "searchFolder",
    ]),

    ...mapActions("user", ["getUserInfo"]),

    ...mapActions("foods", ["getFoodsForForm"]),

    ...mapActions("subscriptions", ["getPayments", "getSubscriptions"]),

    goToPageTwo() {
      if (this.existingClient)
        this.$store.commit("goal/setAddNewMealplan", {
          mainMeals: [],
          snacks: 0,
          customTarget: 0,
        });

      if (this.$refs.goalForm.validate()) {
        this.step = 2;
      }
    },

    goToPageThree() {
      if (this.$refs.parameterForm.validate()) {
        this.step = 3;
      }
    },

    gotoPageFour() {
      if (this.$refs.clinicalForm.validate()) {
        this.step = 4;
      }
    },

    validateForm() {
      this.adding = true;
      let task;

      if (this.existingClient)
        task = Promise.resolve(
          this.updateFolderFromForm({
            id: this.currentClient.id,
            ref: this.currentClient.ref,
            ...this.data,
          })
        );
      else
        task = Promise.resolve(this.addFolderFromForm({ user: this.user, ...this.data }));

      task
        .then(({ newData: taskData }) => {
          let mealplan, name;

          if (this.existingClient) {
            name = `${this.data.client} ${this.data.mealplans.length + 1}`;
            mealplan = Promise.resolve(
              this.$store.dispatch("mealplans/createFromForm", {
                id: this.user.id,
                folderId: this.currentClient.id,
                name,
                mealplan: true,
              })
            );
          } else {
            name = `${this.data.client} 1`;

            mealplan = Promise.resolve(
              this.$store.dispatch("mealplans/createFromForm", {
                id: this.user.id,
                folderId: taskData.id,
                name,
                mealplan: true,
              })
            );
          }

          /**
           * TODO: Find a way to optimize this
           */
          mealplan
            .then(({ error, newData, data }) => {
              if (!error) {
                setTimeout(() => {
                  Promise.resolve(
                    this.$store.dispatch("goal/createFromForm", {
                      id: newData.id,
                      user: this.user,
                    })
                  ).then(async () => {
                    let model = { ...this.customModel };

                    let nTags = this.customModel.tags.filter((t) =>
                      this.nutritionTagsNames.includes(t.toLowerCase())
                    );

                    let mTags = this.customModel.tags.filter((t) =>
                      this.mealTagNames.includes(t.toLowerCase())
                    );

                    model.nutritionTags = nTags.map((tag) => {
                      return this.nutritionTags.find((t) => t.name.toLowerCase() == tag.toLowerCase()).id;
                    });

                    model.mealTags = mTags.map((tag) => {
                      return this.mealTags.find((t) => t.name.toLowerCase() == tag.toLowerCase()).id;
                    });

                    this.$store.dispatch("saveDocument", { ...newData, ...model });

                    if (model?.ingredientTags?.length) {
                      const tags = await this.getIngredientTags();
                      model.ingredientTags = tags;
                    }

                    setTimeout(() => {
                      if (this.existingClient) {
                        this.addPlanToFolderForm({
                          id: newData.id,
                          folder: this.currentClient,
                        });
                      } else {
                        this.addPlanToFolderForm({ id: newData.id, folder: taskData });
                      }

                      setTimeout(() => {
                        Promise.resolve(
                          this.autoGenerateFromForm({
                            plan: { ...newData, ...data, ...model },
                            roleType: "all",
                            user: this.user,
                          })
                        ).then(() => {
                          setTimeout(() => {
                            let emailData = {
                              recipient: this.user.email,
                              subject: "Meal Plan Submission",
                              message: `Hi ${this.user.firstName} ${this.user.lastName}, <br/> <br/> ${this.data.client} is requesting a new meal plan via a new submission of the Meal Plan Questionnaire. <br/><br/>
                          If they are a new client, a new folder would have been created with their meal plan within that folder. <br/><br/>
                          If they are an existing client, and the email address they submitted matches the one in your profile, then a new meal plan will have been added to their existing folder. <br/><br/>
                          If they are an existing client however, no folder will exist for them due to being added to the system before the creation of the folder system. Instead, you will find their meal plan in the 'Others' folder. <br/> <br/>
                          Feel free to reach, email us at <a href="mailto:hello@mealzee.com" style="text-decoration: underline">hello@mealzee.com</a> if you have any question. <br/> <br/>
                          Regards<br/> <br/>

                          Chris Hughes - Dietitian & Founder of MEALZEE
                          `,
                            };

                            this.$store.dispatch("mail/notify", emailData);
                            this.adding = false;
                            this.done = true;
                            this.$store.dispatch("mealplans/updateMealField", {
                              value: true,
                              field: "mealsLoaded",
                              meal: newData,
                              silent: true,
                            });

                            if(this.user?.mealSlots > 0) {
                              this.$store.dispatch('user/updateUserFieldFromForm', {
                                field: 'mealSlots',
                                value: this.user?.mealSlots - 1,
                                silent: true,
                                user: this.user
                              });
                            }

                          }, 200);
                        });
                      }, 400);
                    }, 800);
                  });
                }, 800);
              } else {
                console.log(error);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getIngredientTags() {
      return new Promise((resolve) => {
        let allIngredientTags = [...this.customModel.ingredientTags];
        this.customModel.ingredientTags.forEach(async (tag, i, array) => {
          /*
           * TODO: ADD A CHECK TO FOR ERRORS
           */

          const { hits } = await index.search(tag);

          if (hits.length)
            hits.forEach((hit) => {
              allIngredientTags.push(hit.name);
            });

          if (i == array.length - 1) resolve(allIngredientTags);
        });
      });
    },

    setGoal() {
      if (this.editNewMealplan) this.getGoal(this.plan ? this.plan.id : null);
    },

    searchClient(e) {
      this.loading = true;
      Promise.resolve(this.searchFolder({ email: e.target.value, user: this.user }))
        .then((res) => {
          if (res) {
            this.$store.dispatch("showSuccess", "Client info found");
            this.$store.commit("goal/setGoalData", res);
            this.existingClient = true;
            this.currentClient = res;
          } else {
            if (this.data.client) {
              this.$store.commit("goal/setGoalData", { mainMeals: [] });
              this.currentClient = null;
            }

            this.existingClient = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    determineRole(role) {
      return role === "personal_trainer"
        ? "Health & Fitness Professional"
        : startCase(role);
    },

    filterIngredientTags(val) {
      if(!val.length) return

      // RESULT BASE ON ALTERNATE WORDS

      let result = []

      let re = new RegExp(val[val.length - 1], 'gi')

      this.alternateWords.forEach((word) => {

        if(word.alternateWords.search(re) != -1) {
          result.push(word.name)
        }
      })

      if(result.length) {
        this.customModel.ingredientTags.pop()

        result.forEach(r => {
          if(this.customModel.ingredientTags.includes(r)) {
            this.$store.dispatch('showSuccess', `${r} is on the list already`)
            return
          }
          this.customModel.ingredientTags.push(r)
        })
      }

      // RESULT BASE ON FOOD NAMES

      let result1 = []

      result1 = this.foodNames.filter((food) => food.search(re) != -1 )

      if(result1.length) {
        this.customModel.ingredientTags.pop()
        result1.forEach(r => {
          if(this.customModel.ingredientTags.includes(r)) {
            this.$store.dispatch('showSuccess', 'This Food is already in the list')
            return
          }
          this.customModel.ingredientTags.push(r)
        })
      }

      if(!result1.length && !result.length) {
        this.$store.dispatch('showError', 'Sorry, selected ingredient is not found in our database')
        this.customModel.ingredientTags.pop()
      }  
      
    },

    removeIngredientTag(item) {
      this.customModel.ingredientTags.splice(this.customModel.ingredientTags.indexOf(item), 1)
    },

    // filterIngredientTags(val) {
    //   let result;

    //   let re = new RegExp(val[val.length - 1], "gi");
    //   result = this.foods.some((food) => re.test(food));

    //   if (!result) {
    //     this.$store.dispatch(
    //       "showError",
    //       "Sorry, selected ingredient is not found in our database"
    //     );

    //     this.customModel.ingredientTags.pop();
    //   }
    // },

    // filterMealTags(val) {
    //   if(!val.length) return

    //   let result;
    //   let BreakException = {}

    //   let re = new RegExp(val[val.length - 1], "gi")

    //   this.mealTagsKeywords.forEach(tag => {
    //     result = tag.keywords.some((k) => re.test(k))

    //     if(result) {

    //      this.customModel.mealTags.pop();

    //      let exist = this.customModel.mealTags.some(t => t == tag.name)

    //      if(exist) {
    //         this.$store.dispatch(
    //           "showSuccess",
    //           `The tag is part of the ${tag.name} meal tag`
    //         );
    //      } else {
    //       this.customModel.mealTags.push(tag.name)
    //      }

    //      throw BreakException

    //     }
    //   })

    //   if (!result && !this.mealTagNames.includes(val[val.length - 1])) {
    //     this.$store.dispatch(
    //       "showError",
    //       "Sorry, selected meal tag is not available"
    //     );

    //     this.customModel.mealTags.pop();
    //   }
    // },

    // filterNutriTags(val) {
    //   if(!val.length) return

    //   let result;
    //   let BreakException = {}

    //   let re = new RegExp(val[val.length - 1], "gi")

    //   this.nutriTagsKeywords.forEach(tag => {
    //     result = tag.keywords.some((k) => re.test(k))

    //     if(result) {

    //      this.customModel.nutritionTags.pop();

    //      let exist = this.customModel.nutritionTags.some(t => t == tag.name)

    //      if(exist) {
    //         this.$store.dispatch(
    //           "showSuccess",
    //           `The tag is part of the ${tag.name} meal tag`
    //         );
    //      } else {
    //       this.customModel.nutritionTags.push(tag.name)
    //      }

    //      throw BreakException

    //     }
    //   })

    //   if (!result && !this.nutriTagsKeywords.includes(val[val.length - 1])) {
    //     this.$store.dispatch(
    //       "showError",
    //       "Sorry, selected nutrition tag is not available"
    //     );

    //     this.customModel.nutritionTags.pop();
    //   }
    // },

    filterNutriTags(val) {
      if (!val.length) return;

      let result;
      let BreakException = {};

      // let re = new RegExp("\\b" + val[val.length - 1] +  "\\b", "i")
      let re = new RegExp(val[val.length - 1], "gi");

      this.nutriTagsKeywords.forEach((tag) => {
        result = tag.keywords.some((k) => re.test(k));

        if (result) {
          this.customModel.tags.pop();

          let exist = this.customModel.tags.some((t) => t == tag.name);

          if (exist) {
            this.$store.dispatch("showSuccess", "Tag is already in used.");
          } else {
            this.customModel.tags.push(tag.name);
          }

          throw BreakException;
        }
      });

      if (!result && !this.nutriTagsKeywords.some((t) => t.name == val[val.length - 1])) {
        this.$store.dispatch("showError", "Sorry, the selected tag is not available");

        this.customModel.tags.pop();
      }
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    Promise.resolve(this.getUserInfo(this.$route.query.i)).then(async () => {
      await Promise.resolve(this.getPayments(this.user.id));

      await Promise.resolve(this.getSubscriptions(this.user.id));

      // this.gettingUserInfo = false;
    });
    if (!this.$store.state.tags.status.firstLoad) {
      this.$store.dispatch("tags/getTags");
    }
    this.$store.dispatch("nutritiontags/getTags");
    this.getFoodsForForm(this.user);
  },
};
</script>
