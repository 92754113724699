<template>
  <v-img
    :src="photoUrl"
    placeholder="/placeholder.svg"
    aspect-ratio="1.7"
    min-height="70"
    min-width="70"
  ></v-img>
</template>

<script>

import firebase from 'firebase'

export default {
  name: 'RecipeImage',

  props: {
    size: String,
    recipe: Object,
    updateFields: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      photoUrl: '/placeholder.svg',
    }
  },

  watch: {
    'recipe': {
      handler(recipe) {
        if (recipe) this.getUrl(recipe)
      },
      immediate: true
    }
  },

  methods: {
    getUrl(recipe) {
      var storage  = firebase.storage()
      
      if (recipe && recipe.photo) {
        const enable = false

        if (recipe[`${this.size}PhotoUrl`] && enable) {
          var img = new Image
          img.src = recipe[`${this.size}PhotoUrl`]
          img.onload = () => {
            this.photoUrl = recipe[`${this.size}PhotoUrl`]
            this.$emit('url', this.photoUrl)
          }
        }
        else {
          storage.ref(`recipes/${this.size}_${recipe.photo}`).getDownloadURL()
          .then(url => {
            this.photoUrl = url
            this.$emit('url', url)
  
            if(this.updateFields) {
              this.$store.dispatch('recipe/updateRecipeField', {
                value: url,
                silent: true,
                recipe: this.recipe,
                field: `${this.size}PhotoUrl`,
              })
            }
          })
          .catch(() => {
            storage.ref(`recipes/${recipe.photo}`).getDownloadURL()
            .then(url => {
              this.photoUrl = url
              this.$emit('url', url)

              if(this.updateFields) {
                this.$store.dispatch('recipe/updateRecipeField', {
                  value: url,
                  silent: true,
                  recipe: this.recipe,
                  field: `OriginalPhotoUrl`,
                })
              }
              
            })
            .catch(error => {
              console.log(error.message)
            })
          })
        }
      }
      else {
        this.photoUrl = '/placeholder.svg'
        this.$emit('url', this.photoUrl)
      }

    },
  },

  mounted() {
    this.getUrl(this.name)
  }
}

</script>
