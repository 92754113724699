<template>
  <div>
    <v-alert v-if="user.status == 'pending'" type="warning" border="left" text>
      Your account is currently pending for approval.
    </v-alert>

    <v-card v-if="user.status == 'active'">
      <v-card-title :class="$classes.cardTitle">
        <div class="d-flex justify-space-between" style="width: 100%">
          <span class="font-weight-bold title mr-5">Recipes</span>
          <div class="gap-5 d-flex align-center">
            <v-btn
              :disabled="!this.$store.getters['subscriptions/subscribed']"
              :to="{ name: 'AddRecipe' }"
              color="primary"
              small
              depressed
              >Add New</v-btn
            >
            <v-btn
              v-if="user.role == 'admin'"
              :loading="status.gettingAllIds"
              color="secondary"
              @click="openAllRecipeIdsDialog()"
              small
              depressed
              >Update All</v-btn
            >
          </div>
        </div>
        <div
          class="d-flex flex-column align-end flex-md-row flex-lg-row align-md-center align-lg-center justify-lg-space-between"
          style="width: 100%; gap: 0.5rem"
        >
          <v-text-field
            v-model="search"
            @keydown.enter="searchAll(search)"
            @click:clear="$store.commit('recipes/clearSearch')"
            placeholder="Search Recipe"
            style="max-width: 1000px; width: 100%"
            class="white"
            hide-details
            clearable
            rounded
            dense
            outlined
          >
            <template #append>
              <v-btn
                :loading="status.searching"
                class="mr-n2"
                icon
                small
                disabled
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-5 pt-5 pb-0 pr-0 fill-height">
        <v-row>
          <v-col class="fill-height" cols="3">
            <v-card class="overflow-y-hidden overflow-x-hidden px-2" flat>
              <v-card outlined class="mb-3">
                <v-expansion-panels>
                  <!-- FILTER BY MEAL TAGS -->
                  <v-expansion-panel v-if="mealTags.length">
                    <v-expansion-panel-header class="font-weight-bold">
                      Filter by Meal Tags
                      <v-badge
                        v-if="
                          tagsFilter?.mealTags && tagsFilter.mealTags.length
                        "
                        color="red"
                        :content="tagsFilter.mealTags.length"
                        :style="{ flex: 'none' }"
                        offset-x="-8"
                        offset-y="10"
                      >
                      </v-badge>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-checkbox
                        v-model="tagsFilter.mealTags"
                        v-for="tag in mealTags"
                        class="mt-1 caption"
                        :label="tag.name"
                        :value="tag.id"
                        :key="tag.id"
                        hide-details
                        multiple
                        dense
                      ></v-checkbox>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <!-- FILTER BY NUTRTITION TAGS -->
                  <v-expansion-panel v-if="nutritionTags.length">
                    <v-expansion-panel-header class="font-weight-bold">
                      Filter by Nutrition Tags
                      <v-badge
                        v-if="
                          tagsFilter?.nutritionTags &&
                          tagsFilter.nutritionTags.length
                        "
                        color="red"
                        :content="tagsFilter.nutritionTags.length"
                        :style="{ flex: 'none' }"
                        offset-x="-8"
                        offset-y="10"
                      >
                      </v-badge>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-checkbox
                        v-model="tagsFilter.nutritionTags"
                        v-for="tag in nutritionTags"
                        class="mt-1 caption"
                        :label="tag.name"
                        :value="tag.id"
                        :key="tag.id"
                        hide-details
                        multiple
                        dense
                      ></v-checkbox>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <!-- FILTER BY MEAL TIME -->
                  <v-expansion-panel v-if="nutritionTags.length">
                    <v-expansion-panel-header class="font-weight-bold">
                      Filter by Meal Time
                      <v-badge
                        v-if="
                          tagsFilter?.mealTimes && tagsFilter.mealTimes.length
                        "
                        color="red"
                        :content="tagsFilter.mealTimes.length"
                        :style="{ flex: 'none' }"
                        offset-x="-8"
                        offset-y="10"
                      >
                      </v-badge>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-checkbox
                        v-model="tagsFilter.mealTimes"
                        v-for="(time, id) in mealTimes"
                        class="mt-1 caption"
                        :label="time"
                        :value="time"
                        :key="id"
                        hide-details
                        multiple
                        dense
                      ></v-checkbox>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <div class="d-flex align-center justify-space-between">
                  <div class="pa-3">
                    <Tags
                      :customModel="user"
                      :width="'300'"
                      :hideTags="true"
                      tagViewProp="ingredients"
                    />
                  </div>
                </div>
              </v-card>
              <v-sheet color="transparent" v-if="view == 'all' || view == 'pending'">
                <v-btn-toggle color="primary" mandatory class="my-5">
                  <v-btn @click="roleView = 'dietitian'" x-small
                    >Dietitians<span
                      v-if="selectedDietitian"
                      class="custom-badge"
                      >1</span
                    ></v-btn
                  >
                  <v-btn @click="roleView = 'nutritionist'" x-small
                    >Nutritionists<span
                      v-if="selectedNutritionist"
                      class="custom-badge"
                      >1</span
                    ></v-btn
                  >
                  <v-btn @click="roleView = 'naturopath'" x-small
                    >Naturopaths<span
                      v-if="selectedNaturopath"
                      class="custom-badge"
                      >1</span
                    ></v-btn
                  >
                </v-btn-toggle>

                <v-combobox
                  v-show="roleView == 'dietitian'"
                  dense
                  label="Filter by Dietitians"
                  outlined
                  clearable
                  :loading="status.gettingRecipesByDietitian"
                  v-model="selectedDietitian"
                  :items="confDietitians"
                >
                </v-combobox>
                <v-combobox
                  v-show="roleView == 'nutritionist'"
                  dense
                  label="Filter by Nutritionists"
                  outlined
                  clearable
                  v-model="selectedNutritionist"
                  :items="confNutritionists"
                >
                </v-combobox>
                <v-combobox
                  v-show="roleView == 'naturopath'"
                  dense
                  label="Filter by Naturopaths"
                  outlined
                  clearable
                  v-model="selectedNaturopath"
                  :items="confNaturopaths"
                >
                </v-combobox>
              </v-sheet>
              <v-checkbox label="Sort by Energy" v-model="orderByEnergy" dense>
              </v-checkbox>
              <v-radio-group dense class="mt-0 pt-0" row v-model="sort">
                <v-radio label="Ascending" value="asc"> </v-radio>
                <v-radio label="Descending" value="desc"> </v-radio>
              </v-radio-group>
            </v-card>
          </v-col>

          <v-col class="fill-height" cols="9">
            <v-card class="overflow-y-hidden overflow-x-hidden" flat>
              <v-row class="my-3 mx-2 justify-center">
                <v-btn
                  v-for="(stat, i) in [
                    { stat: 'all', text: 'All Recipes' },
                    { stat: 'published', text: 'My Community Recipes' },
                    { stat: 'my', text: 'My local Recipes' },
                    { stat: 'pending', text: 'Pending', count: pendings },
                    { stat: 'saved', text: 'Saved' },
                    { stat: 'declined', text: 'Declined', count: declined },
                    { stat: 'error', text: 'Errors', count: errors },
                  ]"
                  :color="view == stat.stat ? 'primary' : 'grey'"
                  class="mr-2 text-none text-capitalize"
                  :outlined="view !== stat.stat"
                  @click="setView(stat.stat)"
                  :class="{
                    'd-none':
                      (stat.stat == 'declined' || stat.stat == 'error') &&
                      user.role != 'admin',
                    'v-hidden':
                      (stat.stat == 'published' || stat.stat == 'saved') &&
                      user.role == 'admin',
                    'v-hidden':
                      (stat.stat == 'published' ||
                        stat.stat == 'requested' ||
                        stat.stat == 'pending') &&
                      (user.role == 'personal_trainer' ||
                        user.role == 'member'),
                  }"
                  depressed
                  :key="i"
                  small
                >
                  <span v-if="stat.stat == 'saved' && user.role == 'member'"
                    >Favourites</span
                  >
                  <span v-else>{{ stat.text }}</span>
                  <v-icon
                    v-if="stat.count && stat.count.length"
                    :color="view == stat.stat ? 'white' : 'error'"
                    small
                    right
                    >mdi-alert-circle-outline</v-icon
                  >
                </v-btn>
              </v-row>
              <preloader v-if="recipesStatus.getting" />
              <template v-if="!recipesStatus.getting && confRecipes.length">
                <v-row dense class="mb-3">
                  <v-col
                    v-for="recipe in confRecipes"
                    :key="recipe.id"
                    cols="4"
                  >
                    <RecipeCard
                      @showCard="$emit('showCard', $event)"
                      @selected="insertMeal"
                      :recipe="recipe"
                      :cell="cell"
                      :view="view"
                    />
                  </v-col>
                </v-row>
              </template>

              <template v-if="!recipesStatus.getting && !confRecipes.length">
                <v-alert type="info" border="left" class="mb-0" dense>
                  No recipes found
                </v-alert>
              </template>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="blue-grey lighten-5">
        <div>
          <v-btn
            @click="loadMore()"
            :loading="status.loadingMore"
            v-if="checkNumberOfRecipes()"
            class="mr-2"
            color="secondary"
            depressed
            small
            >Load More</v-btn
          >

          <span class="caption"
            >showing {{ confRecipes.length }}
            {{ pluralize('recipes', confRecipes.length) }}</span
          >
        </div>
      </v-card-actions>
    </v-card>
    <v-dialog
      class="overflow-visible"
      no-click-animation
      max-width="400"
      persistent
      :value="false"
      v-model="showConfirmDialog"
    >
      <v-card>
        <v-card-title>
          <v-icon left>mdi-alert</v-icon>
          <span class="font-weight-bold">Please Confirm</span>
        </v-card-title>
        <v-card-text>
          This may result in a lower or higher than expected average energy
          (kJ/Cal)
          <div class="mt-5 d-flex" style="gap: 1rem">
            <v-btn
              :loading="loadingAllRecipes"
              small
              color="info"
              @click="showAllRecipes()"
            >
              Confirm
            </v-btn>
            <v-btn small color="secondary" @click="closeAllRecipeDialog()">
              Cancel
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ALL RECIPES IDs -->
    <AllRecipeIdsDialog ref="allrecipedialog" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { orderBy, intersection, capitalize } from 'lodash';
import pluralize from 'pluralize';

import RecipeCard from '../recipe/components/RecipeCardSmall2.vue';
import AllRecipeIdsDialog from '../../components/AllRecipeIdsDialog.vue';

import Tags from '../meals/components/Tags.vue';

export default {
  props: {
    cell: Object,
    show: Boolean,
  },
  name: 'Recipes',

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      pluralize,
      sort: 'desc',
      view: 'all',
      roleView: 'dietitian',
      search: null,
      pageSize: 50,
      dialog: false,
      pageNumber: 1,
      getting: false,
      orderByEnergy: false,
      selectedDietitian: '',
      allRecipeCheckbox: false,
      showConfirmDialog: false,
      showAllRecipesBol: false,
      loadingAllRecipes: false,
      selectedNaturopath: '',
      selectedNutritionist: '',
      mealTimes: ['Breakfast', 'Lunch', 'Dinner', 'Dessert', 'Snack'],
    };
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Tags,
    RecipeCard,
    AllRecipeIdsDialog,
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      hits: (state) => state.recipes.hits,
      errors: (state) => state.recipes.errors,
      status: (state) => state.recipes.status,
      recipes: (state) => state.recipes.recipes,
      pendings: (state) => state.recipes.pendings,
      declined: (state) => state.recipes.declined,
      searched: (state) => state.recipes.searched,
      requested: (state) => state.recipes.requested,
      myRecipes: (state) => state.recipes.myRecipes,
      dietitians: (state) => state.members.members,
      recipesStatus: (state) => state.recipes.status,
      allRecipes: (state) => state.recipes.allRecipes,
      naturopaths: (state) => state.members.naturopaths,
      savedRecipes: (state) => state.recipes.savedRecipes,
      nutritionists: (state) => state.members.nutritionists,
      recipesByDietitan: (state) => state.recipes.recipesByDietitian,
    }),

    confRecipes: function () {
      let recipes;

      if (this.view == 'all') {
        if (this.hits.length) {
          recipes = this.hits.filter((h) => h.status == 'published');
        } else recipes = orderBy(this.allRecipes, 'name', this.sort);
      }
      if (this.view == 'published') {
        if (this.hits.length) {
          recipes = this.hits.filter((h) => h.status == 'published');
        } else recipes = orderBy(this.recipes, 'name', this.sort);
      } else if (this.view == 'my') {
        if (this.hits.length) {
          recipes = this.hits.filter(
            (h) => h.status == 'published' && h.isPrivate == true
          );
        } else recipes = orderBy(this.myRecipes, 'name', this.sort);
      } else if (this.view == 'pending') {
        if (this.hits.length) {
          recipes = this.hits.filter((h) => h.status == 'pending');
        } else recipes = orderBy(this.pendings, 'createdAt', this.sort);
      } else if (this.view == 'requested') {
        if (this.hits.length) {
          recipes = this.hits.filter((h) => h.status == 'requested');
        } else recipes = orderBy(this.requested, 'updatedAt', this.sort);
      } else if (this.view == 'declined') {
        if (this.hits.length) {
          recipes = this.hits.filter((h) => h.status == 'declined');
        } else recipes = orderBy(this.declined, 'updatedAt', this.sort);
      } else if (this.view == 'saved') {
        if (this.hits.length) {
          recipes = this.hits.filter(
            (h) =>
              h.status == 'published' && this.user.savedRecipes.includes(h.id)
          );
        } else recipes = orderBy(this.savedRecipes, 'updatedAt', this.sort);
      } else if (this.view == 'error') {
        recipes = this.errors;
      }

      // FILTER BY DIETITIAN
      if (this.selectedDietitian && (this.view === 'all' || this.view === 'pending')) {
        recipes = recipes.filter((recipe) => {
          return recipe.user === this.getDietitianId(this.selectedDietitian);
        });
      }

      // FILTER BY NUTRITIONIST
      if (this.selectedNutritionist && ((this.view === 'all' || this.view === 'pending'))) {
        recipes = recipes.filter((recipe) => {
          return (
            recipe.user === this.getNutritionistId(this.selectedNutritionist)
          );
        });
      }

      // FILTER BY NATUROPATHS
      if (this.selectedNaturopath && (this.view === 'all' || this.view === 'pending')) {
        recipes = recipes.filter((recipe) => {
          return recipe.user === this.getNaturopathId(this.selectedNaturopath);
        });
      }

      // NUTRTION TAGS FILTER
      if (
        this.tagsFilter &&
        this.tagsFilter.nutritionTags &&
        this.tagsFilter.nutritionTags.length
      ) {
        recipes = recipes.filter((recipe) => {
          let count = intersection(
            recipe.nutritionTags,
            this.tagsFilter.nutritionTags
          ).length;
          return count == this.tagsFilter.nutritionTags.length;
        });
      }

      // MEAL TAGS FILTER
      if (
        this.tagsFilter &&
        this.tagsFilter.mealTags &&
        this.tagsFilter.mealTags.length
      ) {
        recipes = recipes.filter((recipe) => {
          let count = intersection(
            recipe.tags,
            this.tagsFilter.mealTags
          ).length;
          return count == this.tagsFilter.mealTags.length;
        });
      }

      // MEAL TIME FILTER
      if (
        this.tagsFilter &&
        this.tagsFilter.mealTimes &&
        this.tagsFilter.mealTimes.length
      ) {
        recipes = recipes.filter((recipe) => {
          let count = intersection(
            recipe.mealTime,
            this.tagsFilter.mealTimes
          ).length;
          return count == this.tagsFilter.mealTimes.length;
        });
      }

      // INGREDIENTS
      if (
        this.tagsFilter &&
        this.tagsFilter.ingredientTags &&
        this.tagsFilter.ingredientTags.length
      ) {
        recipes = recipes.filter((recipe) => {
          let excluded = false;

          this.tagsFilter.ingredientTags.forEach((tag) => {
            if (recipe.name.toLowerCase().includes(tag.toLowerCase()))
              excluded = true;
          });

          let ingredientIds =
            this.$store.state.recipes.recipeIngredientIds.find(
              (r) => r.recipe == recipe.id
            );

          if (ingredientIds) {
            ingredientIds.ids.forEach((id) => {
              let ingredient = this.$store.state.ingredients.ingredients.find(
                (i) => i.id == id
              );

              if (ingredient) {
                this.tagsFilter.ingredientTags.forEach((tag) => {
                  console.log(ingredient.name);
                  if (ingredient.name.toLowerCase().includes(tag.toLowerCase()))
                    excluded = true;
                });
              }
            });
          } else {
            this.tagsFilter.ingredientTags.forEach((tag) => {
              recipe.ingredients.forEach((ing) => {
                if (ing.food.toLowerCase().includes(tag.toLowerCase()))
                  excluded = true;
              });
            });
          }

          return !excluded;
        });
      }

      // Attach energy value for sorting
      recipes = recipes.map((recipe) => ({
        ...recipe,
        energy: recipe.servingValues.energy,
      }));

      // return orderBy(recipes, this.orderByEnergy ? 'energy' : 'name', this.sort)

      if (this.orderByEnergy) recipes = orderBy(recipes, 'energy', this.sort);

      // return recipes.slice(0, this.pageNumber * this.pageSize);

      return recipes
    },

    confDietitians: function () {
      if (!this.dietitians.length) return [];

      return this.dietitians
        .filter((d) => d.role == 'dietitian')
        .map((dietitian) => `${dietitian.firstName} ${dietitian.lastName}`)
        .sort();
    },

    confNutritionists: function () {
      if (!this.nutritionists.length) return [];

      return this.nutritionists
        .map(
          (nutritionist) => `${nutritionist.firstName} ${nutritionist.lastName}`
        )
        .sort();
    },

    confNaturopaths: function () {
      if (!this.naturopaths.length) return [];

      return this.naturopaths
        .map((naturopath) => `${naturopath.firstName} ${naturopath.lastName}`)
        .sort();
    },

    nutritionTags: function () {
      return this.$store.getters['nutritiontags/tags'] || [];
    },

    mealTags: function () {
      return this.$store.getters['tags/tags'] || [];
    },
    currentPageCount: function () {
      return Math.floor(this.confRecipes.length / 50);
    },

    tagsFilter: function () {
      const user = this.user;
      return user;
    },
  },
  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    allRecipeCheckbox: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal) this.showConfirmDialog = true;
        else {
          this.showConfirmDialog = false;
          this.showAllRecipesBol = false;
        }
      }
    },

    view: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.search = null;
        this.$store.commit('recipes/clearSearch');
      }
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('nutritiontags', ['getTags']),

    ...mapActions('recipes', [
      'getRecipesByDietitian',
      'getRequestedRecipes',
      'getDeclinedRecipes',
      'getPendingRecipes',
      'getSavedRecipes',
      'searchMyRecipes',
      'getErrorRecipes',
      'updateToPending',
      'deleteMyRecipe',
      'searchRecipes',
      'getAllRecipes',
      'getMyRecipes',
      'deleteRecipe',
      'getRecipes',
      'getAllIds',
    ]),

    ...mapActions('members', [
      'getAllDietitians',
      'getAllNaturopaths',
      'getAllNutritionists',
    ]),

    close() {
      this.dialog = false;
      this.$store.commit('mealplans/setData', {});
    },

    insertMeal(recipe) {
      let data = {
        cell: this.cell,
        recipe,
      };

      Promise.resolve(this.$store.dispatch('mealplans/insertMeal', data)).then(
        () => {
          this.close();
        }
      );
    },

    closeDialog() {
      this.dialog = false;
    },

    showDialog() {
      this.$store.commit('recipes/setLastVisible', null);

      setTimeout(() => {
        if (this.cell.meal == 'desert') this.cell.meal = 'dessert';
        this.getRecipes(capitalize(this.cell.meal));
        this.getMyRecipes();
        this.dialog = true;
      }, 500);
    },
    closeAllRecipeDialog() {
      this.showConfirmDialog = false;
      this.allRecipeCheckbox = false;
    },
    showAllRecipes() {
      this.loadingAllRecipes = true;
      this.showAllRecipesBol = true;

      setTimeout(() => {
        this.loadingAllRecipes = false;
        this.showConfirmDialog = false;
      }, 2000);
    },
    loadMoreRecipes() {
      if (this.currentPageCount <= this.pageNumber) this.pageNumber++;
    },
    loadMore () {
      if(this.view == 'all')  
        this.getAllRecipes() 
      else if (this.view == 'pending')
        this.getPendingRecipes()
      else if (this.view == 'requested')
        this.getRequestedRecipes()
      else if (this.view == 'declined')
        this.getDeclinedRecipes()
    },
    getDietitianId(name) {
      const found = this.dietitians.find((dietitian) => {
        return `${dietitian.firstName} ${dietitian.lastName}`
          .toLowerCase()
          .includes(name.toLowerCase());
      });

      return found.id;
    },
    getNutritionistId(name) {
      const found = this.nutritionists.find((nutritionist) => {
        return `${nutritionist.firstName} ${nutritionist.lastName}`
          .toLowerCase()
          .includes(name.toLowerCase());
      });

      return found.id;
    },
    getNaturopathId(name) {
      const found = this.naturopaths.find((naturopath) => {
        return `${naturopath.firstName} ${naturopath.lastName}`
          .toLowerCase()
          .includes(name.toLowerCase());
      });

      return found.id;
    },
    setView(view) {
      this.view = view;
      !this.$route.query.view || this.$route.query.view !== view
        ? this.$router.push({ query: { view } })
        : '';
    },

    searchAll(s) {
      if (this.view == 'my') this.searchMyRecipes(s);
      else this.searchRecipes(s);
    },
    openAllRecipeIdsDialog() {
      this.$refs.allrecipedialog.openDialog();
    },

    checkNumberOfRecipes() {
      let numberOfRecipes

      switch(this.view) {
        case 'all':
          numberOfRecipes = this.allRecipes.length
          break;
        case 'my':
          numberOfRecipes = this.myRecipes.length
          break;
        case 'pending':
          numberOfRecipes = this.pendings.length
          break;
        case 'requested':
          numberOfRecipes = this.requested.length
          break;
        case 'declined':
          numberOfRecipes = this.declined.length
          break;
        default:
          numberOfRecipes = 0
      }

      return numberOfRecipes >= 20
    }

  },
  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  created() {
    this.$store.commit('recipes/resetState');
    this.$store.commit('recipes/updateStatus', { limit: 20 })
    if (this.$route.query.view) this.view = this.$route.query.view;
    // if (!this.status.firstLoad) this.getRecipes();
    if (!this.status.firstLoadErrors) this.getErrorRecipes();
    this.$store.commit('recipes/clearSearch');
    this.getPendingRecipes();
    this.getRequestedRecipes();
    this.getDeclinedRecipes();
    this.getMyRecipes();
    this.getAllRecipes();
    this.getAllNutritionists();
    this.getAllNaturopaths();

    if (this.user.role != 'admin' && this.user) {
      this.getSavedRecipes(this.user);
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.$store.getters['subscriptions/subscribed']) {
      this.$root.$emit('showSubscribeDialog');
      return;
    }
    if (!this.$store.state.tags.status.firstLoad)
      this.$store.dispatch('tags/getTags');
    if (!this.$store.state.nutritiontags.status.firstLoad)
      this.$store.dispatch('nutritiontags/getTags');
  },
};
</script>

<style lang="scss">
.v-hidden {
  display: none;
}

.align-start {
  .v-select__slot {
    align-items: flex-start;
  }
}

.custom-badge {
  background-color: #f44336;
  color: #fff;
  border-radius: 50%;
  font-size: 10px;
  margin-left: 5px;
  line-height: 1.4;
  height: 14px;
  width: 14px;
}
</style>
