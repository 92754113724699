<template>
  <v-container fluid>
    <v-card class="box-shadow" outlined>
      <v-card-title :class="$classes.cardTitle">
        <span class="font-weight-bold">Plans &amp; Pricing</span>

        <v-menu bottom left offset-y transition="slide-x-transition">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              small
              icon
            >
              <v-icon small>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item @click="showDialog()">
              <v-list-item-icon class="mr-2"><v-icon small>mdi-plus-circle</v-icon></v-list-item-icon>
              <v-list-item-title>Add</v-list-item-title>
            </v-list-item>
            <v-list-item @click="getPlans()">
              <v-list-item-icon class="mr-2"><v-icon small>mdi-refresh</v-icon></v-list-item-icon>
              <v-list-item-title>Refresh List</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text v-if="!status.gettingPlans && !plans.length" class="pa-7">
        <v-alert type="info" class="mb-0" border="left">
          You don't have any membership plans yet. <v-btn @click="showDialog()" small class="ml-3">Add New</v-btn>
        </v-alert>
      </v-card-text>


      <preloader v-if="status.gettingPlans" />

      <v-simple-table v-if="!status.gettingPlans && sorted.length" dense>
        <template slot:default>
            <thead>
              <tr>
                <th>Name</th>
                <th>Amount</th>
                <th>Roles</th>
                <th class="text-center">Meals</th>
                <th class="text-center">Download Limit</th>
                <th>Type</th>
                <th>Active</th>
                <th></th>
              </tr>
            </thead>
            <draggable v-model="sorted" tag="tbody" @end="sortPlans(sorted)">
              <tr v-for="plan in sorted" :key="plan.id" class="item">
                <td>
                  <v-icon color="grey lighten-1" left small>mdi-arrow-up-down-bold</v-icon>
                  <span>{{ plan.title }}</span> 
                </td>
                <td class="caption grey--text">
                  <span v-if="plan.role">{{ plan.role.map(role => startCase(role)).join(", ") }}</span>
                </td>
                <td class="caption grey--text">
                  ${{ ($store.getters['pricing/price'](plan.subscription).unit_amount/100) | numberFormat }}

                  <span v-if="$store.getters['pricing/price'](plan.subscription).type == 'recurring'">
                    every {{ $store.getters['pricing/price'](plan.subscription).interval_count }} {{ $store.getters['pricing/price'](plan.subscription).interval }}
                  </span>
                </td>
                <td class="text-center caption grey--text">{{ plan.meals == '-1' ? 'unlimited' : plan.meals }} </td>
                <td class="text-center caption grey--text">{{ plan.downloads == '-1' ? 'unlimited' : plan.downloads }} </td>
                <td class="caption grey--text">
                  {{ startCase($store.getters['pricing/price'](plan.subscription).type) }}
                </td>
                <td>
                  <v-switch
                    @change="updatePlanStatus(plan)"
                    v-model="plan.active"
                    class="ma-0"
                    hide-details
                    dense
                  ></v-switch>
                </td>
                <td width="10">
                  <v-menu bottom left offset-y transition="slide-x-transition">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        small
                      >
                        <v-icon small>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list dense>
                      <v-list-item @click="editPlan(plan)">
                        <v-list-item-icon class="mr-2"><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
                        <v-list-item-title>Edit</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item @click="deletePlan(plan)">
                        <v-list-item-icon class="mr-2"><v-icon color="red" small>mdi-delete</v-icon></v-list-item-icon>
                        <v-list-item-title> <span class="red--text">Delete</span> </v-list-item-title>
                      </v-list-item>
                    </v-list>

                  </v-menu>
                </td>
              </tr>
            </draggable>
        </template>
      </v-simple-table>
    </v-card>

    <!-- ADD PRICING DIALOG -->
    <AddPlanDialog />

    <!-- CONFIRM DELETE -->
    <ConfirmDelete
      :showDialog="confirmDialog"
      :message="deleteMessage"
      :deleting="status.deleting"
      @cancel="cancelDelete()"
      @confirmed="deleteConfirmed()"
    />
  </v-container>
</template>

<script>

import { startCase } from 'lodash'
import draggable from 'vuedraggable'
import { mapState, mapActions } from 'vuex'

import AddPlanDialog from './components/AddPlanDialog'

export default {
  name: 'PlansAndPricing',

  metaInfo: {
    title: 'Plans & Pricing'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      startCase,
      toDelete: null,
      deleteMessage: null,
      confirmDialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    AddPlanDialog,
    draggable,
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      plans: state => state.pricing.plans,
      status: state => state.pricing.status,
    }),

    sorted: {
      get: function () {
        return this.plans
      },

      set: function (newValue) {
        this.$store.commit('pricing/reorderPlans', newValue)
      }
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('pricing', [
      'updatePlanStatus',
      'getSubscriptions',
      'sortPlans',
      'getPlans',
      'delete',
    ]),

    showDialog() {
      this.$store.commit('pricing/updateStatus', { dialog: true })
    },

    deletePlan(plan) {
      this.confirmDialog = true
      this.toDelete = plan
      this.deleteMessage = `Are you sure you want to delete "${plan.title}" plan?`
    },

    cancelDelete() {
      this.confirmDialog = false
      this.toDelete = null
      this.deleteMessage = null
    },

    deleteConfirmed() {
      Promise.resolve(this.delete(this.toDelete))
      .then(() => {
          this.confirmDialog = false
      })
    },

    editPlan(plan) {
      this.$store.commit('pricing/setData', plan)
      this.showDialog()
    },
  },

  created() {
    this.getPlans()
    this.getSubscriptions()
  }
}

</script>
