<template>
  <div>
    <v-chip
      v-if="!getting"
      :color="!active ? 'default' : 'primary'"
      outlined
      small
    >{{ active ? active.priceData.description : 'none' }}</v-chip>
    <preloader v-if="getting" text="checking..." />
  </div>
</template>

<script>
import db from '@/firebase/init'
import moment from 'moment'

export default {
  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    user: {
      type: Object,
      required: true
    }
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      subscriptions: [],
      getting: true,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    active: function () {
      let active = null
      let current = Date.now()
      
      this.subscriptions.forEach(sub => {
        if (sub.status == 'active') {
          active = sub
        }
        else if (sub.status == 'canceled') {
          let end = new Date(sub.current_period_end.seconds * 1000)
          end = moment(end).add(1, 'day')
          
          if (end.valueOf() >= current) {
            active = sub
          }
        }
      })

      return active
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    getSubscriptions() {
      db.collection('customers')
      .doc(this.user.id)
      .collection('subscriptions')
      .get()
      .then((snapshot) => {
        if (snapshot.size) {
          snapshot.forEach((doc) => {
            let sub = this.$formatData(doc)

            doc.data().price.get()
            .then((price) => {
              sub.priceData = price.data()
              this.subscriptions.push(sub)
              this.getting = false
            })
          })
        }
        else {
          this.subscription = []
          this.getting = false
        }
      })
      .catch(error => {
        console.log(error.message)
        this.getting = false
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getSubscriptions()
  }
}
</script>