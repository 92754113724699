<template>
  <div>
    <v-btn @click="startImport()" :loading="uploading" color="primary">Start Import</v-btn>

    <div v-if="uploaded.length" class="mt-5">
      <div class="caption" v-for="(u, i) in uploaded" :key="i">
        {{ i + 1 }}. {{ u }}
      </div>
    </div>
  </div>
</template>

<script>
// import { jsonp } from 'vue-jsonp'
import { uniq } from 'lodash'
import firebase from 'firebase'
import db from '@/firebase/init'
import recipes from '@/json/sample-recipe-modified.json'

export default {
  data() {
    return {
      recipes: [],
      uploaded: [],
      uploading: false,
    }
  },

  methods: {
    async startImport() {
      // var selectedRecipes = take(recipes, 1)
      var selectedRecipes = uniq(recipes)
      this.uploading = true

      for (var recipe of selectedRecipes) {
        let mealTime = []
        let ingredients = []

        if (recipe.mealtimes.includes('L')) mealTime.push('Lunch')
        if (recipe.mealtimes.includes('D')) mealTime.push('Dinner')
        if (recipe.mealtimes.includes('B')) mealTime.push('Breakfast')
        if (recipe.mealtimes.includes('S')) mealTime.push('Dessert')
        if (recipe.mealtimes.includes('A')) mealTime.push('Snack')
        if (recipe.mealtimes.includes('M')) mealTime.push('Snack')

        if (recipe.recipe_food && recipe.recipe_food.length) {
          recipe.recipe_food.forEach((food) => {
            ingredients.push(`${food.quantity} ${food.unit} ${food.food_label}`)
          })
        }

        let data = {
          mealTime,
          status: 'pending',
          name: recipe.name,
          methods: [recipe.instructions],
          description: `${recipe.image} ${ingredients.join(", ")}`,
          user: 'G4xPRelK4ZbJTmLjOKjjBF0XgyU2',
          createdAt: firebase.firestore.Timestamp.now(),
          updatedAt: firebase.firestore.Timestamp.now(),
        }

        let image = recipe.image.split('/')
        
        await fetch(`${window.origin}/Photos/${image[image.length - 1]}`)
        .then(res => res.blob())
        .then(async (blob) => {
          await Promise.resolve(
            this.$store.dispatch('file_upload/uploadFile', {
              file: new File([blob], recipe.image, { type: blob.type }),
              folder: 'recipes'
            })
          )
          .then(async (file) => {
            data.photo = file.file

            await db.collection('recipes')
            .add(data)
            .then(() => {
              this.uploaded.push(data.name)
            })
            .catch(error => {
              console.log(error.message)
            })
          })
        })
        
      }

      this.uploading = false
    },
  },
}
</script>