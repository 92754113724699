<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
  >
    <close-button @click="closeDialog()" overflow />

    <v-card v-if="user">
      <v-card-title>
        Update "{{ user.firstName }} {{ user.lasttName }}" credits
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model.number="user.mealSlots"
          label="Meals"
          type="number"
          outlined
        ></v-text-field>
        <v-text-field
          v-model.number="user.downloadCredits"
          label="Download Credits"
          type="number"
          outlined
        ></v-text-field>

        <div class="d-flex gap-5 align-center">
          <v-btn @click="updateCredits()" :loading="updating" color="primary">Update</v-btn>
          <v-btn @click="closeDialog()" :disabled="updating" text>Cancel</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    user: Object,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      dialog: false,
      updating: false,
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    showDialog() {
      this.dialog = true
    },

    closeDialog() {
      this.dialog = false
    },

    updateCredits() {
      this.updating = true

      this.user.ref
      .update({
        mealSlots: this.user.mealSlots || 0,
        downloadCredits: this.user.downloadCredits || 0,
      })
      .then(() => {
        this.$store.dispatch('showSuccess', 'Credits updated')
      })
      .catch(error => {
        console.log(error.message)
        this.$store.dispatch('showError', error.message)
      })
      .finally(() => {
        this.closeDialog()
        this.updating = false
      })
    }
  }
}
</script>