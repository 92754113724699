<template>
  <div>
    <v-alert v-if="user.status == 'pending'" type="warning" border="left" text>
      Your account is currently pending for approval.
    </v-alert>

    <v-card v-if="user.status == 'active'"  outlined>
      <v-card-title :class="$classes.cardTitle">
        <div class="d-flex justify-space-between" style="width:100%">
          <span class="font-weight-bold title mr-5">{{  user.role == 'admin' ? 'Private Recipes' : 'My Recipes' }}</span>
          <div>
            <v-btn :to="{ name: 'AddRecipe' }" color="primary" class="mx-2 mb-1" small depressed>Add New</v-btn>
            <v-btn @click="refreshList()" :loading="status.getting" icon>
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="d-flex flex-column align-end flex-md-row flex-lg-row align-md-center align-lg-center justify-lg-space-between" style="width:100%; gap: 0.5rem">
          <v-text-field
            v-model="search"
            @keydown.enter="searchMyRecipes(search)"
            @click:clear="$store.commit('recipes/clearSearch')"
            placeholder="Search Recipe"
            style="max-width: 1000px;width: 100%"
            class="white "
            hide-details
            clearable
            rounded
            dense
            outlined
          >
            <template #append>
              <v-btn :loading="status.searching" class="mr-n2" icon small disabled>
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </div>
      </v-card-title>
      <v-card-text class="pa-5 d-flex flex-wrap gap-10">
        <template  v-if="!status.getting && confRecipes.length">
          <div v-if="view !== 'reports'" class="d-flex justify-center flex-wrap gap-10">
            <v-card 
              v-for="recipe in confRecipes" 
              :key="recipe.id" 
              :to="{ name: 'EditRecipe', params: { id: recipe.id } }"
              max-width="240" 
              width="100%"
              outlined
              hover
            >
              <RecipeImage :recipe="recipe" size="medium" />
              <todos-chip @showRecipe="showRecipeCard(recipe)" :recipe="recipe"/>
              
              <v-card-text>
                <div class="float-right recipe-actions">
                  <v-menu bottom left offset-y transition="slide-x-transition">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click.prevent
                        v-on="on"
                        icon
                        small
                      >
                        <v-icon small>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list min-width="120">
                      <v-list-item dense @click="showRecipeCard(recipe)">
                        <v-list-item-icon class="mr-2"><v-icon small>mdi-card-bulleted-outline</v-icon></v-list-item-icon>
                        <v-list-item-title>View recipe card</v-list-item-title>
                      </v-list-item>
                      
                      <v-list-item dense :to="{ name: 'EditRecipe', params: { id: recipe.id } }">
                        <v-list-item-icon class="mr-2"><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
                        <v-list-item-title>Edit</v-list-item-title>
                      </v-list-item>
                      
                      <v-list-item @click="duplicateRecipe(recipe)" dense>
                        <v-list-item-icon class="mr-2"><v-icon small>mdi-content-copy</v-icon></v-list-item-icon>
                        <v-list-item-title>{{  user.role == 'admin' ? 'Add to Community' : 'Duplicate' }}</v-list-item-title>
                      </v-list-item>
                      
                      <v-divider></v-divider>
                      
                      <v-list-item dense @click="setDeleteData(recipe)">
                        <v-list-item-icon class="mr-2"><v-icon color="red" small>mdi-delete</v-icon></v-list-item-icon>
                        <v-list-item-title color="red"> <span class="red--text">Delete</span> </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <h4 class="primary--text">{{ recipe.name }}</h4>
                <div v-if="user.role == 'admin'" class="caption">
                  {{ getUser(recipe.user) }}
                </div>
                <div v-else class="caption">
                    You
                </div>

                <div class="ml-n1 mt-1 d-flex align-center justify-space-between">

                  <v-menu 
                    v-if="recipe.errors && recipe.errors.length && user.role == 'admin'" 
                    :close-on-content-click="false"
                    offset-y
                  >
                    <template #activator="{ on }">
                      <v-btn @click.prevent v-on="on" small icon>
                        <v-icon color="warning" small>mdi-alert</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item
                        v-for="(error, i) in recipe.errors"
                        :key="i"
                      >
                        <v-list-item-action class="mr-2">
                          <v-checkbox @change="deleteRecipeError({ action: $event, error, recipe })"  dense hide-details></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-title>{{ error }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </template>

        <preloader v-if="status.getting"/>

        <v-alert class="flex-grow-1" type="info" border="left" v-if="!status.getting && !confRecipes.length" text>
          No results found.
          
          <v-btn 
            :to="{ name: 'AddRecipe' }"
            color="dark" 
            class="ml-3" 
            absolute
            right
            small
          >Add New</v-btn>
        </v-alert>
      </v-card-text>
      <v-card-actions class="blue-grey lighten-5">
        <div>
          <v-btn
            @click="view == 'published' ? getMyRecipes() : getPendingRecipes()"
            :loading="status.loadingMore"
            v-if="confRecipes.length >= 20"
            class="mr-2"
            color="secondary"
            depressed
            small
          >Load More</v-btn>
          
          <span class="caption">showing {{ confRecipes.length }} {{ pluralize('recipe', confRecipes.length) }}</span>
        </div>
      </v-card-actions>
    </v-card>  

    <ConfirmDelete
      :showDialog="deleteDialog"
      :message="deleteMessage"
      :deleting="status.deleting"
      @cancel="deleteDialog = false"
      @confirmed="deleteConfirmed()"
    />

    <RecipeCard
      v-if="dialogRecipe"
      :recipe="dialogRecipe"
      :show="showCard"
      @cancel="closeRecipeCard()"
    />

  </div>
</template>

<script>

import _ from 'lodash'
import pluralize from 'pluralize'
import RecipeCard from '../recipe/components/RecipeCard'
import RecipeImage from '@/components/RecipeImage'
import { mapState, mapGetters, mapActions } from 'vuex'
import TodosChip from '@/views/recipe/components/TodosChip'

export default {
  name: 'Recipes',

  metaInfo: {
    title: 'Recipes'
  },

  components: {
    TodosChip,
    RecipeCard,
    RecipeImage,
  },

  data() {
    return {
      pluralize,
      search: null,
      toDelete: null,
      showCard: false,
      view: 'published',
      dialogRecipe: null,
      deleteDialog: false,
      deleteMessage: null,
      allIdsDialog: false,
    }
  },

  computed: {
    ...mapState({
      user: state => state.user.user,
      hits: state => state.recipes.hits,
      status: state => state.recipes.status,
      errors: state => state.recipes.errors,
      recipes: state => state.recipes.myRecipes,
      searched: state => state.recipes.searched,
      noneExisting: state => state.members.noneExisting,
    }),

    ...mapGetters('members', [
      'canAddRecipeMembers'
    ]),

    confRecipes: function () {
      let recipes 
      
      if (this.view == 'published') {
        if (this.hits.length) {
          recipes = this.hits
        }
        else recipes = _.orderBy(this.recipes, 'name', 'asc')
      } 
      
      return recipes
    }
  },

  watch: {
    'recipes': function (val) {
      if (val.length) {
        this.getUsers()
      }
    }
  },

  methods: {
    ...mapActions('recipe', [
      'duplicateRecipe',
      'deleteRecipeError',
    ]),
    
    ...mapActions('recipes', [
      'updateToPending',
      'getErrorRecipes',
      'searchMyRecipes',
      'deleteMyRecipe',
      'getMyRecipes',
      'getAllIds',
    ]),

    getUser(id) {
        return this.$store.getters['members/getUser'](id)
    },

    getUsers() {
      let ids = []

      this.recipes.forEach(recipe => {
        ids.push(recipe.user)
      })

      if (ids.length) {
        ids.forEach(id => {
          this.$store.dispatch('members/getMember', id)
        })
      }
    },

    setDeleteData(recipe) {
      this.deleteMessage = `Are you sure you want to delete recipe "${recipe.name}"?`
      this.deleteDialog = true
      this.toDelete = recipe
    },

    deleteConfirmed() {
      Promise.resolve(this.deleteMyRecipe(this.toDelete))
      .then(() => {
        this.deleteDialog = false
      })
    },

    showRecipeCard(recipe) {
      this.dialogRecipe = {}
      Object.assign(this.dialogRecipe, recipe)
      this.showCard = true
    },

    closeRecipeCard() {
      this.showCard = false
      this.dialogRecipe = null
    },

    refreshList() {
      this.$store.commit('recipes/resetState')
      this.$store.commit('recipes/setLastVisible', null)
      this.$store.commit('recipes/setFirstLoad', false)
      this.getMyRecipes()
    },

    getErrors(errors) {
      if (errors && errors.length) {
        return errors.join('<br>')
      }
      else return 'None'
    },

  },

  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  created() {
    this.$store.commit('recipes/resetState')
    this.$store.commit('recipes/updateStatus', { limit: 20 })
    if (this.$route.query.view) this.view = this.$route.query.view
    if (!this.status.firstLoad) this.getMyRecipes()
    if (!this.status.firstLoadErrors) this.getErrorRecipes()
    this.$store.commit('recipes/clearSearch')
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/

  mounted() {
    if(!this.$store.getters['subscriptions/subscribed']) {
        this.overlay = false
        this.$root.$emit('showSubscribeDialog')
        return
    }
  }
}

</script>

<style>

.recipe-actions {
  margin-top: -5px;
  margin-right: -5px;
}

</style>
