<template>
  <div class="d-flex">
    <v-rating
      :value="getTotalRatings"
      background-color="grey lighten-1"
      color="orange"
      size="50"
      readonly
      small
      dense
    ></v-rating> <span class="caption ml-2">({{ ratings.length ? ratings.length : 0 }})</span>
  </div>
</template>

<script>

export default {
    name: 'MarketplaceRating',
    props: {
        ratings: {
            type: Array,
            default() {
                return []
            }
        },
        totalRatings: Number
    },
    computed: {
        getTotalRatings () {
            return this.totalRatings ? this.totalRatings : 0
        },
        getTotalUserRated () {
            return this?.ratings.length ? this.ratings.length : 0
        }
    }
}
</script>