<template>
  <div v-if="user">
    <v-autocomplete
      v-if="user.role == 'admin'"
      v-model="selected"
      @keydown.enter="searchMembers($event)"
      placeholder="Search by name or email"
      class="font-weight-normal white"
      style="max-width: 450px"
      :items="users"
      :item-text="(item) => `${item.firstName} ${item.lastName} (${item.email})`"
      return-object
      hide-details
      clearable
      outlined
      rounded
      dense
    >
      <template #append>
        <v-btn
          @click="searchMembers($event)"
          :loading="searching"
          small
          icon 
        >
          <v-icon>mdi-account-search</v-icon>
        </v-btn>
      </template>
    </v-autocomplete>

    <preloader v-if="gettingRecipes" />
    
    <div v-if="!gettingRecipes && recipes.length" class="mt-5">
      <div class="mb-5">{{ recipes.length }} recipes found</div>

      <v-card class="box-shadow" outlined>
        <v-simple-table dense>
          <template #default>
            <thead>
              <tr>
                <th>Recipe</th>
                <th class="text-center">Meals</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="recipe in orderBy(recipes, 'name', 'asc')" :key="recipe.id">
                <td>{{ recipe.name }}</td>
                <td class="text-center">{{ recipeMeals(recipe).length }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="grey lighten-2 font-weight-bold">Total</td>
                <td class="grey lighten-2 text-center font-weight-bold">{{ meals.length }}</td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import db from '@/firebase/init'
import { mapState } from 'vuex'
import { orderBy } from 'lodash'
import { userIndex } from '@/algolia/init'

export default {
  metaInfo: {
    title: 'Recipe Stat'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      orderBy,
      users: [],
      meals: [],
      recipes: [],
      search: null,
      selected: null,
      searching: false,
      gettingRecipes: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'selected': function (selected) {
      if (selected) {
        this.getUserRecipes(selected)
      }
    },

    'user': {
      handler(user) {
        if (user && (user.role == 'dietitian' || user.role == 'nutritionist' || user.role == 'naturopath')) this.getUserRecipes(user)
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    // SEARCH MEMBERS
    searchMembers(e) {
      this.searching = true

      userIndex.search(e.target.value, {
        attributesToRetrieve: ['fullname', 'objectID']
      })
      .then( ({ hits }) => {
        let promises = []
        
        if (hits.length) {
          hits.forEach(hit => {
            promises.push(db.collection('users').doc(hit.objectID).get())
          })

          Promise.all(promises)
          .then((docs) => {
            if (docs.length) {
              docs.forEach(doc => {
                if (doc.exists) {
                  let user = doc.data()
                  user.id = doc.id
                  user.ref = doc.ref
                  this.users.push(user)

                }
              })
            }

            this.searching = false
          })
        }
        else {
          this.$store.dispatch('showError', 'No user found')
          this.searching = false
        }
      })
    },

    // GET RECIPES
    getUserRecipes(selected) {
      this.recipes = []
      this.gettingRecipes = true
      
      db.collection('recipes')
      .where('user', '==', selected.id)
      .where('status', 'in', ['published'])
      .get()
      .then((snapshot) => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            let recipe = doc.data()
            recipe.ref = doc.ref
            recipe.id = doc.id
            this.recipes.push(recipe)
          })

          snapshot.forEach(recipe => {
            this.getMeals(recipe)
          })
        }
      })
      .catch(error => {
        console.log(error.message)
        this.$store.dispatch('showError', 'No recipes created')
      })
      .finally(() => {
        this.gettingRecipes = false
      })
    },

    /*------------------------------------------------------------------------------
     * GET MEALS
     *----------------------------------------------------------------------------*/
    getMeals(recipe) {
      db.collectionGroup('meals')
      .where('recipe', '==', recipe.id)
      .get()
      .then((snapshot) => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            if (doc.exists) {
              let meal = doc.data()
              meal.id = doc.id
              meal.ref = doc.ref
              this.meals.push(meal)
            }
          })
        }
      })
      .catch(error => {
        console.log(error.message)
      })
    },

    /*------------------------------------------------------------------------------
     * RECIPE MEALS
     *----------------------------------------------------------------------------*/
    recipeMeals(recipe) {
      return this.meals.filter(meal => {
        return meal.recipe == recipe.id
      })
    }
  }
}
</script>