<template>
  <div>
    <v-card outlined>
      <v-card-title class="blue-grey lighten-5 justify-space-between">
        <span class="font-weight-bold">Nutrition Tags ({{confTags.length}})</span>

        <v-text-field
          v-model="search"
          placeholder="Search by name"
          append-icon="mdi-magnify"
          style="max-width: 450px"
          class="white"
          hide-details
          clearable
          outlined
          rounded
          dense
        ></v-text-field>

        <v-btn @click="getTags()" :loading="status.getting" icon>
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text 
        v-if="!status.getting && confTags.length"
        class="pa-0"
      >
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Name</th>
                <th>Main Meal Auto Tagging Per Serve</th>
                <th>Snack Meal Auto Tagging Per Serve</th>
                <th>Meal Auto Tagging Per 100g</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="tag in confTags"
                :key="tag.id"
              >
                <td :class="tag.published ? 'primary--text' : 'grey--text'">{{ tag.name }}</td>
                <td>
                  <div class="d-flex align-center gap-10">
                    <v-btn @click="() => tag.switchToBool = !tag.switchToBool" icon small>
                      <v-icon small>mdi-swap-horizontal</v-icon>
                    </v-btn>

                    <div class="d-flex align-center gap-10"
                      v-if="tag.range"
                    >
                      <v-combobox
                        v-model="tag.main"
                        :items="minerals"
                        item-text="name"
                        max-width="250"
                        class="white"
                        hide-details
                        outlined
                        dense
                      ></v-combobox>

                      <v-sheet max-width="70">
                        <v-select
                          v-model="tag.mainCondition"
                          :items="['≥']"
                          max-width="50"
                          hide-details
                          outlined
                          dense
                        ></v-select>
                      </v-sheet>

                      <v-sheet max-width="100">
                        <v-text-field
                          :suffix="tag.main ? tag.main.unit : ''"
                          v-model.number="tag.mainAmount"
                          placeholder="amount"
                          type="number"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-sheet>
                      <v-sheet max-width="70">
                        <v-select
                          v-model="tag.secondCondition"
                          :items="['≤']"
                          max-width="50"
                          hide-details
                          outlined
                          dense
                        ></v-select>
                      </v-sheet>

                      <v-sheet max-width="100">
                        <v-text-field
                          :suffix="tag.main ? tag.main.unit : ''"
                          v-model.number="tag.secondAmount"
                          placeholder="amount"
                          type="number"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-sheet>
                    </div>
                    <div class="d-flex align-center gap-10" v-else-if="!tag.switchToBool">
                      <v-combobox
                        v-model="tag.main"
                        :items="minerals"
                        item-text="name"
                        max-width="250"
                        class="white"
                        hide-details
                        outlined
                        dense
                      ></v-combobox>

                      <v-sheet max-width="70">
                        <v-select
                          v-model="tag.mainCondition"
                          :items="['≤', '≥']"
                          max-width="50"
                          hide-details
                          outlined
                          dense
                        ></v-select>
                      </v-sheet>

                      <v-sheet max-width="100">
                        <v-text-field
                          :suffix="tag.main ? tag.main.unit : ''"
                          v-model.number="tag.mainAmount"
                          placeholder="amount"
                          type="number"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-sheet>
                      <v-btn @click="$store.commit('nutritiontags/clearMainAutoTag', tag)" icon small>
                        <v-icon small>mdi-close-circle-outline</v-icon>
                      </v-btn>
                    </div>
                    <div class="d-flex align-center gap-10" v-else-if="tag.switchToBool">
                      <v-combobox
                        v-model="tag.mainBool"
                        :items="newTags"
                        item-text="name"
                        max-width="250"
                        class="white"
                        hide-details
                        outlined
                        dense
                      ></v-combobox>
                      <v-sheet max-width="120">
                        <v-select
                          v-model="tag.mainBoolVal"
                          :items="['YES', 'NO']"
                          max-width="50"
                          hide-details
                          outlined
                          dense
                        ></v-select>
                      </v-sheet>
                      <v-btn @click="$store.commit('nutritiontags/clearMainBoolTag', tag)" icon small>
                        <v-icon small>mdi-close-circle-outline</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </td>
                <td>
                  <div v-if="!tag.switchToBool && !tag.range" class="d-flex align-center gap-10">
                    <v-combobox
                      v-model="tag.snack"
                      :items="minerals"
                      item-value="name"
                      item-text="name"
                      max-width="250"
                      class="white"
                      hide-details
                      outlined
                      dense
                    ></v-combobox>

                    <v-sheet max-width="70">
                      <v-select
                        v-model="tag.snackCondition"
                        :items="['≤', '≥']"
                        max-width="50"
                        hide-details
                        outlined
                        dense
                      ></v-select>
                    </v-sheet>

                    <v-sheet max-width="100">
                      <v-text-field
                        :suffix="tag.snack ? tag.snack.unit : ''"
                        v-model.number="tag.snackAmount"
                        placeholder="amount"
                        type="number"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-sheet>

                    <v-btn @click="$store.commit('nutritiontags/clearSnackAutoTag', tag)" icon small>
                      <v-icon small>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </div>

                  <div v-if="tag.range" class="d-flex align-center gap-10">
                    <v-combobox
                      v-model="tag.snack"
                      :items="minerals"
                      item-value="name"
                      item-text="name"
                      max-width="250"
                      class="white"
                      hide-details
                      outlined
                      dense
                    ></v-combobox>

                    <v-sheet max-width="70">
                      <v-select
                        v-model="tag.snackCondition"
                        :items="['≥']"
                        max-width="10"
                        hide-details
                        outlined
                        dense
                      ></v-select>
                    </v-sheet>

                    <v-sheet max-width="100">
                      <v-text-field
                        :suffix="tag.snack ? tag.snack.unit : ''"
                        v-model.number="tag.snackAmount"
                        placeholder="amount"
                        type="number"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-sheet>

                    <v-sheet max-width="70">
                      <v-select
                        v-model="tag.snackSecondCondition"
                        :items="['≤']"
                        max-width="50"
                        hide-details
                        outlined
                        dense
                      ></v-select>
                    </v-sheet>

                    <v-sheet max-width="100">
                      <v-text-field
                        :suffix="tag.snack ? tag.snack.unit : ''"
                        v-model.number="tag.snackSecondAmount"
                        placeholder="amount"
                        type="number"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-sheet>
                  </div>
                </td>
                <td>
                  <div v-if="!tag.switchToBool && !tag.range" class="d-flex align-center gap-10">
                    <v-combobox
                      v-model="tag.hundred"
                      :items="minerals"
                      item-value="name"
                      item-text="name"
                      max-width="250"
                      class="white"
                      hide-details
                      outlined
                      dense
                    ></v-combobox>

                    <v-sheet max-width="70">
                      <v-select
                        v-model="tag.hundredCondition"
                        :items="['≤', '≥']"
                        max-width="50"
                        hide-details
                        outlined
                        dense
                      ></v-select>
                    </v-sheet>

                    <v-sheet max-width="100">
                      <v-text-field
                        :suffix="tag.hundred ? tag.hundred.unit : ''"
                        v-model.number="tag.hundredAmount"
                        placeholder="amount"
                        type="number"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-sheet>

                    <v-btn @click="$store.commit('nutritiontags/clearHundredAutoTag', tag)" icon small>
                      <v-icon small>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </div>
                  <div v-if="tag.range" class="d-flex align-center gap-10">
                    <v-combobox
                      v-model="tag.hundred"
                      :items="minerals"
                      item-value="name"
                      item-text="name"
                      max-width="250"
                      class="white"
                      hide-details
                      outlined
                      dense
                    ></v-combobox>

                    <v-sheet max-width="70">
                      <v-select
                        v-model="tag.hundredCondition"
                        :items="['≥']"
                        max-width="50"
                        hide-details
                        outlined
                        dense
                      ></v-select>
                    </v-sheet>

                    <v-sheet max-width="100">
                      <v-text-field
                        :suffix="tag.hundred ? tag.hundred.unit : ''"
                        v-model.number="tag.hundredAmount"
                        placeholder="amount"
                        type="number"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-sheet>

                    <v-sheet max-width="70">
                      <v-select
                        v-model="tag.hundredSecondCondition"
                        :items="['≤']"
                        max-width="50"
                        hide-details
                        outlined
                        dense
                      ></v-select>
                    </v-sheet>

                    <v-sheet max-width="100">
                      <v-text-field
                        :suffix="tag.hundred ? tag.hundred.unit : ''"
                        v-model.number="tag.hundredSecondAmount"
                        placeholder="amount"
                        type="number"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-sheet>

                  </div>
                </td>
                <td class="text-right">
                  <v-menu 
                    transition="slide-x-transition"
                    :close-on-content-click="false"
                    offset-y 
                    bottom 
                    left 
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-n2"
                        icon
                        v-on="on"
                        x-small
                      >
                        <v-icon small>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>

                    <v-list dense>
                      <v-list-item @click="setToEdit(tag)">
                        <v-list-item-title>Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="updateTagField({
                          tag,
                          field: 'published',
                          value: !tag.published
                        })"
                      >
                        <v-list-item-title>{{ tag.published ? 'Unpublish' : 'Publish' }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="updateTagField({
                          tag,
                          field: 'range',
                          value: !tag.range
                        })"
                      >
                        <v-list-item-title >{{ tag.range ? 'Unset Range' : 'Set Range'}}</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title class="caption grey--text">User Roles</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-for="(role, i) in ['dietitian', 'personal_trainer', 'member', 'nutritionist', 'naturopath']"
                        :key="i"
                      >
                        <v-checkbox
                          @change="updateTagField({
                            tag,
                            silent: true,
                            field: 'roles',
                            value: tag.roles,
                          })"
                          v-model="tag.roles"
                          :label="role"
                          :value="role"
                          class="mt-0 pt-0"
                          hide-details
                          multiple
                          dense
                        >
                          <template #label>
                            <v-list-item-title>{{ role | startCase }}</v-list-item-title>
                          </template>
                        </v-checkbox>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item @click="setToDelete(tag)">
                        <v-list-item-title> <span class="red--text">Delete</span> </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      
      <div class="pa-7" v-if="!status.getting && !confTags.length">
        <v-alert type="info" border="left" class="mb-0">
          You don't have any tags yet.
        </v-alert>
      </div>
      <preloader v-if="status.getting"/>
    </v-card>

    <div class="d-flex align-center justify-space-between mt-5" style="gap:1rem">
      <v-card
        class="flex-grow-1"
        color="transparent"
        max-width="1000"
        flat
      >

        <v-alert v-if="status.error" border="left" type="error" text>
          {{ status.error }}
        </v-alert>

        <v-form v-if="!toEdit" ref="tagForm" @submit.prevent="validateForm()">
          <v-text-field
            v-model="data.name"
            placeholder="Meal tag name"
            :rules="[rules.required]"
            hide-details
            outlined
            dense
          >
            <v-btn
              :loading="status.adding"
              @click="validateForm()"
              class="mb-2 mr-n1"
              color="accent"
              slot="append"
              depressed
              small
              dark
            >Add</v-btn>
          </v-text-field>
        </v-form>

        <v-form v-else ref="updateForm" @submit.prevent="validateUpdateForm()">
          <v-text-field
            v-model="toEdit.name"
            placeholder="New meal tag name"
            :rules="[rules.required]"
            hide-details
            outlined
          >
            <template v-slot:append>
              <div class="d-flex align-center mt-n3 mr-n1">
                <v-btn
                  @click="validateUpdateForm()"
                  :loading="status.updating"
                  color="accent"
                  class="mr-2"
                  depressed
                  large
                  dark
                >Update</v-btn>

                <v-btn
                  :disabled="status.updating"
                  @click="cancelEdit()"
                  depressed
                  large
                >Cancel</v-btn>
              </div>
            </template>
          </v-text-field>
        </v-form>
      </v-card>

      <v-btn
        :loading="status.updatingAll"
        @click="updateAll()"
        color="primary" 
        depressed
      >Update All</v-btn>
    </div>

    <!-- DELETE -->
    <ConfirmDelete
      @confirmed="deleteConfirmed()"
      @cancel="showDelete = false"
      :deleting="status.deleting"
      :showDialog="showDelete"
      :message="deleteMessage"
    />
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import _ from 'lodash'
import rules from '@/rules'

export default {
  name: 'MealTags',

  metaInfo: {
    title: 'Nutrition Tags'
  },

  data() {
    return {
      rules,
      toEdit: null,
      search: null,
      toDelete: null,
      originalValue: {},
      showDelete: false,
      deleteMessage: null,
      minerals: [
        { name: 'Energy', unit: 'kJ' },
        { name: 'Protein', unit: 'g' },
        { name: 'Fat', unit: 'g' },
        { name: 'Carbohydrate', unit: 'g' },
        { name: 'Sugar', unit: 'g' },
        { name: 'Fibre', unit: 'g' },
        { name: 'Sodium', unit: 'mg' },
        { name: 'Weight', unit: 'g' },
      ],

    }
  },

  computed: {
    ...mapState({
      data: state => state.nutritiontags.data,
      tags: state => state.nutritiontags.tags,
      status: state => state.nutritiontags.status,
      newTags: state => state.excel.nutritionTags,
    }),

    confTags: function () {
      let tags = _.orderBy(this.tags, ['name'], ['asc'])

      if (this.search) {
        tags = tags.filter(tag => {
          return tag.name.toLowerCase().includes(this.search.toLowerCase())
        })
      }

      return tags
    }
  },

  methods: {
    ...mapActions('nutritiontags', [
      'updateTagField',
      'updateAll',
      'updateTag',
      'deleteTag',
      'getTags',
      'addTag',
    ]),

    validateForm() {
      if (this.$refs.tagForm.validate()) {
        Promise.all([this.addTag()])
        .then(() => this.$refs.tagForm.resetValidation())
      }
    },

    setToEdit(tag) {
      this.toEdit = Object.assign({}, tag)
    },

    cancelEdit() {
      this.toEdit = null
    },

    validateUpdateForm() {
      if (this.$refs.updateForm.validate()) {
        Promise.all([this.updateTag(this.toEdit)])
        .then(() => {
          this.toEdit = null
          this.$refs.updateForm.resetValidation()
        })
      }
    },

    setToDelete(tag) {
      this.toDelete = tag
      this.showDelete = true
      this.deleteMessage = `Are you sure want to delete nutrition tag "${this.toDelete.name}"?`
    },

    deleteConfirmed() {
      Promise.all([this.deleteTag(this.toDelete)])
      .then(() => {
        this.toDelete = null
        this.showDelete = false
      })
    },
  },

  mounted() {
    if (!this.status.firstLoad) this.getTags()

  }
}

</script>
