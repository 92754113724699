<template>
  <div>
    <v-card outlined>
      <v-card-title :class="$classes.cardTitle">
        <span class="font-weight-bold title mr-5 mb-3 d-flex align-center"
          ><v-icon class="mr-1">mdi-storefront</v-icon>Marketplace</span
        >

        <div
          class="d-flex flex-column align-end flex-md-row flex-lg-row align-md-center align-lg-center"
          style="width: 100%; gap: 0.5rem"
        >
          <v-text-field
            v-model="search"
            @keydown.enter="searchPlan(search)"
            @input="$store.commit('marketplace/setSearchTerm', search)"
            @click:clear="$store.dispatch('marketplace/clearSearch')"
            placeholder="Search Plan"
            style="max-width: 1000px; width: 100%"
            class="white"
            hide-details
            clearable
            rounded
            dense
            outlined
          >
            <template #append>
              <v-btn
                :loading="status.searching"
                class="mr-n2"
                icon
                small
                disabled
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </div>
        <v-container>
          <v-row dense>
            <v-col cols="5">
              <small>Sort by:</small>
              <v-row dense>
                <v-col cols="9">
                  <v-select
                    :items="sortItems"
                    label="Sort by"
                    solo
                    dense
                    depressed
                    v-model="orderByVal"
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-select
                    :items="orderItems"
                    label="Order"
                    solo
                    dense
                    depressed
                    v-model="orderSort"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="activeTag ? 6 : 3">
              <small>Tags:</small>

              <div class="d-flex" style="gap: 0.5rem">
                <v-select
                  :items="['Nutrition Tags', 'Meal Tags']"
                  label="Tag type"
                  solo
                  dense
                  depressed
                  v-model="activeTag"
                ></v-select>
                <v-select
                  v-if="activeTag === 'Meal Tags'"
                  :items="mealTagsName"
                  label="Meal Tags"
                  solo
                  dense
                  depressed
                  v-model="tagValue"
                ></v-select>
                <v-select
                  v-if="activeTag === 'Nutrition Tags'"
                  :items="nutritionTagsName"
                  label="Nutrition Tags"
                  solo
                  dense
                  depressed
                  v-model="tagValue"
                ></v-select>
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="d-flex" style="gap: 0.5rem">
              <v-btn
                small
                depressed
                class="info"
                :disabled="disabled"
                @click="startSort"
              >
                Sort
              </v-btn>
              <v-btn
                small
                depressed
                color="secondary"
                @click="clearOrderbyVal"
                :disabled="disabled"
              >
                Clear
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-text class="pa-5 d-flex flex-wrap gap-10">
        <template v-if="!status.getting && confPlans.length">
          <div
            class="d-flex justify-start flex-wrap gap-10"
            style="width: 100%"
          >
            <v-card
              max-width="240"
              width="100%"
              outlined
              hover
              v-for="plan in confPlans"
              :key="plan.id"
            >
              <v-card-text>
                <div class="float-right" v-if="user.id !== plan.user">
                  <v-menu bottom left offset-y transition="slide-x-transition">
                    <template v-slot:activator="{ on }">
                      <v-btn @click.prevent v-on="on" icon small>
                        <v-icon small>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list min-width="120">
                      <v-list-item
                        v-if="user.role !== 'admin'"
                        dense
                        @click="() => copySelectedPlan(plan)"
                      >
                        <v-list-item-icon class="mr-2">
                          <v-icon> mdi-plus-circle-outline </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title> Copy Mealplan </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="user.role === 'admin'"
                        dense
                        @click="() => setPlanToRemove(plan)"
                      >
                        <v-list-item-icon class="mr-2">
                          <v-icon> mdi-trash-can-outline </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title> Remove Mealplan </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <h2 class="primary--text">{{ plan.marketplaceName }}</h2>
                <div class="caption">
                  {{ getUser(plan.user) }}
                </div>
                <div class="caption">
                  {{ getUserRole(plan.user) }}
                </div>
                <marketplace-plan-rating
                  :totalRatings="plan.totalRatings"
                  :ratings="plan.ratings"
                />
              </v-card-text>
              <v-card-subtitle class="pb-0">
                <div v-if="user.energyUnit == 'cal'">
                  {{ plan.energy | kjToCal | amount | numberFormat }} Cal
                </div>
                <div v-else>{{ plan.energy | amount | numberFormat }} kJ</div>
              </v-card-subtitle>
              <v-card-subtitle class="cardDesc pt-0">
                {{ truncDesc(plan.marketplaceDesc) }}
              </v-card-subtitle>
              <v-card-actions class="px-3">
                <v-btn
                  depressed
                  small
                  block
                  class="primary"
                  @click="showDialog(plan)"
                  >More Info</v-btn
                >
              </v-card-actions>
            </v-card>
          </div>
        </template>
        <preloader v-if="status.getting" />
        <v-alert
          class="flex-grow-1"
          type="info"
          border="left"
          v-if="!status.getting && !confPlans.length"
          text
        >
          No results found
        </v-alert>
      </v-card-text>
      <v-card-actions class="blue-grey lighten-5">
        <div>
          <v-btn
            @click="getAllMarketplacePlans()"
            :loading="status.getting"
            v-if="confPlans.length >= 20"
            class="mr-2"
            color="secondary"
            depressed
            small
            >Load More</v-btn
          >

          <span class="caption"
            >showing {{ confPlans.length }}
            {{ pluralize('plan', confPlans.length) }}</span
          >
        </div>
      </v-card-actions>
    </v-card>
    <selected-plan
      v-if="this.selectedPlan"
      :show="show"
      @cancel="closeDialog"
      :plan="this.selectedPlan"
      @copyPlan="copySelectedPlan"
      @showRatingDialog="showRatingDialog"
    />

    <copy-plan-dialog />

    <remove-from-marketplace
      ref="removeMarketplace"
      :plan="currentPlanToRemove"
    />

    <rating-dialog
      ref="ratingDialog"
      :plan="this.selectedPlan"
      @refreshPlan="refreshPlan"
    />
    <v-dialog v-model="warningDialog" max-width="290" @>
      <v-card>
        <v-card-title class="headline"> Notice </v-card-title>

        <v-card-text>
          <p>
            You have reached the maximum limit of mealplans for your
            subscription.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="warningDialog = false">
            Got it
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import SelectedPlan from './components/SelectedPlan.vue';
import pluralize from 'pluralize';

import CopyPlanDialog from '@/components/CopyPlanDialog';
import RatingDialog from '@/components/RatingDialog';
import MarketplacePlanRating from '@/components/MarketplacePlanRating';
import RemoveFromMarketplace from '@/components/RemoveMarketplace';

import { startCase, truncate, orderBy } from 'lodash';

export default {
  name: 'Marketplace',
  data() {
    return {
      pluralize,
      show: false,
      selectedPlan: null,
      search: null,
      orderByVal: '',
      orderByValDefined: '',
      orderSort: 'desc',
      sortItems: [
        'Name',
        'Most Downloaded',
        'Highest Rated',
        'Latest',
        'Energy',
      ],
      orderItems: ['asc', 'desc'],
      showNutritionTag: false,
      showMealTag: false,
      activeTag: '',
      tagValue: '',
      currentPlanToRemove: {},
      warningDialog: false,
    };
  },
  components: {
    SelectedPlan,
    CopyPlanDialog,
    MarketplacePlanRating,
    RatingDialog,
    RemoveFromMarketplace,
  },
  watch: {
    marketplacePlans: function (val) {
      if (val.length) {
        this.getUsers();
      }
    },
    orderByVal: function (val) {
      this.orderByValDefined = this.defineSortValue(val);
    },
    tagValue: function (val) {
      this.$store.commit('marketplace/setTagValue', this.getTagId(val));
    },
  },
  computed: {
    ...mapState({
      status: (state) => state.marketplace.status,
      marketplacePlans: (state) => state.marketplace.marketplacePlans,
      noneExisting: (state) => state.members.noneExisting,
      user: (state) => state.user.user,
      hits: (state) => state.marketplace.hits,
      searchTerm: (state) => state.marketplace.searchTerm,
      mealTags: (state) => state.tags.tags,
      nutritionTags: (state) => state.nutritiontags.tags,
      plans: (state) => state.mealplans.plans,
    }),
    ...mapGetters('members', ['getUser', 'getRole']),

    confPlans: function () {
      const plans =
        this.hits.length && this.searchTerm ? this.hits : this.marketplacePlans;

      const orderVal = this.orderByVal ? this.orderByValDefined : 'createdAt';

      return orderBy(plans, orderVal, this.orderSort);
    },

    disabled: function () {
      return this.tagValue ? false : true;
    },

    publishedMealTags: function () {
      return this.mealTags.filter((tag) => tag.published === true);
    },
    publishedNutritionTags: function () {
      return this.nutritionTags.filter((tag) => tag.published === true);
    },

    mealTagsName() {
      const names = orderBy(this.publishedMealTags, 'name', 'asc').map(
        (tag) => tag.name
      );

      return names;
    },

    nutritionTagsName() {
      const names = orderBy(this.publishedNutritionTags, 'name', 'asc').map(
        (tag) => tag.name
      );

      return names;
    },
  },
  methods: {
    ...mapActions('marketplace', [
      'getAllMarketplacePlans',
      'copyPlan',
      'startSort',
      'searchPlan',
    ]),

    getUserRole(id) {
      const role = this.getRole(id);

      return role === 'personal_trainer'
        ? 'Health & Fitness Professionals'
        : startCase(role);
    },

    getUsers() {
      let ids = [];

      this.marketplacePlans.forEach((plan) => {
        ids.push(plan.user);
      });

      if (ids.length) {
        ids.forEach((id) => {
          this.$store.dispatch('members/getMember', id);
        });
      }
    },

    copySelectedPlan(plan) {
      if (
        this.plans.length >= this.$store.getters['user/mealPlans'] &&
        !this.$store.getters['user/mealPlans'] == -1
      )
        return (this.warningDialog = true);

      this.copyPlan(plan);
    },

    showDialog(plan) {
      this.selectedPlan = {};
      Object.assign(this.selectedPlan, plan);
      this.show = true;
    },
    closeDialog() {
      this.show = false;
      this.selectedPlan = null;
    },
    truncDesc(val) {
      return truncate(val, {
        length: 65,
        separator: ' ',
      });
    },
    defineSortValue(val) {
      const sortValues = {
        ['Name']: 'name',
        ['Latest']: 'createdAt',
        ['Highest Rated']: 'totalRatings',
        ['Most Downloaded']: 'downloads',
        ['Meal Tags']: 'mealTags',
        ['Nutrition Tags']: 'nutritionTags',
        ['Energy']: 'energy',
      };

      return sortValues[val];
    },
    clearOrderbyVal() {
      this.orderByVal = '';
      this.activeTag = '';
      this.tagValue = '';
      this.orderSort = 'asc';

      this.$store.dispatch('marketplace/clearSortMealPlan');
    },
    getTagId(val) {
      if (!val) return;

      const tagType = this.defineSortValue(this.activeTag);

      this.$store.commit('marketplace/setTagType', tagType);
      const id = this[tagType].find((tag) => tag.name === val).id;

      return id;
    },

    showRatingDialog() {
      this.$refs.ratingDialog.showDialog();
    },

    refreshPlan(id) {
      this.selectedPlan = {};
      this.selectedPlan = this.confPlans.find((plan) => plan.id === id);
    },
    setPlanToRemove(plan) {
      this.$refs.removeMarketplace.showDialog();
      this.currentPlanToRemove = plan;
    },
  },

  created() {
    this.$store.commit('marketplace/resetState');
    this.$store.commit('marketplace/updateStatus', { limit: 20 });
    if (!this.status.firstLoad) this.getAllMarketplacePlans();
  },

  mounted() {
    if (!this.$store.state.tags.status.firstLoad)
      this.$store.dispatch('tags/getTags');
    if (!this.$store.state.nutritiontags.status.firstLoad)
      this.$store.dispatch('nutritiontags/getTags');
  },
};
</script>

<style></style>
