<template>
  <v-dialog
    :value="status.dialog"
    class="overflow-visible"
    no-click-animation
    max-width="650"
    persistent
    scrollable
  >
    <close-button @click="closeDialog()" overflow />

    <v-card>
      <v-card-title class="font-weight-bold">
        {{ data.id ? `Edit Plan ${data.title}` : 'Add New Plan' }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-alert type="error" border="left" class="mb-7" v-if="status.error">{{ status.error }}</v-alert>

        <v-select
          v-model="data.subscription"
          @change="selected($event)"
          :rules="[rules.required]"
          item-text="description"
          label="Select product"
          :items="subscriptions"
          item-value="id"
          outlined
          dense
        ></v-select>

        <v-card v-if="price" class="mb-5" outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Amount</th>
                  <th>Type</th>
                  <th>Interval</th>
                  <th class="text-center">Interval Count</th>
                  <th>User Role</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="20%">$ {{ price.unit_amount / 100 | numberFormat }}</td>
                  <td width="20%">{{ startCase(price.type) }}</td>
                  <td width="20%">{{ price.interval || 'NA' }}</td>
                  <td class="text-center" width="20%">{{ price.interval_count || 'NA' }}</td>
                  <td width="20%">
                    <v-select
                      v-model="data.role"
                      :items="roles"
                      :item-text="(item) => startCase(item.name)"
                      item-value="name"
                      hide-details
                      small-chips
                      outlined
                      multiple
                      chips
                      dense
                    ></v-select>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <v-form ref="addPlanForm" @submit.prevent="validateForm()">
          <v-text-field
            label="Plan/Pricing Title"
            :rules="[rules.required]"
            v-model="data.title"
            outlined
            dense
          ></v-text-field>

          <!-- <v-textarea
            v-model="data.description"
            label="Description"
            outlined
          ></v-textarea> -->
          <vue-editor v-model="data.description" placeholder="Description" class="mb-3" />

          <v-row dense>
            <v-col>
              <v-text-field
                :suffix="price && price.type == 'recurring' ? 'per billing period' : ''"
                :rules="[rules.required]"
                label="Meal Plan Storage"
                v-model.number="data.meals"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model.number="data.downloads"
                :rules="[rules.required]"
                label="Download credits"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-switch v-model="data.active" class="mt-0" label="Active" dense></v-switch>
        </v-form>

        <div class="mt-5 d-flex gap-7">
          <v-btn
            @click="validateForm()"
            :loading="status.creating || status.updating"
            color="primary"
          >Submit</v-btn>

          <v-btn
            @click="closeDialog()"
            :disabled="status.creating || status.updating"
            depressed
          >Cancel</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/rules'
import { startCase } from 'lodash'
import { mapState, mapActions } from 'vuex'
import { VueEditor } from "vue2-editor";

export default {
  data() {
    return {
      rules,
      startCase,
      originalValue: {},
      subscription: null,
      roles: [{name: 'member'}, {name: 'dietitian'}, {name: 'personal_trainer'}, { name: 'nutritionist'}, {name: 'naturopath'}]
    }
  },

  components: {
    VueEditor
  },

  computed: {
    ...mapState({
      data: state => state.pricing.data,
      status: state => state.pricing.status,
      plans: state => state.pricing.plans,
      subscriptions: state => state.pricing.subscriptions,
    }),

    price: function () {
      return this.data.subscription ? this.subscriptions.find(s => s.id == this.data.subscription) : null
    }
  },

  watch: {
    'data': function (value) {
      let plan = this.plans.find(plan => plan == value)

      if (plan) {
        Object.assign(this.originalValue, plan)
      }

      if (value) {
        this.selected(value.subscription)
      }
    }
  },

  methods: {
    ...mapActions('pricing', [
      'createPricing',
      'update'
    ]),

    closeDialog() {
      Object.assign(this.data, this.originalValue)
      this.$store.commit('pricing/updateStatus', { dialog: false })
      this.$store.commit('pricing/resetData')
      this.subscription = null
      this.$refs.addPlanForm.resetValidation()
    },

    selected(e) {
      this.subscription = this.subscriptions.find(subscription => subscription.id == e)
    },

    getAmount(amount) {
      return amount/100
    },

    validateForm() {
      if (this.$refs.addPlanForm.validate()) {
        if (!this.data.id) {
          this.createPricing()
        }
        else {
          this.update()
        }
      }
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (this.data) {
      this.selected(this.data.subscription)
    }
  }
}

</script>
